import iGameMediaLogo from "../../../../img/igm_logo.svg";
import modusLogo from "../../../../img/MSSLogoMain.png";
import React from "react";
import {animated, useSpring} from "react-spring";
import './style.scss';
import './style-media.scss';
import redDragonLogo from '../../../../img/red-dragon-logo.svg'

interface IBroadcastersFooterProps {
    counter: number
}

const BroadcastersFooter: React.FC<IBroadcastersFooterProps> = ({counter}) => {

    const moveIGLogoEnd = useSpring({
        to: async (next) => {
            await next({
                y: counter > 3800 && counter <= 38000 ? '50%' : "-50%",
                x: counter > 3800 && counter <= 38000 ? '-50%' : "-50%",
                width: counter > 3800 && counter <= 38000 ? "280px" : "800px",
                height: counter > 3800 && counter <= 38000 ? "120px" : "441px"
            })
        },
        config:{duration:700}

    })

    return (
        <div className="broadcaster-logos">
            <div className="broadcaster-logo-inner__left-side ">
                <img className="broadcaster-logo" src={iGameMediaLogo} alt="logo"/>
            </div>
            <animated.div className="broadcaster-logo-inner" style={moveIGLogoEnd}>
                <img className="broadcaster-logo-igm" src={modusLogo} alt="logo"/>
            </animated.div>
            <div className="red-dragon-logo-inner">
                <img className="red-dragon-logo" src={redDragonLogo} alt=" "/>
            </div>
        </div>
    )
}

export default BroadcastersFooter
