import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

interface IWarningModalProps {
  isOpen: boolean;
  message: string;
  onClick: () => void;
}

export const WarningModal: React.FC<IWarningModalProps> = ({ isOpen, message, onClick }) => {
  return (
    <Modal open={isOpen} size='tiny' autoFocus>
      <Modal.Header>Additional action required</Modal.Header>
      <Modal.Content>
        {message}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={onClick}>Ok</Button>
      </Modal.Actions>
    </Modal>
  )
}