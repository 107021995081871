import * as yup from "yup";

export const filterValidationSchema = yup.object().shape({

    competition: yup.string().required(),
    playerHomeName: yup.string().required(),
    playerAwayName: yup.string().required(),
    location: yup.string().required(),
    legsToWin: yup.number().required(),
    isValid: yup
        .string()
        .test("match", "You cannot select the same competition", function () {
            return this.parent.playerHomeName !== this.parent.playerAwayName;
        }),
});
