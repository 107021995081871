import React, {FC} from 'react';
import {useAppSelector} from "../../../../hooks/hooks";

const TurnsScoreTable: FC = () => {
    const {scoreBoardAndStatsInfo:{rounds}} = useAppSelector(
        (state) => state.scoreboardAndStats
    );

    return (
        <div className="score-table score-table-100-140-180">
            <div className="scores-header">
                {rounds.titles.map((title,index)=> {
                    return (
                        <div key={index} className="scores-header-item">
                            {title}
                        </div>
                    )
                })}

            </div>
            <div className="table-body">
                <div className="table-body-inner">
                    {rounds.data[0].map((score,index)=> {
                        return (
                            <div
                                key={index}
                                className="table-body-inner-item table-body-inner-item-line">
                                {score}
                            </div>
                        )
                    })}
                </div>
                <div className="table-body-inner">
                    {rounds.data[1].map((score,index)=> {
                        return (
                            <div
                                key={index}
                                className="table-body-inner-item table-body-inner-item-line">
                                {score}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default TurnsScoreTable;
