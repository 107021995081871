export enum PagePermissionsDictionary {
    StatisticDownload = "pSD",
    LeagueTable = "pLT",
    PreMatchAnimation = "pMA",
    PreMatchImage = "pMI",
    PostMatch = "pM",
    LiveScore = "pLS",
    ScoreboardAndStats = "pSS",
    ActiveGameScore = "pAG"
}

export const checkUserAccessByPermission = (userPermissions: string[], checkingPermission: string) => {
    return !!userPermissions.find(permission => permission === checkingPermission)
}
