import React from 'react';
import ActiveGameScore from './Scoreboard';
import './styles.scss';

interface IScoreboardPageProps {
}

export const ActiveGameScorePage: React.FC<IScoreboardPageProps> = () => {

  return (
    <div className="scoreboard-page">
      <ActiveGameScore />
    </div>
  );
}
ActiveGameScorePage.displayName = "ActiveGameScorePage";

