import React, {useEffect, useState} from 'react';
import {Switch, useLocation, useRouteMatch} from 'react-router-dom';
import {PrivateRoute} from '../../../components/PrivateRoute';
import DartsGamesTable from '../Darts/DartsGames/DartsGamesTable';
import DartsGroups from '../Darts/DartsGroups/DartsGroups';
import DartsPhase from '../Darts/DartsPhase/DartsPhase';
import DartsPlayersTableDashBoard from '../Darts/DartsPlayersTable/DartsPlayersTableDashBoard';
import DartsTournaments from '../Darts/DartsTournaments/DartsTournaments';
import DartsWeeks from '../Darts/DartsWeeks/DartsTournamentWeeks';
import Sidebar from './Sidebar/Sidebar';
import DartsShootOutGames from "../Darts/DartsShootOut/DartsShootOutGames";
import DartsCompetitions from "../Darts/DartsCompetitions/DartsCompetitions";
import MssNewsTable from "../News/MssNewsTable";
import Users from "../UsersRoles/Users/Users";
import Roles from "../UsersRoles/Roles/Roles";
import DartsAdcGamesTable from "../Darts/DartsAdcGames/DartsAdcGamesTable";
import DartsService from "../Darts/DartsService/DartsService";
import ClientCards from "../ClientCards/ClientCards";
import Listeners from "../UsersRoles/Listeners/Listeners";
import {
    getPermissionsDictionary,
    getTourTypes,
} from "../../../store/cms/permissions/permissionsSlice";
import {useAppDispatch} from "../../../hooks/hooks";
import './styles.scss'

const CmsPage = () => {
    const {path} = useRouteMatch();
    const dispatch = useAppDispatch()
    const [pageTitle, setPageTitle] = useState('');
    const location = useLocation();

    useEffect(() => {
        dispatch(getPermissionsDictionary("page"))
        dispatch(getTourTypes())
    }, []);

    useEffect(() => {
        const pathTitleMap = {
            '/cms/darts/service': 'Darts Service',
            '/cms/darts/games': 'Standard Games',
            '/cms/darts/adc-games': 'ADC Games',
            '/cms/darts/players': 'Players',
            '/cms/clients': 'Client Cards',
            '/cms/client-info': 'Client Information',
            '/cms/darts/groups': 'Groups',
            '/cms/darts/competitions': 'Competitions',
            '/cms/darts/weeks': 'Weeks',
            '/cms/darts/tournaments': 'Tournaments',
            '/cms/darts/phase': 'Phases',
            '/cms/darts/shootout': 'Shootout Games',
            '/cms/mss/news': 'MSS News',
            '/cms/users': 'Users',
            '/cms/listeners': 'Listeners',
            '/cms/roles': 'Roles',
            '/cms/user-groups': 'User Groups'
        };

        const baseRoute = Object.keys(pathTitleMap).find((key) =>
            location.pathname.startsWith(key)
        ) as keyof typeof pathTitleMap | undefined;
        if (baseRoute) {
            setPageTitle(pathTitleMap[baseRoute] || 'CMS Dashboard');
        }
    }, [location]);

    const getClientName = () => {
        const clientInfo = localStorage.getItem("currentUser")
        if (clientInfo) {
            const parsedInfo = JSON.parse(clientInfo)
            if (parsedInfo.client) {
                return parsedInfo.client.name
            } else {
                return ""
            }
        }
    }

    return (
        <div className='cms__container'>
            <Sidebar name='CMS Pages'/>
            <div className='cms-content'>
                <div className='cms-dashboard__header'>
                    <h6>{pageTitle}</h6>
                    <h6>{getClientName()}</h6>
                </div>
                <Switch>
                    <PrivateRoute path={`${path}/darts/service`} component={DartsService}/>
                    <PrivateRoute path={`${path}/darts/games`} component={DartsGamesTable}/>
                    <PrivateRoute path={`${path}/darts/adc-games`} component={DartsAdcGamesTable}/>
                    <PrivateRoute path={`${path}/darts/players`} component={DartsPlayersTableDashBoard}/>
                    <PrivateRoute path={`${path}/clients`} component={ClientCards}/>
                    <PrivateRoute path={`${path}/darts/groups`} component={DartsGroups}/>
                    <PrivateRoute path={`${path}/darts/competitions`} component={DartsCompetitions}/>
                    <PrivateRoute path={`${path}/darts/weeks`} component={DartsWeeks}/>
                    <PrivateRoute path={`${path}/darts/tournaments`} component={DartsTournaments}/>
                    <PrivateRoute path={`${path}/darts/phase`} component={DartsPhase}/>
                    <PrivateRoute path={`${path}/darts/shootout`} component={DartsShootOutGames}/>
                    <PrivateRoute path={`${path}/mss/news`} component={MssNewsTable}/>
                    <PrivateRoute path={`${path}/users/`} component={Users}/>
                    <PrivateRoute path={`${path}/listeners`} component={Listeners}/>
                    <PrivateRoute path={`${path}/roles/`} component={Roles}/>
                </Switch>
            </div>
        </div>
    )
}


export default CmsPage;
