import React, {FC} from 'react';
import styles from "./index.module.scss"
import {IDailyFixture} from "../../../../store/dailyResults/dailyResultSlice";

interface IFixtureProps {
    fixture: IDailyFixture
}

enum Player {
    HomePlayer = "HomePLayer",
    AwayPlayer = "AwayPLayer"
}

const Fixture: FC<IFixtureProps> = ({fixture}) => {
    const setPlayerColor = (player: Player) => {
        const {scorePlayerHome, scorePlayerAway} = fixture
        if (!scorePlayerHome && !scorePlayerAway) {
            return `${styles.greyColor}`
        }

        if (player === Player.HomePlayer && scorePlayerHome > scorePlayerAway) {
            return `${styles.goldColor}`
        }
        if (player === Player.AwayPlayer && scorePlayerAway > scorePlayerHome) {
            return `${styles.goldColor}`
        }

    }
    const formatPlayerName = (name:string) => {
        return name.replace(/_/g, ' ')
    }

    return (
        <div className={styles.fixture}>
            <div className={`${styles.playerHome} ${setPlayerColor(Player.HomePlayer)}`}>{formatPlayerName(fixture.playerHome)}</div>
            <div className={styles.matchResult}>
                <div
                    className={`${styles.playerResult} ${setPlayerColor(Player.HomePlayer)}`}>
                    {fixture.scorePlayerHome !== null ? fixture.scorePlayerHome : ""}
                </div>
                <div className={styles.decor}></div>
                <div
                    className={`${styles.playerResult} ${setPlayerColor(Player.AwayPlayer)}`}>
                    {fixture.scorePlayerAway !== null ? fixture.scorePlayerAway : ""}
                </div>
            </div>
            <div className={`${styles.playerAway} ${setPlayerColor(Player.AwayPlayer)}`}>{formatPlayerName(fixture.playerAway)}</div>
        </div>
    );
};

export default Fixture;
