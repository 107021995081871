export enum GameEvent {
    TurnFinished = 3,
    LegWin = 12,
    GameStatus = 22,
    WinSet = 24,
    PlayerChanged = 32,
}

export enum SportType {
    Darts,
    TableTennis,
    Unknown,
}


export enum GameTypes  {
    SHOOTOUT = "SHOOTOUT",
    CLASSIC = "CLASSIC",
    ADC = "ADC"
}
export enum DartsGameTypes  {
    SHOOTOUT = 1,
    CLASSIC = 0,
    ADC = 2
}

export interface FinishedRound {
    leg: number;
    roundNumber: number;
    currentScore: number;
    playerId: number;
    scores: Score[];
    timestamp: Date;
    gameId: number;
}

export interface HomeAwayScore {
    home: number;
    away: number;
}

export interface Score {
    score: number;
    bed: string;
    ring: string;
    date: Date;
    isDoubleAttempt: boolean;
    scoreBeforeShot: number;
}
export type OnShotForLiveScore = {
    bed: number;
    ring: string;
    message: string;
}

export type PlayerScoreData = {
    turn: null | number;
    score: number;
}
