import React from "react";
import {Table} from "semantic-ui-react";

const DartsGroups = () => {
    const groups = [
        {name: 'GROUP A', id: 1},
        {name: 'GROUP B', id: 2},
        {name: 'GROUP C', id: 3},
        {name: 'GROUP 1', id: 4},
        {name: 'GROUP 2', id: 5},
        {name: 'SEMI FINAL', id: 6},
        {name: 'FINAL', id: 7},
    ];

    return (
        <div>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
            </div>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center">Groups</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {groups.map((group) => {
                            return (
                                <Table.Row key={group.id}>
                                    <Table.Cell textAlign="center">{group.name}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
        </div>
    );
};

export default DartsGroups;
