import React from 'react';
import styles from "./index.module.scss"
import {IDoubleMissedInfo} from "../../../../store/pages/scoreboardAndStats/types";
interface IDoublesMissedLegTableProps {
    doubleMissedInfo:IDoubleMissedInfo
}
const DoublesMissedLegTable:React.FC<IDoublesMissedLegTableProps> = ({doubleMissedInfo:{currentLeg,scores}}) => {
    return (
        <div className={styles.table}>
            <div className={styles.header}>DOUBLES MISSED LEG {currentLeg}</div>
            <div className={styles.valueCell}>{scores[0]}</div>
            <div className={styles.valueCell}>{scores[1]}</div>
        </div>
    );
};

export default DoublesMissedLegTable;
