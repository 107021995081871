import {DartsADCGame, DartsStatuses} from "../../../store/pages/schedule/types";
import {DartsGameItem} from "../../../store/dartsGame/handler/types";
import {IDartsGameStatus} from "../../../store/cms/darts/games/types";

export const getStatusForGame = (game: DartsGameItem | DartsADCGame, statuses: DartsStatuses[]) => {
    const gameStatus = statuses.find((status: IDartsGameStatus) => {
        return status.key === game.status
    })
    if (gameStatus?.value !== undefined && gameStatus?.value !== "NotActive") {
        return ` - ${gameStatus!.value}`
    } else return ""
}
