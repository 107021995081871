import {removeNameSeparator} from "../../../helpers/playersNames.helper";
interface IGetTournamentName {
    shootOutGameName: string;
    isShootOutGame: string;
    gameName: string;
}
export const formatTournamentName = ({gameName, shootOutGameName, isShootOutGame}: IGetTournamentName): string => {
    if (isShootOutGame) {
        return shootOutGameName?.split(' vs ').map((game) => {
            return removeNameSeparator(game);
        }).join(' vs ');
    }
    return gameName?.split(' vs ').map((game) => {
        return removeNameSeparator(game);
    }).join(' vs ');
}
