import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import './styles.scss';
import {PlayerScoreData} from "../../../enums/gameEvents";

interface IBodyProps {
    reverse?: boolean;
    turnData: PlayerScoreData[];
}

const ShootOutPlayerScoreboardBody: React.FC<IBodyProps> = ({ reverse, turnData }) => {
    const scrollToBottomRef: any = useRef();

    const scrollToBottom = () => {
        if (scrollToBottomRef.current) {
            scrollToBottomRef.current.scrollIntoView({ behavior: "smooth" })
        }
    }
    useEffect(scrollToBottom, [turnData]);

    return (
        <Grid columns={16} className="player-scoreboard-body">
            {turnData?.map((score, i) => (
                <Grid.Row key={i} className="row" >
                    {reverse && <Grid.Column width={4}/>}
                    <Grid.Column width={8} className="col scored-col">{score.turn}</Grid.Column>
                    <Grid.Column width={4} className="col remaining-score-col">{score.score}</Grid.Column>
                </Grid.Row>
            ))}
            <div ref={scrollToBottomRef} ></div>
        </Grid>
    );
}

export default connect()(ShootOutPlayerScoreboardBody);
