import React from "react";

interface IGetSelectedColumnStyle {
    id: number | null;
    basicStyles: React.CSSProperties;
    currentPlayerId?: number;
}
export const setSelectedColumnStyle = ({id,  currentPlayerId, basicStyles}: IGetSelectedColumnStyle) => {
    if (!currentPlayerId) {
        return {...basicStyles, display: "none"};
    } else if (id === currentPlayerId) {
        return basicStyles;
    } else {
        return {...basicStyles, right: 0};
    }
};
