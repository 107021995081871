import {Button, Checkbox, Form, Icon, Label, Select} from "semantic-ui-react";
import React, {useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    clearSingleUserState,
    createModeHandler,
    editModeHandler, getRoles,
} from "../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {
    IUserPayload,
    IUserFormData,
    IRole
} from "../../../../store/cms/usersNRoles/types";
import {createUserPayload} from "./helpers/createPayload";
import {setUserHandler} from "./helpers/hasExistingUser";
import {toastr} from "react-redux-toastr";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import styles from "./index.module.scss"
import Permissions from "../../../../components/Permissions/Permissions";
import {readUserPermissions,} from "../../../../store/cms/permissions/permissionsSlice";

const UsersForm = () => {
    const dispatch = useAppDispatch();
    const {roles, allUsers, user, editMode, allClients, clientId} = useAppSelector((state) => state.cmsUsersNRoles);
    const {permissions} = useAppSelector(state => state.permissions)
    const {register, control, handleSubmit, errors, setValue} = useForm();
    const [newClient, setNewClient] = useState<any>(undefined);
    const [is2FA, setIs2FA] = useState(false);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

    useEffect(() => {
        if (editMode && user.roles) {
            setUpdatingUserInfo(user.id)
            setValue('userRole', user.roles)
            setIs2FA(user.is2FA)
            readExistingPermission(user.id)
        }
    }, [user])

    useEffect(() => {
        if (clientId) {
            setNewClient(clientId)
        }
        dispatch(getRoles())
    }, []);

    const readExistingPermission = (id: number) => {
        dispatch(readUserPermissions(id)).then((data:
        {
            payload: {
                codes: React.SetStateAction<string[]>;
            }
        }) => {
            setSelectedPermissions(data.payload.codes)
        })
    }

    const setUpdatingUserInfo = async (userId: number) => {
        try {
            const {data} = await client.get(`/api/users/${userId}`)
            if (data.clientId) {
                setNewClient(data.clientId)
            }
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch a user info.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get a single user: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return
        }

    }

    const roleNames = useMemo(() => {
        return roles.map((role: IRole) => ({
            key: role.id,
            value: role.name,
            text: role.name,
        }))
    }, [roles])

    const onSubmit = (inputData: IUserFormData) => {
        if (editMode) {
            const payload: IUserPayload = createUserPayload({
                inputData,
                roles,
                userId: user.id,
                clientId: newClient,
                is2FA,
                permissions: selectedPermissions
            })

            setUserHandler({user: payload, allUsers, dispatch, editMode, editedUser: user})
        }
        if (!editMode) {
            if (inputData.userEmail !== inputData.confirmEmail) {
                return toastr.error("Create User", "Emails mismatch")
            }
            const payload: IUserPayload = createUserPayload({
                inputData,
                roles,
                clientId:
                newClient,
                is2FA,
                permissions: selectedPermissions
            });
            setUserHandler({user: payload, allUsers, dispatch, editMode, editedUser: user});
        }
    }

    const closeForm = () => {
        dispatch(createModeHandler(false));
        dispatch(editModeHandler(false));
        dispatch(clearSingleUserState());
    }

    const clientOptions = useMemo(() => {
        const clients = allClients.map(client => {
            return {key: client.id, value: client.id, text: client.name}
        })
        clients.unshift({key: 0, value: 0, text: "Unselected"})
        return clients
    }, [allClients]);

    const selectPermission = (permission: string) => {
        const isExistPermission = selectedPermissions.find(item => item === permission)
        if (isExistPermission) {
            return setSelectedPermissions(selectedPermissions.filter(item => item !== permission))
        } else {
            setSelectedPermissions([...selectedPermissions, permission])
        }
    }

    return (
        <div className="form-overlay">
            <Form
                autoComplete={"off"}
                onSubmit={handleSubmit(onSubmit)}
                className={"darts-users-form"}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={closeForm}
                    style={{float: "right", cursor: "pointer"}}
                />
                <div className={styles.formRow}>
                    <Form.Field className={styles.formField}>
                        <Label className="darts-users-form-label">Client</Label>
                        <Select
                            name={"clientId"}
                            options={clientOptions}
                            value={newClient}
                            onChange={(e, {value}) => {
                                setNewClient(value)
                            }}
                            placeholder='Select client'
                        />
                    </Form.Field>
                    <Form.Field className={styles.formField}>
                        <Label className="darts-users-form-label">Name</Label>
                        <input
                            autoComplete="new-user"
                            defaultValue={user.name}
                            name="userName"
                            ref={register({required: true, minLength: 3})}
                        />

                        {errors.userName && errors.userName.type === 'required' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>The user name field is required</p>
                            </div>}
                        {errors.userName && errors.userName.type === 'minLength' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>This field required min 3 symbols</p>
                            </div>}
                    </Form.Field>
                </div>
                <div className={styles.formRow}>
                    <Form.Field className={styles.formField}>
                        <Label className="darts-users-form-label">E-mail</Label>
                        <input
                            onCopy={(e) => {
                                e.preventDefault()
                                return toastr.warning("Create User", "Copying is prohibited")
                            }}
                            type={'email'}
                            defaultValue={user.email}
                            name="userEmail"
                            ref={register(
                                {
                                    required: true,
                                    minLength: 5,
                                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i
                                })
                            }
                        />
                        {errors.userEmail && errors.userEmail.type === 'required' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>The user e-mail field is required</p>
                            </div>}
                        {errors.userEmail && errors.userEmail.type === 'minLength' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>This field required min 5 symbols</p>
                            </div>
                        }
                        {errors.userEmail && errors.userEmail.type === 'pattern' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>The e-mail should be like example@domain.com</p>
                            </div>
                        }
                    </Form.Field>
                    <Form.Field className={styles.formField}>
                        <Label className="darts-users-form-label">Confirm E-mail</Label>
                        <input
                            onCopy={(e) => {
                                e.preventDefault()
                                return toastr.warning("Create User", "Copying is prohibited")
                            }}
                            type={'email'}
                            defaultValue={user.email}
                            name="confirmEmail"
                            ref={register({
                                required: true,
                                minLength: 5,
                                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i
                            })}
                        />
                        {errors.confirmEmail && errors.confirmEmail.type === 'required' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>The confirm e-mail field is required</p>
                            </div>}
                        {errors.confirmEmail && errors.confirmEmail.type === 'minLength' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>This field required min 5 symbols</p>
                            </div>
                        }
                        {errors.confirmEmail && errors.confirmEmail.type === 'pattern' &&
                            <div className={'darts-users-form-error-wrap'}>
                                <div><i className="warning sign red icon"></i></div>
                                <p className='darts-users-form-error'>The confirm e-mail should be like
                                    example@domain.com</p>
                            </div>
                        }
                    </Form.Field>
                </div>
                <Form.Field>
                    <Label className="darts-users-form-label">Role</Label>

                    {
                        editMode ?
                            <Controller
                                name="userRole"
                                control={control}
                                rules={{
                                    validate: (value) => {
                                        return !!(value && value.length > 0)
                                    }
                                }}
                                render={(props) => (
                                    <Select
                                        multiple
                                        value={props.value}
                                        id="userRole"
                                        options={roleNames}
                                        placeholder="Select user role"
                                        onChange={(e, {value}) => props.onChange(value)}
                                    />

                                )}
                            />
                            :
                            <Controller
                                name="userRole"
                                rules={{
                                    validate: (value) => {
                                        return !!(value && value.length > 0)
                                    }
                                }}
                                control={control}
                                render={(props) => (
                                    <Select
                                        multiple
                                        value={props.value}
                                        id="userRole"
                                        options={roleNames}
                                        placeholder="Select user role"
                                        onChange={(e, {value}) => props.onChange(value)}
                                    />
                                )}
                            />
                    }
                    {errors.userRole && errors.userRole.type === 'validate' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The user's role field is required</p>
                        </div>}
                </Form.Field>
                <div className={"two-factor-checkbox"}>
                    <Checkbox
                        checked={is2FA}
                        onClick={() => setIs2FA(!is2FA)}
                        label={"Two factor authorization."}
                    />
                </div>
                <Permissions
                    title={"Permissions"}
                    permissions={permissions}
                    selectedPermissions={selectedPermissions}
                    selectPermission={selectPermission}
                />
                <Button id="save_game" color="green" type="submit">
                    {editMode ? 'Update' : 'Create'}
                </Button>
            </Form>
        </div>
    );
}

export default UsersForm;
