/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import './index.scss';

interface IConfirmationModalProps {
  open: boolean,
  content: string,
  onConfirm: () => void,
  onDecline: () => void
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({ open, content, onConfirm, onDecline }) => {
  useEffect(() => {
    document.addEventListener('keyup', handleDKeyPressed, false);

    return () => {
      document.removeEventListener('keyup', handleDKeyPressed, false);
    }
  }, []);

  const handleDKeyPressed = (event: KeyboardEvent) => {
    if (event.code === 'KeyD') {
      onDecline();
    }
  }
  

  return (
    <Modal open={open} size='tiny' autoFocus left='20px'>
      <Modal.Header>Do you confirm action?</Modal.Header>
      <Modal.Content>
        {<div style={{fontSize:16}}>{content}</div>}
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onDecline}>Disagree</Button>
        <Button type='submit' positive onClick={onConfirm} autoFocus>Agree</Button>
      </Modal.Actions>
    </Modal>
  )
}

export { ConfirmationModal };