import { client, handleError } from './api.service';
import axios, {AxiosError} from "axios";

export const auth = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity'
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT
})
export const loginRequest = async (email: string, password: string) => {
  try {
      let response = await client.post('/api/auth/login', { email, password });
      return response.data;
  }
  catch (err) { return handleError(err as AxiosError) }
}

