import React, { useState } from "react"
import { Transition } from "react-transition-group"
import { Icon } from 'semantic-ui-react'
import './styles.scss';

const duration = 500

const sidebarStyle = {
  transition: `width ${duration}ms`
}
const sidebarTransitionStyles = {
  entering: { width: 0 },
  entered: { width: '250px' },
  exiting: { width: '250px' },
  exited: { width: 0 }
}

const linkStyle = {
  transition: `opacity ${duration}ms`
}

const linkTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 }
}

const selectedStyles = {
  borderRight: '5px solid dimgray',
  backgroundColor: 'gainsboro'
}

const SidebarContent: React.FC<any> = ({ isOpen, initialItems }) => {
  const [selectedItem, setSelectedItem] = useState('Users');
  const [items, setItems] = useState(initialItems);

  const openItem = (index: number) => {
    const newItems = [...items];
    const item = { ...newItems[index] };
    item.isOpened = !item.isOpened;
    newItems[index] = item;
    setItems(newItems);
  }

  const renderLinks = () => {
    return (
      <Transition in={isOpen} timeout={duration}>
        {(state) => (
          <div style={{
            ...linkStyle,
            ...linkTransitionStyles[state as 'exited' | 'entering' | 'exiting' | 'entered']
          }}>
            {
              isOpen && items.map((x: any, index: number) =>
                !x.children ? (
                  <div
                    key={x.id}
                    className='sidebar-link'
                    onClick={() => {
                      setSelectedItem(x.id);
                      x.onClick();
                    }}
                    style={selectedItem === x.id ? selectedStyles : undefined}>
                    <Icon name={x.iconName as any} size='large' />
                    {x.name}
                  </div>
                ) : (
                  <div key={x.id}>
                    <div
                      className='sidebar-link'
                      onClick={() => {
                        setSelectedItem(x.id);
                        openItem(index);
                      }}
                      style={selectedItem === x.id ? selectedStyles : undefined}>
                      <Icon name={x.iconName as any} size='large' />
                      {x.name}
                    </div>
                    {x.isOpened && x.children.map((x: any) => (
                        <div
                          key={x.id}
                          className='sidebar-link'
                          onClick={() => {
                            setSelectedItem(x.id);
                            x.onClick();
                          }}
                          style={selectedItem === x.id ? { ...selectedStyles, marginLeft: 5 } : { marginLeft: 5 }}>
                          <Icon name={x.iconName as any} size='large' />
                          {x.name}
                        </div>)
                      )
                    }
                  </div>
                )
              )
            }
          </div>
        )}
      </Transition>
    )
  }

  return (
    <Transition in={isOpen} timeout={duration}>
      {(state) => (
        <div className="sidebar" style={{
          ...sidebarStyle,
          ...sidebarTransitionStyles[state as 'exited' | 'entering' | 'exiting' | 'entered']
        }}>
          {renderLinks()}
        </div>
      )}
    </Transition>
  )
}

export { SidebarContent }