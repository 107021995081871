import {IRole} from "../../../../../store/cms/usersNRoles/types";

export const getRoleIDs = (inputRoles: string, roles: IRole[]): string[] | null  => {

    if (Array.isArray(inputRoles) && inputRoles.length) {
        const filteredRoles = inputRoles.map((inputRoleName) => {
            return roles.find((existingRole) => existingRole.name === inputRoleName)
        })
        return filteredRoles.map((role) => role?.name!)
    }
    return null
};
