export const groupBy = <TKey, TModel>(list: TModel[], keyGetter: (model: TModel) => TKey): Map<TKey, TModel[]> => {
  const map = new Map<TKey, TModel[]>();
  list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
          map.set(key, [item]);
      } else {
          collection.push(item);
      }
  });
  return map;
}

export const groupByMultiple = <T>(array: T[], f: (item: T) => any) => {
  let groups = {} as any;
  array.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
return Object.keys(groups).map(function (group) {
 return groups[group];
})
}

export const sequentialNumbers = (value: number): number[] => [...Array(value).keys()];
