import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {API_POST_MATCH} from "../../../constants";
import {errorLogger} from "../../../services/error-logger";
import {IPostMatchState} from "./types";
import {client} from "../../../services/api.service";
import {toastr} from "react-redux-toastr";
import {IPostMatchSocketResponse, IScoreBoardPlayersStatistics} from "../scoreboardAndStats/types";

const initialState: IPostMatchState = {
    playersStatistics: {
        statistics:[
            {   totalScore: 0,
                turns100to139: 0,
                turns140to179: 0,
                turns180: 0,
                highestCheckout: 0,
                checkouts100plus: 0,
                average: 0,
                checkoutPercentage: 0,
                checkouts: "",
                totalPoints: 0
            },
            {   totalScore: 0,
                turns100to139: 0,
                turns140to179: 0,
                turns180: 0,
                highestCheckout: 0,
                checkouts100plus: 0,
                average: 0,
                checkoutPercentage: 0,
                checkouts: "",
                totalPoints: 0
            },
        ],
        players:[
            { id: 0 ,name: ""},
            { id: 0 ,name: ""}
        ],
        groups:[]
    } as IScoreBoardPlayersStatistics,
    isLoading: true
}

export const getPostMatchStats = createAsyncThunk<IPostMatchState>(
    'postMatch/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_POST_MATCH);
            return data;
        } catch (error) {
            toastr.error('Post match', 'Failed to fetch data.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `tryGetPostMatchStats: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const postMatchSlice = createSlice({
    name: 'postMatch',
    reducers: {
        setPostMatchPlayerStatistics: (state, action: PayloadAction<IPostMatchSocketResponse>) => {
            state.playersStatistics.statistics = action.payload.playersStatistics.statistics
            state.playersStatistics.players = action.payload.playersStatistics.players
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPostMatchStats.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPostMatchStats.fulfilled, (state, action) => {
                state.playersStatistics = action.payload.playersStatistics;
                state.isLoading = false;
            })
            .addCase(getPostMatchStats.rejected, (state) => {
                state.isLoading = false;
            })

    }})

export const {setPostMatchPlayerStatistics} = postMatchSlice.actions;
export default postMatchSlice.reducer;
