import React, {PropsWithChildren, useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import moment from "moment";
import "./styles.scss";
import {client} from "../../../../../services/api.service";
import {AxiosResponse} from "axios";
import {useAppDispatch} from "../../../../../hooks/hooks";
import {setFirstPlayer} from "../../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {
    ICurrentShootOutGame, IDartsCurrentGameState,
    IFirstPlayerPayload
} from "../../../../../store/dartsGame/currentGame/types";
import {GameTypes} from "../../../../../enums/gameEvents";
import {API_DARTS_SHOOTOUT_GAMES} from "../../../../../constants";
import {formatPlayerName} from "./helpers/getSeparateDisplayNames";


interface IScoreBoardProps {
    currentPlayerId: number | null;
    initData: IDartsCurrentGameState;
    gameId: number
    propsAreEqual?: (
        prevProps: Readonly<PropsWithChildren<IScoreBoardProps>>,
        nextProps: Readonly<PropsWithChildren<IScoreBoardProps>>
    ) => boolean
}

const ShootOutScoreBoard: React.FC<IScoreBoardProps> = React.memo((
    {
        currentPlayerId,
        initData,
        gameId
    }) => {
    const [currentGameInfo, setCurrentGameInfo] = useState<ICurrentShootOutGame | null>(null)
    const dispatch = useAppDispatch();
    const {turnScores,players} = initData
    const setDartsPlayer = (
        currentPlayer: number | null,
        playerId: number | undefined,
        payload: IFirstPlayerPayload
    ) => {

        if (!currentPlayer) {
            const setFirstPlayerPayload = {
                gameType: GameTypes.SHOOTOUT,
                gameData: payload
            }
            dispatch(setFirstPlayer(setFirstPlayerPayload))
        } else {
            return alert("Game already started and first player already changed");
        }
    };
    const basicStyles: React.CSSProperties = {
        width: "33.3333%",
        height: "100%",
        position: "absolute",
        background: "#fbbd08",
        color: '#454d55'
    };
    const getActiveGameInfo = async (gameId: number) => {
        try {
            const resp: AxiosResponse = await client.get(`${API_DARTS_SHOOTOUT_GAMES}/${gameId}`)
            const DartsGames9Shots: ICurrentShootOutGame = resp.data;
            setCurrentGameInfo(DartsGames9Shots);
        } catch (e) {
            if (e instanceof Error) {
                console.log(e.message)
            }
        }
    };

    useEffect(() => {
        if (gameId) {
            getActiveGameInfo(gameId)
        }
    }, [gameId]);

    const getSelectedColumnStyle = (id: number | null) => {
        if (!currentPlayerId) {
            return {...basicStyles, display: "none"};
        } else if (id === currentGameInfo?.player1id) {
            return basicStyles;
        } else if (id === currentGameInfo?.player2id) {
            return {...basicStyles, right: '33%'};
        } else {
            return {...basicStyles, right: 0};
        }
    };

    const getTextColorForColumn = (id: number | undefined) => id === currentPlayerId ? {color: "#454d55"} : {color: "white"};

    return (
        <Grid columns={3}>
            <div
                className="custom-background"
                style={getSelectedColumnStyle(currentPlayerId)}
            />
             <Grid.Row>
                 {players?.map(player => {
                     return (
                         <Grid.Column style={getTextColorForColumn(player.id)}>
                             <Player
                                 playerName={formatPlayerName(player.name).name}
                                 playerSurname={formatPlayerName(player.name).surname}
                                 onSelect={setDartsPlayer}
                                 playerId={player.id}
                                 gameId={gameId}
                                 currentPlayerId={currentPlayerId}
                             />
                         </Grid.Column>
                     )
                 })}
            </Grid.Row>
            <Grid.Column style={getTextColorForColumn(initData?.players?.[0].id)}>
                {turnScores && turnScores[0]?.map((score: number) => {
                    return (
                        <div key={score}>
                            <div style={{paddingBottom: "10px"}}>
                                {score}
                            </div>
                        </div>
                    )
                })}
            </Grid.Column>
            <Grid.Column style={getTextColorForColumn(initData?.players?.[1].id)}>
                {turnScores && turnScores[1]?.map((score: number) => {
                    return (
                        <div key={score}>
                            <div style={{paddingBottom: "10px"}}>
                                {score}
                            </div>
                        </div>
                    )
                })}
            </Grid.Column>
            <Grid.Column style={getTextColorForColumn(initData?.players?.[2].id)}>
                {turnScores && turnScores[2]?.map((score: number) => {
                    return (
                        <div key={score}>
                            <div style={{paddingBottom: "10px"}}>
                                {score}
                            </div>
                        </div>
                    )
                })}
            </Grid.Column>
        </Grid>
    );
})

interface IPlayerProps {
    playerId: number | undefined;
    playerName: string | undefined;
    playerSurname: string | undefined;
    leg?: number;
    onSelect: (playerId: any,currentPlayer:any, payload: any) => void;
    gameId: number;
    currentPlayerId: number | null;
}

const Player: React.FC<IPlayerProps> = (
    {
        playerName,
        playerSurname,
        onSelect,
        leg,
        playerId,
        gameId,
        currentPlayerId
    }) => {
    const payload = {
        gameId,
        playerId,
        date: moment().utc().toDate(),
    };
    return (
        <div
            className="player-name__shoot-container"
            onClick={() => onSelect(currentPlayerId,playerId, payload)}
        >
            <div className="player-name__score">{leg}</div>
            <div className="player-name__shoot-details">
                <div className="player-name__shoot-details-name">{playerName}</div>
                <div className="player-name__shoot-details-surname">{playerSurname}</div>
            </div>
        </div>
    );
};

export {ShootOutScoreBoard};
