import {IPreMatchState, IPreMatchStatistic} from "./types";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {API_PRE_MATCH} from "../../../constants";
import {errorLogger} from "../../../services/error-logger";
import {client} from "../../../services/api.service";
import {toastr} from "react-redux-toastr";

const initialState: IPreMatchState = {
    gameStatistic: {} as IPreMatchStatistic,
    isReady: false,
    isDataDownloaded: false
};

export const getPreMatchStats = createAsyncThunk<IPreMatchStatistic>(
    'preMatch/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_PRE_MATCH);
            return data;
        } catch (error) {
            toastr.error('Pre match', 'Failed to fetch data.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `tryGetPreMatchStats: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const preMatchSlice = createSlice({
    name: 'preMatch',
    reducers: {},
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getPreMatchStats.pending, (state) => {
                state.isDataDownloaded = false;
                state.isReady = false;
            })
            .addCase(getPreMatchStats.fulfilled, (state, action) => {
                state.gameStatistic = action.payload;
                state.isDataDownloaded = true;
                state.isReady = true;

            })
            .addCase(getPreMatchStats.rejected, (state) => {
                state.isDataDownloaded = true;
                state.isReady = true;
            })
    }})

export default preMatchSlice.reducer;
