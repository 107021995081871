import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {LS_TOKEN} from "../constants/localStorageItems";
import {useAppSelector} from "../hooks/hooks";


const PrivateRouteComponent: React.FC<any> = ({component: Component, roles, ...rest}: any) => {
    const user = useAppSelector((state) => state.auth)
    const renderPriveRoute = () => {
        return (
            <Route {...rest} render={(props) => {
                const currentUser = user;
                const token = localStorage.getItem(LS_TOKEN);
                if (!currentUser && token) {
                    return renderSpinner();
                }
                if (!currentUser && !token) {
                    return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                }


                if (rest.render) {
                    return rest.render();
                }

                return <Component {...props} />

            }}/>
        );
    }

    return renderPriveRoute()
}

export function mapStateToProps(state: any, ownProps: any) {
    return {
        user: state.user
    };
}

const PrivateRoute = connect(mapStateToProps, null)(PrivateRouteComponent);
export {PrivateRoute};

export const renderSpinner = () => {
    const style = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#171b21',
        color: 'white'
    }
    return <div style={style}>
        ...Loading
    </div>
}
