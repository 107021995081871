import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {API_DARTS_ACTIVE_GAME_SCORE, API_DARTS_ACTIVE_GAME_SCORE_ADC,} from "../../../../constants";
import {toastr} from "react-redux-toastr";
import {ActiveGamScoreReducer, IActionGameScorePayload, IActiveTournamentGameData} from "./types";
import {GameTypes} from "../../../../enums/gameEvents";

const initialState: ActiveGamScoreReducer = {
    activeGameData: {} as IActiveTournamentGameData,
    loader: false
}

export const getActiveGameScoreData = createAsyncThunk<IActiveTournamentGameData, IActionGameScorePayload, { rejectValue: string }>(
    'activeGameScore/get',
    async (gameParams, {rejectWithValue}) => {
        try {
            if (gameParams.gameType === GameTypes.ADC) {
                const {data} = await client.get(`${API_DARTS_ACTIVE_GAME_SCORE_ADC}/${gameParams.gameId}`);
                return data;
            }
            if (gameParams.gameType === GameTypes.CLASSIC) {
                const {data} = await client.get(`${API_DARTS_ACTIVE_GAME_SCORE}/${gameParams.gameId}`);
                return data;
            }
        } catch (e) {
            toastr.error('Active handler score:', `${e.response.data}`);
            console.log(e?.message)
            return rejectWithValue(e.response.data);
        }

    }
)


export const activeGameScoreSlice = createSlice({
    name: 'activeGameScore',
    reducers: {
        setActiveGameScoreData: (state, action: PayloadAction<IActiveTournamentGameData>) => {
            state.activeGameData = action.payload;
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getActiveGameScoreData.pending, (state: ActiveGamScoreReducer) => {
                state.loader = true;
            })
            .addCase(getActiveGameScoreData.fulfilled, (state: ActiveGamScoreReducer, action) => {
                state.activeGameData = action.payload;
                state.loader = false;
            })
            .addCase(getActiveGameScoreData.rejected, (state: ActiveGamScoreReducer) => {
                state.loader = false;
            })
    }})

export const {setActiveGameScoreData} = activeGameScoreSlice.actions;
export default activeGameScoreSlice.reducer;
