import {DartsAdcGamesData, DartsCompetitionAdc} from "../../../../../store/cms/darts/adcGames/types";
import {useMemo} from "react";

interface IUseGetCompDefaultOptions {
    selectedGame: DartsAdcGamesData | null,
    competitions: DartsCompetitionAdc[];
}
export const useGetCompDefaultOptions = ({selectedGame, competitions}: IUseGetCompDefaultOptions) => {
    return useMemo(() => {
        const competition = competitions?.filter((competition) => {
            return competition.id === selectedGame?.competitionId
        })
        if (competition) {
            return {
                key: competition[0]?.id,
                value: competition[0]?.id,
                text: competition[0]?.name,
            }
        }

    }, [selectedGame, competitions]);
}
