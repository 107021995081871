import React from 'react'
import './style.scss';
import './style-media.scss'
import {IPlayerAnimateData} from "../../../PreMatchPage";
import {removeNameSeparator} from "../../../../../helpers/playersNames.helper";

interface IPlayerStats {
    playerHome: IPlayerAnimateData,
    playerAway: IPlayerAnimateData
}

const PlayerNames: React.FC<IPlayerStats> = ({playerHome, playerAway}) => {

    return (
        <>
            <div className="pre-match-player-names">
                <div className="pre-match-player">
                    <h1 className="pre-match-player__name">{removeNameSeparator(playerHome?.displayName)}</h1>
                </div>
                <div>
                    <h1 className="pre-match-player__surname">VS</h1>
                </div>
                <div className="pre-match-player">
                    <h1 className="pre-match-player__name">{removeNameSeparator(playerAway?.displayName)}</h1>
                </div>
            </div>
        </>

    );
}

export default PlayerNames;
PlayerNames.displayName = "PlayerNames";
