import {IPlayerAnimateData} from "../PreMatchPage";
import React, {useEffect} from "react";
import './styles.scss';
import './styles-media.scss';
import mmsLogo from '../../../img/MSSLogoMain.png';
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {getPreMatchStats} from "../../../store/pages/preMatch/preMatchSlice";
import {splitName} from "../../LeagueTablePage/LeagueTable/helpers/splitPlayerName";


export interface IStaticStatisticProps {
    playerHome: IPlayerAnimateData,
    playerAway: IPlayerAnimateData,
}

const StaticStatistic: React.FC<IStaticStatisticProps> = () => {

    const dispatch = useAppDispatch();
    const {gameStatistic, isDataDownloaded} = useAppSelector((state) => state.preMatch);

    useEffect(() => {
        dispatch(getPreMatchStats())
    }, [dispatch])

    const formatAverageScore = (average: number): string => {
        const stringAverage = average.toString().split('.')[1]
        if (!stringAverage) return average + ".00"
        if (stringAverage.length === 1) return average + "0"
        return average.toString()
    }

    const splitPlayerData = (stats: number | string, index: number, separator: string) => {
        const separateData = String(stats).split(separator)[index];
        if (!separateData) return '00';
        return separateData;
    }

    const getAverageCheckoutValue = (average: number, percentSymbol?: string) => {
        return (
            <>
                {splitPlayerData(formatAverageScore(average), 0, '.')}
                <span className='static-statistic-value-part'>
                .{splitPlayerData(formatAverageScore(average), 1, '.')}{percentSymbol}
                </span>
            </>
        )
    }
    const fixedValues = {
        winPercentHome: gameStatistic?.playerHome?.winningPercent?.toFixed(2),
        perMatchHome: gameStatistic?.playerHome?.perMatch180?.toFixed(2),
        winPercentAway: gameStatistic?.playerAway?.winningPercent?.toFixed(2),
        perMatchAway: gameStatistic?.playerAway?.perMatch180?.toFixed(2),
    }
    const getLastGames = (value: any) => {
        const result = value.map((item: boolean | undefined) => {
            switch (item) {
                case true :
                    return 'W'
                case false:
                    return 'L'
            }
        })
        return result.join('')
    }

    return (
        <>
            {
                isDataDownloaded
                    ?
                    <div className="pre-match-page">
                        <div className="static-statistic">
                            <div className="static-statistic-wrapper">
                                <div className="static-statistic-names">
                                    <div>
                                        <span className={"gold-firstname"}>{splitName(gameStatistic.playerHome.displayName)[0]}</span>
                                        <span>{splitName(gameStatistic.playerHome.displayName)[1]}</span>
                                    </div>
                                    <div>
                                        <span className={"gold-firstname"}>{splitName(gameStatistic.playerAway.displayName)[0]}</span>
                                        <span>{splitName(gameStatistic.playerAway.displayName)[1]}</span>
                                    </div>
                                </div>
                                <div className="static-statistic-container">
                                    <div className="static-statistic-item static-statistic-item-odd">
                                        <div className="static-statistic-item-value">
                                            {getAverageCheckoutValue(gameStatistic?.playerHome.average)}
                                        </div>
                                        <div className="static-statistic-item-title">overall 3 dart average</div>
                                        <div className="static-statistic-item-value">
                                            {getAverageCheckoutValue(gameStatistic?.playerAway.average)}
                                        </div>
                                    </div>
                                    <div className="static-statistic-item static-statistic-item-even">
                                        <div className="static-statistic-item-value">
                                            {getAverageCheckoutValue(gameStatistic?.playerHome.checkoutPercentage, '%')}
                                        </div>
                                        <div className="static-statistic-item-title">overall checkout %</div>
                                        <div className="static-statistic-item-value">
                                            { getAverageCheckoutValue(gameStatistic?.playerAway.checkoutPercentage, '%')}
                                        </div>
                                    </div>
                                    <div className="static-statistic-item static-statistic-item-odd">
                                        <div className="static-statistic-item-value">
                                            {gameStatistic?.playerHome.winLossRecord}
                                        </div>
                                        <div className="static-statistic-item-title">win-loss record</div>
                                        <div className="static-statistic-item-value">
                                            {gameStatistic?.playerAway.winLossRecord}
                                        </div>
                                    </div>
                                    <div className="static-statistic-item static-statistic-item-even">
                                        <div className="static-statistic-item-value">
                                            {getLastGames(gameStatistic?.playerHome.lastGames)}
                                        </div>
                                        <div className="static-statistic-item-title">last 5 results</div>
                                        <div className="static-statistic-item-value">
                                            {getLastGames(gameStatistic?.playerAway.lastGames)}
                                        </div>
                                    </div>
                                    <div className="static-statistic-item static-statistic-item-odd">
                                        <div className="static-statistic-item-value">
                                            { getAverageCheckoutValue(+fixedValues.winPercentHome, '%')}
                                        </div>
                                        <div className="static-statistic-item-title">win %</div>
                                        <div className="static-statistic-item-value">
                                            {getAverageCheckoutValue(+fixedValues.winPercentAway, '%')}
                                        </div>
                                    </div>
                                    <div className="static-statistic-item static-statistic-item-even">
                                        <div className="static-statistic-item-value">
                                            {getAverageCheckoutValue(+fixedValues.perMatchHome)}
                                        </div>
                                        <div className="static-statistic-item-title">180 per match</div>
                                        <div className="static-statistic-item-value">
                                            {getAverageCheckoutValue(+fixedValues.perMatchAway)}
                                        </div>
                                    </div>
                                    <div className="static-statistic-item">
                                        <div className="static-statistic-logo">
                                            <img src={mmsLogo} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="pre-match-page">
                        <div className="blackBgLayout">
                            <OverlayLoader/>
                        </div>
                    </div>
            }
        </>

    )
}

export default StaticStatistic;
StaticStatistic.displayName = "StaticStatistic";

