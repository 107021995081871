import React, {useState} from "react";
import {Button, Table, Input, Loader, Icon} from "semantic-ui-react";
import {useEffect} from "react";
import DartsPlayersTableForm from "../../../../components/Form/DartsPlayersTableForm/DartsPlayersTableForm";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import moment from "moment/moment";
import PhotoUploader from "./PhotoUploader/PhotoUploader";
import {
    getDartsPlayers,
    playersShowMode,
    playersEditMode,
    playersEditPhotoMode,
    setPageNumber,
    deleteDartsPlayer, changePageSize,
} from "../../../../store/cms/darts/players/cmsPlayersSlice";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {setAdcQualifier} from "./helpers/setAdcQualifier";
import {showFullSizePhoto} from "./helpers/showFullSizePhoto";
import './style.scss'
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";
import {useDebounce} from "../../../../hooks/useDebounce";
import {client} from "../../../../services/api.service";
import {API_PLAYERS_PHOTO} from "../../../../constants";
import {errorLogger} from "../../../../services/error-logger";
import AppLoader from "../../../../components/Loader/Loader";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import AppPagination from "../../../../components/Pagination/AppPagination";

const DartsPlayersTableDashBoard = () => {
    const {
        isVisibleForm,
        dartsPlayers,
        noContent,
        isEditPhotoMode,
        totalPages,
        pageNum,
        pageSize,
        loadingPlayers
    } = useAppSelector((state) => state.cmsPlayers);
    const {isRenderControls} = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [sortField, setSortField] = useState("name");
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [isShowPhotoWidget, setIsShowPhotoWidget] = useState(false)
    const [playerPhotoId, setPlayerPhotoId] = useState<number>(0)
    const [isExistPhoto, setIsExistPhoto] = useState('')
    const [photoWidgetLoader, setPhotoWidgetLoader] = useState(false);

    useEffect(() => {
        dispatch(getDartsPlayers(
            buildQueryString({
                pageNum,
                pageSize,
                sortField,
                sort: sortDirection === "ascending" ? 0 : 1
            }))
        );
    }, []);


    const onSort = (field: string) => {
        const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
        setSortField(field);
        setSortDirection(direction);
        const payload = {
            pageNum,
            pageSize,
            sortField,
            sort: direction === "ascending" ? 0 : 1
        }
        dispatch(getDartsPlayers(buildQueryString(payload)))
    };

    const gotToPhotoWidget = async (id: number) => {
        try {
            setPhotoWidgetLoader(true)
            setPlayerPhotoId(id)
            const playersPhoto = await client.get(`${API_PLAYERS_PHOTO}/${id}`)
            setIsExistPhoto(playersPhoto.data)
            setPhotoWidgetLoader(false)
            setIsShowPhotoWidget(true)

        } catch (error) {
            setPhotoWidgetLoader(false)
            if (error instanceof Error) {
                console.error(error.message);
                const errorLog = {
                    projectName: 'DC',
                    errorMessage: `getPlayerPhotoById: ${error.name}: ${error.message}`,
                    errorDate: new Date()
                }
                errorLogger(errorLog)
            }
        }
    }
    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum: activePage, pageSize, search: searchValue}
        dispatch(getDartsPlayers(buildQueryString(params)));
    }

    const onSearch = () => {
        dispatch(setPageNumber(1))
        dispatch(getDartsPlayers(buildQueryString({
            pageNum: 1,
            pageSize,
            search:
            searchValue,
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1
        })));
    };

    useDebounce(onSearch, searchValue, 500)

    const onChangePageSize = (value: any) => {
        dispatch(changePageSize(value))
        const params = {pageNum, pageSize: value, search: searchValue}
        dispatch(getDartsPlayers(buildQueryString(params)));
    }

    return (
        <div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>
                    <Input
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Search....."
                    />
                </div>
                {isRenderControls && <Button
                    color="green"
                    id="add_player"
                    onClick={() => dispatch(playersShowMode())}
                >
                    Add
                </Button>}
            </div>
            {loadingPlayers && <DimmerLoader/>}
            {!loadingPlayers && <Table className='players-table' sortable celled compact>
                <Table.Header>
                    <Table.Row>
                        {isRenderControls &&
                            <Table.HeaderCell
                                textAlign="center"
                            >
                                Id
                            </Table.HeaderCell>
                        }
                        <Table.HeaderCell
                            sorted={
                                sortField === "name"
                                    ? sortDirection === "ascending"
                                        ? "ascending"
                                        : "descending"
                                    : undefined
                            }
                            onClick={() => onSort("name")}
                            textAlign="center"
                        >
                            Name
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={sortField === "surname" ? sortDirection === "ascending" ? "ascending" : "descending" : undefined}
                            onClick={() => onSort("surname")}
                            textAlign="center"
                        >
                            Surname
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center"
                        >
                            Avatar

                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">Nickname</Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">Abbr</Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">Gender</Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            Displayname
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">Hometown</Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            Nationality
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            Darts used
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            Career best
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            Throw hand
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            Date of birth
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                            ADC Qualifier
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className="table-header-cell"
                            textAlign="center">
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {dartsPlayers?.map((player) => {
                        return (
                            <Table.Row key={player.id}>
                                {isRenderControls &&
                                    <Table.Cell textAlign="center">{player.id}</Table.Cell>
                                }
                                <Table.Cell textAlign="center">{player.name}</Table.Cell>
                                <Table.Cell textAlign="center">{player.surname}</Table.Cell>
                                <Table.Cell textAlign="center"><img
                                    alt='players-avatar'
                                    className='players-table-avatar'
                                    src={`data:image/png;base64,${player.avatar}`}
                                    onClick={() => showFullSizePhoto({
                                        id: player.id,
                                        setIsExistPhoto,
                                        dispatch
                                    })}
                                /></Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.nickname}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.abbr.slice(0, 3)}
                                </Table.Cell>
                                <Table.Cell textAlign="center">{player.gender}</Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.displayname}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.hometown}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.nationality}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.dartsUsed}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.careerBest}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {player.throwHand}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {moment(player.dateOfBirth).format("YYYY-MM-DD")}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {setAdcQualifier(player.adcQualifier)}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                    {isRenderControls && <div className={"players-table-controls"}>
                                        <DartsDeleteModal
                                            id={player.id}
                                            confirmText={
                                                "Are you sure you want to delete your player?"
                                            }
                                            deleteAction={() => dispatch(deleteDartsPlayer(player.id))}
                                            titleModal={"Delete your Player"}
                                        />
                                        <Icon
                                            name='pencil alternate'
                                            link
                                            color="grey"
                                            onClick={() => dispatch(playersEditMode(player.id))}
                                            size="small"
                                        >
                                        </Icon>
                                        {!photoWidgetLoader && <Icon
                                            name='photo'
                                            link
                                            color='blue'
                                            size="small"
                                            onClick={() => gotToPhotoWidget(player.id)}
                                        >
                                        </Icon>}
                                        {photoWidgetLoader && player.id === playerPhotoId &&
                                            <AppLoader/>
                                        }
                                    </div>
                                    }
                                    {isEditPhotoMode &&
                                        <div className="photo-uploader__show-full-size">
                                            <div
                                                onClick={() => dispatch(playersEditPhotoMode(false))}
                                                className="photo-uploader__show-full-size-close"/>
                                            {
                                                isExistPhoto ?
                                                    <img src={`data:image/png;base64,${isExistPhoto}`}
                                                         alt="full-players-photo"/>
                                                    :
                                                    <Loader active/>
                                            }
                                        </div>
                                    }
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>}
            {noContent && <div className="schedule-info-block">No players</div>}
            {isVisibleForm && <DartsPlayersTableForm/>}
            {!noContent && (
                <AppPagination
                    activePage={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onChangePager={onChangePager}
                    onChangePageSize={onChangePageSize}
                />
            )}
            {isShowPhotoWidget &&
                <PhotoUploader
                    isPlayerPhoto={isExistPhoto}
                    playerId={playerPhotoId}
                    closeUploaderHandler={setIsShowPhotoWidget}
                />}
        </div>
    );
};

export default DartsPlayersTableDashBoard;
