import React, {Fragment, useCallback, useEffect, useRef, useState,} from "react";
import {ConfirmationModal} from "../../../components/ConfirmationModal";
import moment from "moment";
import {GameStatus, GameStatuses} from "../../../enums/gameStatus";
import {Button, Grid} from "semantic-ui-react";
import {ActionsContainer} from "../../MatchContainer/components/ActionsContainer/ActionsContainer";
import {useHistory, useParams} from "react-router-dom";
import EndGameModal from "../../../components/EndGameModal/EndGameModal";
import AlertModal from "../../../components/DartsGameAlertModal/AlertModal";
import Dartboard from "../components/Dartbord/dartboard_lib";
import {WarningModal} from "../components/WarningModal/WarningModal";
import {ShootOutScoreBoard} from "./components/ShootOutScoreBoard/ShootOutScoreBoard";
import classnames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    IGameIdDatePayload,
    IMatchStatusPayload,
    IThrowShootPayloadData
} from "../../../store/dartsGame/currentGame/types";
import {
    addShootOutThrow,
    getInitScoreForShootOutGame,
    setDartsGameStatus,
    undoThrow
} from "../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {GameTypes, Score, SportType} from "../../../enums/gameEvents";
import {createActiveShootoutGameAfterRefreshPage} from "./helpers/createActiveShootoutGameAfterRefresh";
import "./styles.scss";

const ShootOutDartBoard: React.FC = () => {
    const dispatch = useAppDispatch();
    const {
        currentShootOutGameInitData,
        isThrowAllowed,
        isUndoThrowAllowed,
        currentGameStatus,
    } = useAppSelector((state) => state.currentDartsGame);
    const {gameId} = useParams<{ gameId: string }>();
    const history = useHistory();
    const {game} = useAppSelector((state) => state.gameHandler)
    const gameState = useAppSelector((state) => state.gameHandler)
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
    const [gameStatusMessage, setGameStatusMessage] = useState("");
    const [gameStatusForSchedule, setNewGameStatusForSchedule] = useState(game?.status);
    const [isOpenEndGameModal, setIsOpenEndGameModal] = useState(false);
    const [isActionButtonClicked, setActionButtonClicked] = useState(false);
    const defaultSizeOfDartboard = window.innerHeight * 0.9;
    const currentPlayerId = currentShootOutGameInitData?.playerId;
    const [alertForWaitResponse, setAlertForWaitResponse] = useState(false);
    const [size, setSize] = useState<{ width: Number; height: number }>({
        width: defaultSizeOfDartboard,
        height: defaultSizeOfDartboard,
    });
    const showAlertModal = () => {
        if (!isThrowAllowed) {
            setAlertForWaitResponse(true)
        }
        if (isThrowAllowed) {
            setAlertForWaitResponse(false)
        }
    }

    const currentPlayer: number | null = currentShootOutGameInitData.playerId;

    const handleThrow = (lastShot: IThrowShootPayloadData, isAllowed: boolean, showAlertModal: () => void) => {
        if (!currentPlayer) {
            alert("Please, select player");
            return;
        }
        if (!isAllowed) {
            showAlertModal()
            return
        }
        dispatch(addShootOutThrow(lastShot))
    };

    const onThrow = useCallback(
        ({detail: score}: { detail: Score }) => {
            if (currentGameStatus === GameStatuses.PreEnd) {
                alert("Game is over. Use the 'EndGame' button");
                return;
            }
            const lastShot: IThrowShootPayloadData = {
                gameId: Number(gameId),
                playerId: currentShootOutGameInitData.playerId!,
                score: score.score,
                date: moment().utc().toDate(),
                bed: score.bed,
                ring: score.ring,
            };
            handleThrow(lastShot, isThrowAllowed, showAlertModal);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [currentShootOutGameInitData.playerId, showAlertModal, isThrowAllowed, currentGameStatus]
    );


    useEffect(() => {
        if (gameId) {
            dispatch(getInitScoreForShootOutGame(+gameId));
        } else {
            createActiveShootoutGameAfterRefreshPage(+gameId,dispatch);
        }
    }, [game]);

    const dartboardRef = useRef<any>(null);


    useEffect(() => {
        if (isThrowAllowed) {
            setAlertForWaitResponse(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleThrow]);

    useEffect(() => {
        // https://www.npmjs.com/package/dartboard
        if (dartboardRef.current?.id) {
            dartboardRef.current.innerHTML = "";
            window.addEventListener("resize", handleWindowResize);
            const dartboard = new Dartboard(`#${dartboardRef.current.id}`);
            dartboard.render();
        }

        return () => {
            if (dartboardRef.current?.id) {
                window.removeEventListener("resize", handleWindowResize);
            }
        };
    }, [game,size]);

    useEffect(() => {
        if (!dartboardRef.current) {
            return;
        }
        dartboardRef.current.addEventListener("throw", onThrow);
        return () =>
            dartboardRef.current &&
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dartboardRef.current.removeEventListener("throw", onThrow);
    }, [dartboardRef, onThrow, game]);


    const handleWindowResize = () => {
        const width = window.innerHeight * 0.9;
        const height = width;

        setSize({ width, height });

        if (dartboardRef.current) {
            dartboardRef.current.innerHTML = "";
            new Dartboard(`#${dartboardRef.current.id}`).render();
        }
    };

    const onClickActionButtonDartboard = (
        newStatus: GameStatus,
        gameStatus: GameStatuses,
        message: string
    ) => {
        setGameStatusMessage(message);
        setNewGameStatusForSchedule(gameStatus);
        setActionButtonClicked(true);
    };

    const onConfirmActionModal = () => {
        if (
            gameStatusForSchedule === game.status
        ) {
            setActionButtonClicked(false);
            return;
        }
        const matchStatus: IMatchStatusPayload = {
            gameData: {
                gameId: gameId,
                status: gameStatusMessage,
                date: moment().utc().toDate(),
            },
            playerId:null,
            status:gameStatusForSchedule,
            gameType: GameTypes.SHOOTOUT,
            history
        };
        dispatch(setDartsGameStatus(matchStatus));
        return setActionButtonClicked(false);
    };

    const handleUndo = (isAllowed: boolean) => {
        if (isAllowed) {
            const undoPayload: IGameIdDatePayload = {
                gameData: {
                    gameId: +gameId,
                    date: moment().utc().toDate(),
                },
                gameType: GameTypes.SHOOTOUT
            };
            dispatch(undoThrow(undoPayload));
        } else {
            alert("Please wait for a response from the server");
        }
    };

    const handleBounce = () => {
        const score = {
            score: 0,
            bed: "B0",
            ring: "BounceOut",
            date: moment().utc().toDate(),
            isDoubleAttempt: false,
            scoreBeforeShot: 0,
        };
        onThrow({detail: score});
    };
    return (
        <Fragment>
            {game && (
                <Grid className="darts" centered>
                    <AlertModal open={alertForWaitResponse} text={'Please await response...'}/>
                    {(
                        <Grid.Column computer={8} tablet={13}>
                            <div
                                className="darts__container"
                                style={{justifyContent: "center"}}
                            >
                                {(
                                    <Fragment>
                                        <div className="undo-button-container">
                                            <Button
                                                className="undo-button"
                                                color="red"
                                                onClick={() => handleUndo(isUndoThrowAllowed)}
                                                disabled={currentPlayerId === null && currentGameStatus !== GameStatuses.PreEnd}
                                            >
                                                Undo
                                            </Button>
                                        </div>
                                        <div
                                            ref={dartboardRef}
                                            id="dartboard"
                                            className={classnames("darts__container__board", {
                                                "darts__container__board--disabled": false,
                                            })}
                                            style={{
                                                width: `${size.width}px`,
                                                height: `${size.height}px`,
                                            }}
                                        />
                                        <div className="bounce_out">
                                            <Button onClick={handleBounce} color="blue">
                                                Bounce
                                            </Button>
                                        </div>
                                    </Fragment>
                                )}
                            </div>
                        </Grid.Column>
                    )}
                    <Grid.Column computer={8} stretched tablet={13}>
                        <Grid padded>
                            <Grid.Row className="h-65">
                                <div className="darts__details__scoreboard-shootout">
                                    <ShootOutScoreBoard
                                        currentPlayerId={currentPlayerId}
                                        initData={currentShootOutGameInitData}
                                        gameId={currentShootOutGameInitData.gameId!}
                                    />
                                </div>
                            </Grid.Row>
                            <Grid.Row stretched className="h-35">
                                <div className="darts__details__actions">
                                    <ActionsContainer
                                        setIsOpenEndGameModal={setIsOpenEndGameModal}
                                        isReadonly={gameState.isReadonly}
                                        sportType={SportType.Darts}
                                        isShootOutGame={true}
                                        onClickActionButton={onClickActionButtonDartboard}
                                    />
                                </div>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            )}

            {isOpenEndGameModal && (
                <EndGameModal
                    isOpen={isOpenEndGameModal}
                    onCloseModal={setIsOpenEndGameModal}
                />
            )}

            <WarningModal
                isOpen={isConfirmModalOpen}
                message="Please, select player"
                onClick={() => setIsConfirmModalOpen(false)}
            />
            <ConfirmationModal
                open={
                    isActionButtonClicked && gameStatusForSchedule !== GameStatuses.Walkover
                }
                content={`Confirm game to be ${gameStatusMessage}?`}
                onConfirm={onConfirmActionModal}
                onDecline={() => setActionButtonClicked(false)}
            />
        </Fragment>
    );
};


ShootOutDartBoard.displayName = "ShootOutDartBoard";
export default ShootOutDartBoard;
