import React, {FC} from 'react';

interface IScoreTableProps {
    homeValue:any
    awayValue:any
    title:string
}
const ScoreTable:FC<IScoreTableProps> = ({homeValue,awayValue,title}) => {
    return (
        <div className="score-table score-table-average">
            <div className="scores-header">
                {title}
            </div>
            <div className="table-body">
                <div
                    className="table-body-inner table-body-inner-average-checkouts">
                    {homeValue}
                </div>
                <div
                    className="table-body-inner table-body-inner-average-checkouts">
                    {awayValue}
                </div>
            </div>
        </div>
    );
};

export default ScoreTable;
