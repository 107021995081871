import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Grid} from 'semantic-ui-react';
import OverlayLoader from "../../../components/OverlayLoader/OverlayLoader";
import ShootOutPlayerScoreboardHeader from "../PlayerShootOutScoreboardHeader/ShootoutPlayerScoreboardHeader";
import ShootOutPlayerScoreboardBody from "../PlayerShootOutScoreboardBody/ShootOutPlayerScoreboardBody";
import '../styles.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {
    getShootOutActiveGameScoreData
} from "../../../store/pages/shootOutActiveGameScore/shootOutActiveGameScoreSlice";


const ShootOutActiveGameScore: React.FC = () => {
    const {gameId} = useParams();
    const dispatch = useAppDispatch();
    const {activeGameData} = useAppSelector((state) => state.shootOutActiveGameScore);
    const connected = useAppSelector((state) => state.connection.connection);
    const {players, playerId, turnScores, scores} = activeGameData

    useEffect(() => {
        if (connected && gameId)
            dispatch(getShootOutActiveGameScoreData(Number(gameId)));
    }, [connected, gameId]);

    return (
        <Grid columns={3} className="scoreboard shootout">
            {Object.keys(activeGameData).length !== 0 && scores ? (
                <>
                    <Grid.Row className="scoreboard_header">
                        <Grid.Column>
                            <ShootOutPlayerScoreboardHeader
                                isCurrentPlayerThrow={playerId === players[0].id}
                                remainingScore={scores[0]}
                                playerName={players[0].name}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ShootOutPlayerScoreboardHeader
                                isCurrentPlayerThrow={playerId === players[1].id}
                                remainingScore={scores[1]}
                                playerName={players[1].name}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <ShootOutPlayerScoreboardHeader
                                isCurrentPlayerThrow={playerId === players[2].id}
                                remainingScore={scores[2]}
                                playerName={players[2].name}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="scoreboard_body">
                        <Grid.Column className={'scores-column'}>
                            <div className={'scores-column-item'}>
                                <ShootOutPlayerScoreboardBody
                                    turnData={turnScores[0]}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column className={'scores-column'}>
                            <div className={'scores-column-item'}>
                                <ShootOutPlayerScoreboardBody
                                    turnData={turnScores[1]}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column className={'scores-column'}>
                            <div className={'scores-column-item'}>
                                <ShootOutPlayerScoreboardBody
                                    turnData={turnScores[2]}
                                />
                            </div>
                        </Grid.Column>

                    </Grid.Row>
                </>
            ) : <OverlayLoader/>}
        </Grid>
    );
}


export default ShootOutActiveGameScore;
ShootOutActiveGameScore.displayName = "ShootOutActiveGameScore";
