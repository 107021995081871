import React from 'react';
import instFooter from "../../../../../img/inst-footer.png";
import twFooter from "../../../../../img/twitterX.png";
import fbFooter from "../../../../../img/fb-footer.png";
import "./styles.scss"
import {BestCompetitionType} from "../GameScores";

interface GameScoresFooter {
    colorType: BestCompetitionType
}

const GameScoresFooter: React.FC<GameScoresFooter> = ({colorType}) => {
    const renderFooterContent = () => {
        if (colorType === BestCompetitionType.STANDARD) {
            return (
                <div className={'social-footer-content'}>
                    <div className="social-icon">
                        <img src={instFooter} alt=""/>
                    </div>
                    <div className="social-icon">
                        <img src={twFooter} alt=""/>
                    </div>
                    <div
                        className="social-icon fb-icon">
                        <img src={fbFooter} alt=""/>
                    </div>
                    <div>@MSSdarts</div>
                </div>
            )
        }
        if (colorType === BestCompetitionType.WADC) {
            return (
                <div className={'social-footer-content'}>
                    <div>WADC WINMAU Championship Tour Finals</div>
                </div>
            )
        }

        if (colorType === BestCompetitionType.ADC) {
            return (
                <div className={'social-footer-content'}>
                    <div>ADC WINMAU Championship Tour Finals</div>
                </div>
            )
        }
    }

    return (
        <div className={'game-scores-footer'}>
            <div>
                <div className={"social-footer"}>
                    {renderFooterContent()}
                </div>
            </div>
        </div>
    );
};

export default React.memo(GameScoresFooter);
