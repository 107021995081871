import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Grid} from 'semantic-ui-react';
import PlayerScoreboardHeader from './PlayerScoreboardHeader';
import PlayerScoreboardBody from './PlayerScoreboardBody';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import PausePopUp from "./PausePopUp/PausePopUp";
import {useSpring} from "react-spring";
import './styles.scss';
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {getActiveGameScoreData} from "../../../store/pages/activeGameScore/toolkit/activeGameScoreSlice";
import {DARTS_ADC_OR_CLASSIC} from "../../../constants/globalTypes";
import {IActionGameScorePayload} from "../../../store/pages/activeGameScore/toolkit/types";
import {GameTypes} from "../../../enums/gameEvents";
import {GameStatuses} from "../../../enums/gameStatus";


const ActiveGameScore: React.FC = () => {
    const {leagueId: leagueIdParam} = useParams<{ leagueId: string }>();
    const {gameType} = useParams<{ gameType: string }>();
    const dispatch = useAppDispatch()
    const {activeGameData, loader} = useAppSelector((state) => state.activeGameScore)
    const connected = useAppSelector((state) => state.connection.connection)
    const [isShowPopUp, setIsShowPopUp] = useState<boolean>(false)
    const {players, playerId, legs, turnScores, legFinished, scores,status} = activeGameData

    useEffect(() => {

        if (connected && Object.values(gameType).length) {
            if (Object.values(gameType).length) {
                if (!leagueIdParam && gameType === GameTypes.CLASSIC) {
                    return console.warn("Tournament id required for get active tournament data.")
                }
                const payload: IActionGameScorePayload = {
                    gameId: +leagueIdParam,
                    gameType: gameType.toUpperCase() as DARTS_ADC_OR_CLASSIC
                }
                dispatch(getActiveGameScoreData(payload))
            }
        }
    }, [connected, leagueIdParam]);

    useEffect(() => {
        if (legFinished && status === GameStatuses.Running) {
            setIsShowPopUp(true)
        }
    }, [legFinished,status])

    const popUpStyle = useSpring({
        to: async (next) => {
            await next({
                opacity: isShowPopUp ? 1 : 0,
                display: isShowPopUp ? "flex" : "none",
            })
        },
        from: {opacity: 0, display: 'none'},
        config: {duration: 300}
    })

    return (
        <div>
            <PausePopUp
                animStyle={popUpStyle}
                legFinished={legFinished}
                setIsShowPopUp={setIsShowPopUp}
            />
            <Grid columns={2} className="scoreboard">
                {!loader && Object.keys(activeGameData).length !== 0 ? (
                    <>
                        <Grid.Row className="scoreboard_header">
                            <Grid.Column id="left_player">
                                <PlayerScoreboardHeader
                                    legsCount={legs[0]}
                                    isActive={playerId === players[0].id}
                                    remainingScore={scores[0]}
                                    playerName={players[0].name}/>
                            </Grid.Column>
                            <Grid.Column id="right_player">
                                <PlayerScoreboardHeader
                                    legsCount={legs[1]}
                                    isActive={playerId === players[1].id}
                                    remainingScore={scores[1]}
                                    playerName={players[1].name}
                                    reverse
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className="scoreboard_body">
                            <Grid.Column id="left_score">
                                <PlayerScoreboardBody
                                    turnData={turnScores ? turnScores[0] : []}
                                />
                            </Grid.Column>
                            <Grid.Column id="right_score">
                                <PlayerScoreboardBody
                                    turnData={turnScores ? turnScores[1] : []}
                                    reverse
                                />
                            </Grid.Column>

                        </Grid.Row>
                    </>
                ) : <OverlayLoader/>}
            </Grid>
        </div>
    );
}


export default ActiveGameScore;
ActiveGameScore.displayName = "Scoreboard";
