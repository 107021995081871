
export enum GameStatus {
  Pending = 0,
  Open = 1,
  Closed = 2,
  Void = 3
}

export enum GameStatuses {
  NotActive = 1,
  Running = 2,
  Delayed = 3,
  Suspended = 4,
  Postponed = 5,
  Walkover = 6,
  Abandoned = 7,
  Cancelled = 8,
  PreEnd = 9,
  End = 10,
}
