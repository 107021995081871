import React from "react";
import {Checkbox, Icon, Table} from "semantic-ui-react";
import {IDartsPhaseTableData} from "../../store/cms/darts/phases/types";

interface IPhaseFormTableProps {
    tableData: IDartsPhaseTableData[]
    onDeleteItem: (id: number) => void
    setFinalWeek: (weekId: number) => void
}

const PhaseFormTable: React.FC<IPhaseFormTableProps> = React.memo(({tableData, onDeleteItem, setFinalWeek}) => {
    return (
        <div>
            <Table celled>
                <Table.Body>
                    {tableData.map((item, index) => (
                        <Table.Row key={index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{item.weekNumber}</Table.Cell>
                            <Table.Cell>{item.date}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onClick={() => setFinalWeek(item.weekNumber)}
                                    label={'Final'}
                                    readOnly
                                    checked={item.isFinal}
                                />
                            </Table.Cell>
                            <Table.Cell textAlign="center">
                                <Icon
                                    color="red"
                                    name="window close"
                                    onClick={() => onDeleteItem(item.weekId)}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
});

export default PhaseFormTable;
