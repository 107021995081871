import {useEffect, useCallback, useRef} from "react";

export const useDebounce = (fn: Function, deps: any | any[], msDelay: number) => {
    const callback = useRef<Function | undefined>();
    const timeout = useRef<NodeJS.Timeout | null>(null);

    const createDebounce = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            if (callback.current) callback.current();
        }, msDelay);
    }, [msDelay]);

    const clearTimeOut = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
    }, []);

    useEffect(() => {
        callback.current = fn;
    }, [fn]);

    useEffect(() => {
        createDebounce();
        return clearTimeOut;
    }, Array.isArray(deps) ? deps : [deps]);
};