import React, {useState} from "react";
import {Button, Icon, Modal} from "semantic-ui-react";

interface IDartsDeleteModal {
    id: any;
    deleteAction: any
    confirmText: string;
    titleModal: string;
    closeUploaderHandler?: (flag: false) => void;
    btnView?: boolean
}

const DartsDeleteModal: React.FC<IDartsDeleteModal> = (
    {
        id,
        deleteAction,
        titleModal,
        confirmText,
        closeUploaderHandler,
        btnView
    }) => {
    const [open, setOpen] = useState(false);
    const deleteHandler = (id: number) => {
        deleteAction(id)
        setOpen(false);
        if (closeUploaderHandler) {
            closeUploaderHandler(false);
        }
    };

    return (
        <>
            {btnView
                ?
                <Button size="mini" color="red" onClick={() => setOpen(true)}>Delete</Button>
                :
                < Icon link
                       size="small"
                       color="red"
                       name='trash alternate outline'
                       onClick={() => setOpen(true)}>

                </Icon>
            }
            <Modal size={"mini"} open={open} onClose={() => setOpen(false)}>
                <Modal.Header>{titleModal}</Modal.Header>
                <Modal.Content>
                    <p>{confirmText}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => setOpen(false)}>
                        No
                    </Button>
                    <Button positive onClick={() => deleteHandler(id)}>
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default DartsDeleteModal;
