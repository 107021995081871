import React, {useEffect, useState} from "react";
import {animated} from "react-spring";
import './style.scss'

interface IPausePopUpProps{
    legFinished:boolean
    setIsShowPopUp: (flag:boolean)=>void
    animStyle: any
}
const PausePopUp:React.FC<IPausePopUpProps> = ({legFinished,setIsShowPopUp,animStyle}) => {
    const [counter, setCounter] = useState(10)
    const closePopUp = () => {
        setTimeout(() =>  setIsShowPopUp(false), 1000);
        setTimeout(() =>  setCounter(10), 1500);
    }
    useEffect(() => {
        if(counter !== 0 && legFinished) {
            setTimeout(() => setCounter(counter - 1), 1000);
        }
        else {
            return closePopUp()
        }
    },[legFinished,counter])

    return (
        <animated.div style={animStyle} className="pause-pop-up">
            <div className="pop-up-content">
                <div className="pop-up-text-inner">
                    <p className="pop-up-text">Please Wait:</p>
                    <p className="pop-up-text">{`${counter}`} Seconds</p>
                </div>
            </div>
        </animated.div>
    )
}

export default PausePopUp