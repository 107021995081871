type PlayerName = {
    name: string;
    surname: string;
};

export const formatPlayerName = (input: string): PlayerName => {
    if (input.includes("_")) {
        const [firstName, ...rest] = input.split("_");
        return {
            name: firstName,
            surname: rest.join(" "),
        };
    } else {
        const [firstName, ...rest] = input.split(" ");
        return {
            name: firstName,
            surname: rest.join(" "),
        };
    }
};
