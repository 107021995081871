import React from "react";
import {Grid} from "semantic-ui-react";
import ShootOutGameScores from "../DartBoard/components/ShootOutGameScores/ShootOutGameScores";
import "./styles.scss";

interface IDartMatchStatsTableProps {
    legsCount: number;
    match: any;
    isConnected: boolean;
    statistics: any;
    getScoreboardAndStats: (gameId: number) => void;
    getDartsData: (gameId: number) => void;
    getEvents: () => void;
    subscribeOnGameUpdates: (matchId: number) => any;
}

const ShootOutMatchStatsTable: React.FC<IDartMatchStatsTableProps> = () => {


    // useEffect(() => {
    //     if (match && match.params.gameId && isConnected) {
    //         connectToTheGameGroupRoutine(match.params.gameId);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isConnected]);

    return (
        <div className="match-stats-shoot-out">
            <Grid>
                <Grid.Row>
                    <Grid.Column className="panel-column" width="5">
                        <div className="logo-container">
                            <img
                                alt="logo"
                                className="logo"
                                src="/modus-home-page-logo.png"
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column className="content-column" width="10">
                        <div className="content">
                            <div className="border-container">
                                <div className="game-scores-wrapper">
                                    <ShootOutGameScores/>
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
};




export default ShootOutMatchStatsTable;
ShootOutMatchStatsTable.displayName = "ShootOutMatchStatsTable";
