import React, {FunctionComponent, useEffect, useState} from 'react';
import {Button, Form, FormField, Icon, Label, Message, Select} from "semantic-ui-react";
import {Controller, useForm} from "react-hook-form";
import Flatpickr from "react-flatpickr";
import TimePicker from "rc-time-picker";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
    IShootOutGameEditPayload,
    IShootOutGamePayload
} from "../../../../../../store/cms/darts/shootOutGames/types";
import {
    createDartsShootOutGame,
    editDartsShootOutGame, setShootOutGameEditMode
} from "../../../../../../store/cms/darts/shootOutGames/cmsShootOutGamesSlice";
import {useShootOutFormOptions} from "./hooks/useShootOutFormOptions";
import './styles.scss';

interface IDartsShootOutFormProps {
    setIsOpenForm: (flag: boolean) => void
}

const DartsShootOutForm: FunctionComponent<IDartsShootOutFormProps> = ({setIsOpenForm}) => {
    const dispatch = useAppDispatch();
    const [dateStart, setDateStart] = useState(new Date() as Date | string);
    const [timeValue, setTimeValue] = useState(moment());
    const {countries, tournaments, players} = useAppSelector((state) => state.cmsShootOutGames.initData);
    const {shootOutGames, editMode, editGameId} = useAppSelector((state) => state.cmsShootOutGames);
    const {
        formStyle,
        dayPickerOptions,
        countryOptions,
        playersOptions,
        tournamentsOptions,

    } = useShootOutFormOptions({countries, players, tournaments});
    let gamesValidationSchema = yup.object().shape({
        tournamentId: yup.string().required(),
        player1id: yup.string().required(),
        player2id: yup.string().required(),
        player3id: yup.string().required(),
        location: yup.string().required(),
        startDate: yup.string().required(),
        startTime: yup.string().required(),
        isUniquePlayer: yup
            .string()
            .test("match", "Player names must be unique", function () {
                const p1 = this.parent.player1id
                const p2 = this.parent.player2id
                const p3 = this.parent.player3id
                return checkIsUniquePlayerNames(p1, p2, p3);
            }),
    });
    const {control, errors, handleSubmit, getValues, setValue} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(gamesValidationSchema),
    });

    useEffect(() => {
        setValue("location", "GB");
        setValue("startTime", moment());
        setValue("startDate", new Date());
    }, []);

    const checkIsUniquePlayerNames = (p1: string, p2: string, p3: string) => {
        return p1 !== p2 && p1 !== p3 && p2 !== p3
    }

    useEffect(() => {
        if (editMode && editGameId) {
            const editGame = shootOutGames.find((game) => game.id === editGameId);
            const defaultTournamentsOptions = tournamentsOptions.find(
                (tour) => tour.text === editGame!.tournamentName
            );
            const defaultCountryOptions = countryOptions.find(
                (country) => country.value === editGame!.location
            );
            const default1PlayerOptions = playersOptions.find(
                (player) => player.text === editGame!.player1Name
            );
            const default2PlayerOptions = playersOptions.find(
                (player) => player.text === editGame!.player2Name
            );
            const default3PlayerOptions = playersOptions.find(
                (player) => player.text === editGame!.player3Name
            );

            setValue("tournamentId", defaultTournamentsOptions?.value);
            setValue("location", defaultCountryOptions?.value);
            setValue("player1id", default1PlayerOptions?.value);
            setValue("player2id", default2PlayerOptions?.value);
            setValue("player3id", default3PlayerOptions?.value);
            setDateStart(editGame!.timeStart);
            setTimeValue(moment(editGame!.timeStart));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editGameId,editMode]);

    const onCreateGame = () => {
        const requestBody: IShootOutGamePayload = {
            tournamentId: getValues("tournamentId"),
            player1id: getValues("player1id"),
            player2id: getValues("player2id"),
            player3id: getValues("player3id"),
            status: 1,
            location: getValues("location"),
            timeStart: `${moment(dateStart).format("YYYY-MM-DD")}${timeValue.format("THH:mm:ss.SSS")}`,
        }
        dispatch(createDartsShootOutGame(requestBody))
        setIsOpenForm(false)

    }
    const onUpdateGame = () => {
        const requestBody: IShootOutGameEditPayload = {
            id: editGameId!,
            tournamentId: getValues("tournamentId"),
            player1id: getValues("player1id"),
            player2id: getValues("player2id"),
            player3id: getValues("player3id"),
            status: 1,
            location: getValues("location"),
            timeStart: `${moment(dateStart).format("YYYY-MM-DD")}${timeValue.format("THH:mm:ss.SSS")}`
        }
        dispatch(editDartsShootOutGame(requestBody))
        setIsOpenForm(false)
    }

    const updateFormHandler = () => {
        setIsOpenForm(false)
        dispatch(setShootOutGameEditMode(false))
    }

    return (
        <div className="form-overlay">
            <Form
                className={'shootOut-form-wrapper'}
                onSubmit={editMode ? handleSubmit(onUpdateGame) : handleSubmit(onCreateGame)}
                style={formStyle}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={updateFormHandler}
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label>Tournament:</Label>
                    <Controller
                        name="tournamentId"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("tournamentId")}
                                id="tournamentName"
                                options={tournamentsOptions}
                                placeholder="Select tournament"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.tournamentId && (
                        <Message negative>{errors.tournamentId.message}</Message>
                    )}
                </Form.Field>

                <Form.Field>
                    <Label>Player 1:</Label>
                    <Controller
                        name="player1id"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("player1id")}
                                id="player1id"
                                options={playersOptions}
                                placeholder="Select player 1 name"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.player1id && (
                        <Message negative>{errors.player1id.message}</Message>
                    )}
                </Form.Field>

                <Form.Field>
                    <Label>Player 2:</Label>
                    <Controller
                        name="player2id"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("player2id")}
                                id="player2id"
                                options={playersOptions}
                                placeholder="Select player 2 name"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.player2id && (
                        <Message negative>{errors.player2id.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Player 3:</Label>
                    <Controller
                        name="player3id"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("player3id")}
                                id="player3id"
                                options={playersOptions}
                                placeholder="Select player 3 name"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.player3id && (
                        <Message negative>{errors.player3id.message}</Message>
                    )}
                    {errors.isUniquePlayer && (
                        <Message negative>{errors.isUniquePlayer.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label>Location:</Label>
                    <Controller
                        name="location"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("location")}
                                options={countryOptions}
                                placeholder="Select country"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.location && (
                        <Message negative>{errors.location.message}</Message>
                    )}
                </Form.Field>
                <FormField>
                    <Label>Start date:</Label>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({onChange}) => (
                            <Flatpickr
                                value={dateStart}
                                options={dayPickerOptions}
                                onChange={(date: Date, dayStr: string) => {
                                    setDateStart(dayStr)
                                    onChange(dayStr)
                                }}
                            />
                        )}
                    />
                    {errors.startDate && (
                        <Message negative>{errors.startDate.message}</Message>
                    )}
                </FormField>
                <FormField>
                    <Label>Start Time:</Label>
                    <div>
                        <Controller
                            name="startTime"
                            control={control}
                            render={({onChange}) => (
                                <TimePicker
                                    className={"game-time-picker"}
                                    showSecond={false}
                                    value={timeValue}
                                    onChange={(newValue) => {
                                        setTimeValue(newValue)
                                        onChange(newValue)
                                    }}
                                />
                            )}
                        />
                    </div>
                    {errors.startTime && (
                        <Message negative>{"Start time is required field"}</Message>
                    )}
                </FormField>
                <div className={"btn-submit"}>
                    <Button id="save_game" color="green" type="submit">
                        Submit
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default DartsShootOutForm;
