import React from "react";
import {Button, Segment} from "semantic-ui-react";
import {GameStatus, GameStatuses} from "../../../../enums/gameStatus";
import "./styles.scss";
import {useAppSelector} from "../../../../hooks/hooks";
import {SportType} from "../../../../enums/gameEvents";

export interface IActionButtonProps {
    sportType: SportType;
    onClickActionButton: (
        eventStatus: GameStatus,
        newStatus: GameStatuses,
        message: string
    ) => void;
    setIsOpenEndGameModal: (flag: boolean) => void;
}

const ShootOutActionButtons: React.FC<IActionButtonProps> = ({
         sportType,
         onClickActionButton,
         setIsOpenEndGameModal,
     }) => {

    const {currentGameStatus} = useAppSelector((state) => state.currentDartsGame);

    const handleEndGame = (gameStatus: number) => {
        if (gameStatus !== GameStatuses.PreEnd) {
            setIsOpenEndGameModal(true);
            return;
        } else {
            onClickActionButton(GameStatus.Closed, GameStatuses.End, "end");
        }
    };

    return (
        <Segment>
            <div className="action-buttons__container">
                <div className="action-buttons__container">
                    <div className="action-buttons__row">
                        <Button
                            onClick={() =>
                                onClickActionButton(GameStatus.Open, GameStatuses.Delayed, "delay")
                            }
                            color="blue"
                        >
                            Delay
                        </Button>
                        <Button
                            onClick={() =>
                                onClickActionButton(
                                    GameStatus.Pending,
                                    GameStatuses.Suspended,
                                    "suspend"
                                )
                            }
                            color="violet"
                        >
                            Suspend
                        </Button>
                        <Button
                            onClick={() =>
                                onClickActionButton(GameStatus.Void, GameStatuses.Postponed, "postpone")
                            }
                            color="violet"
                        >
                            Postpone
                        </Button>
                    </div>
                    <div className="action-buttons__row">
                        <Button
                            onClick={() =>
                                onClickActionButton(GameStatus.Void, GameStatuses.Abandoned, "abandon")
                            }
                            color="orange"
                        >
                            Abandon
                        </Button>
                        <Button
                            className="end-game-btn"
                            onClick={() => handleEndGame(currentGameStatus as number)}
                        >
                            End Game
                        </Button>
                        <Button
                            onClick={() =>
                                onClickActionButton(GameStatus.Void, GameStatuses.Cancelled, "cancel")
                            }
                            color="red"
                        >
                            Cancel
                        </Button>
                    </div>

                    {sportType === SportType.TableTennis && (
                        <div className="action-buttons__row">
                            <Button color="green">Expedite Mode</Button>
                        </div>
                    )}
                </div>
                {sportType === SportType.TableTennis && (
                    <div className="action-buttons__row">
                        <Button color="green">Expedite Mode</Button>
                    </div>
                )}
            </div>
        </Segment>
    );
};

export default ShootOutActionButtons;
