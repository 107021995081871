import {LiveScoreTimelineItem} from "../../../../../store/pages/liveScoreInfo/types";

export const getLogContentHomePlayer = (log: LiveScoreTimelineItem) => {
    if (log.homeAway) {
        return log.description;
    }
};

export const getLogContentAwayPlayer = (log: LiveScoreTimelineItem) => {
    if (!log.homeAway) {
        return log.description;
    }
};
