import React from 'react'
import {animated} from "react-spring";
import './style.scss'
import './style-media.scss'

interface IPlayersAverageProps {
    scoreType: string
    counter: number
    movements: any
    changeLabelMove: number
    isPercent?: boolean
    playersScore: { home: any, away: any }
}

const PlayersAverage: React.FC<IPlayersAverageProps> = (
    {
        scoreType,
        counter,
        isPercent,
        movements,
        changeLabelMove,
        playersScore
    }) => {

    return (
        <>
            <div className="players-average">
                <animated.div className="home-average-value-inner" style={movements.showHomePlayerScore}>
                    <h1 className="average-score__value">
                        {playersScore.home}
                        {isPercent && <span className="average-score__percent"> %</span>}
                    </h1>
                </animated.div>
                <div>
                    <animated.div className="overall-label" style={movements.showScoreLabel}>
                        <div className="average-label-inner">
                            <p className="average-score__label">OVERALL</p>
                        </div>
                        <div className="average-type-inner">
                            <p className="average-score__type">{scoreType}</p>
                        </div>
                        <animated.div
                            className="overall-label-decor"
                            style={counter < changeLabelMove ? movements.moveLabelLineStart : movements.moveLabelLineEnd}
                        />
                    </animated.div>
                </div>
                <animated.div className="away-average-value-inner" style={movements.showAwayPlayerScore}>
                    <h1
                        className="average-score__value">{playersScore.away}
                        {isPercent && <span className="average-score__percent"> %</span>}
                    </h1>
                </animated.div>
            </div>
        </>
    )
}


export default PlayersAverage;
PlayersAverage.displayName = "PlayersAverage";
