import {IDartsPhase} from "../../../../../../store/cms/darts/phases/types";

interface ICheckIsExistsWeek {
    dartsPhase: IDartsPhase[]
    weekNumber: number;
    year: number;
}

export const checkIsExistWeek = ({dartsPhase, weekNumber, year}:ICheckIsExistsWeek) => {
    let isExistWeek = false
    dartsPhase.forEach(phase => {
        phase.weeks.find(week => {
            if (week.year === year && week.number === weekNumber) {
                isExistWeek = true
            }
        })

    })
    return isExistWeek;
};
