import {AxiosResponse} from "axios";
import {client} from "../../../../services/api.service";
import {DartsGameItem} from "../../../../store/dartsGame/handler/types";
import {setGame} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {AppDispatch} from "../../../../store";
import {API_DARTS_GAMES} from "../../../../constants";

export const createActiveGameAfterRefreshPage = async (gameId: number, dispatch: AppDispatch) => {
    try {
        const resp: AxiosResponse = await client.get(`${API_DARTS_GAMES}/${gameId}`);
        const activeGame: DartsGameItem = resp.data;
        dispatch(setGame(activeGame))
    } catch (e) {
        if (e instanceof Error) {
            console.log(e.message)
        }
    }
};
