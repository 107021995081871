import {AdcTypes, PlayerAdcType} from "../../../../../enums/playerAdcTypes";

export const setAdcQualifier = (qualifierId: number) => {
    switch (qualifierId) {
        case PlayerAdcType.UK:
            return AdcTypes.UK;
        case PlayerAdcType.Europe:
            return AdcTypes.Europe;
        case PlayerAdcType.Oceania:
            return AdcTypes.Oceania
        default:
            return ''
    }
};
