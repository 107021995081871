import {ILiveScoreLocations} from "../types";

export const formatSideBar = (sideBar: ILiveScoreLocations[]) => {
    let arrayEvents: any[] = [];
    if (sideBar) {
        for (const sideBarField of sideBar) {
            let location = sideBarField.location;
            let tournaments = sideBarField.tournaments;
            tournaments.map((tour) => {
                let tournament = tour;
                let games = tournament.games.map((game: { id: any; status: any; timeStart: any; score: any; players: any; }) => {
                    return {
                        location,
                        tournamentName: tournament.name,
                        id: game.id,
                        status: game.status,
                        timeStart: game.timeStart,
                        score: game.score,
                        players: game.players,
                    };
                });
                arrayEvents.push(...games);
            });
        }
        return arrayEvents;
    }
};
