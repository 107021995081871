import React, {FC} from 'react';
import {useAppSelector} from "../../../../hooks/hooks";


const Average180Table: FC = () => {
    const {scoreBoardAndStatsInfo:{averages}} = useAppSelector(
        (state) => state.scoreboardAndStats
    );
    return (
        <div className="score-table score-table-average-180">
            <div className="scores-header">
                <div className="scores-header-item">
                    Average
                </div>
                <div className="scores-header-item item-180s">
                    180<span>s</span>
                </div>
            </div>
            <div className="table-body">
                <div className="table-body-inner">
                    <div
                        className="table-body-inner-item table-body-inner-item-average-180">
                        {averages.average[0]}
                    </div>
                    <div className="table-body-inner-item table-body-inner-item-line table-body-inner-item-line-average-180">
                        {averages.turn180[0]}
                    </div>
                </div>
                <div className="table-body-inner">
                    <div
                        className="table-body-inner-item table-body-inner-item-average-180">
                        {averages.average[1]}
                    </div>
                    <div
                        className="table-body-inner-item table-body-inner-item-line table-body-inner-item-line-average-180">
                        {averages.turn180[1]}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Average180Table;
