import moment from "moment";
import React, {useEffect, useState} from "react";
import {Button, Icon, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DartsGamesForm from "./DartsGamesForm";
import DartsGamesFilter from "./components/dartsGamesFilter/DartsGamesFilter";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    changePageSize,
    deleteDartsGame,
    getDartsGames,
    getDartsGamesInit, setActivePage, setEditMode,
    showGamesForm
} from "../../../../store/cms/darts/games/cmsGamesSlice";
import {DartsGamesData} from "../../../../store/cms/darts/games/types";
import {buildQueryString} from "./helpers/buildQueryString";
import CheckboxCms from "../../../../components/CheckboxCms/CheckboxCms";
import './style.scss'
import {GameStatuses} from "../../../../enums/gameStatus";
import CDCTournament from "../../../../components/CDCTournament/CDCTournament";
import AppPagination from "../../../../components/Pagination/AppPagination";

const DartsGamesTable = () => {
    const [isShowFilter, setIsShowFilter] = useState(false);
    const dispatch = useAppDispatch();
    const {user, isRenderControls} = useAppSelector((state) => state.auth);
    const [showCDCTour, setShowCDCTour] = useState(false);
    const {
        dartsGames,
        isVisibleForm,
        totalPages,
        pageNum,
        pageSize,
        noContent,
        filterValues
    } = useAppSelector((state) => state.cmsGames);

    useEffect(() => {
        dispatch(setActivePage(1))
        const params = {...filterValues, pageNum: 1, pageSize}
        dispatch(getDartsGames(buildQueryString(params)));
    }, [filterValues]);


    useEffect(() => {
        dispatch(getDartsGamesInit());
    }, []);

    const onOpenFilter = () => {
        setIsShowFilter(true);
    };

    const generateButtons = (game: DartsGamesData) => {
        if (game.status === GameStatuses.NotActive && isRenderControls) {
            return (
                <div className={"table-controls-btn"}>
                    <DartsDeleteModal
                        id={game.id}
                        deleteAction={(id: number) => dispatch(deleteDartsGame(id))}
                        confirmText={"Are you sure you want to delete your handler?"}
                        titleModal={"Delete your Game"}
                    />
                    <Icon
                        name='pencil alternate'
                        link
                        onClick={() => dispatch(setEditMode(game.id))}
                        size="small"
                        color="grey"
                    >
                    </Icon>
                </div>
            );
        }
    };

    const showForm = () => {
        dispatch(showGamesForm())
    }
    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setActivePage(activePage))
        const params = {...filterValues, pageNum: activePage, pageSize}
        dispatch(getDartsGames(buildQueryString(params)));
    }

    const onChangePageSize = (value: any) => {
        const params = {...filterValues, pageNum, pageSize: value}
        dispatch(changePageSize(value))
        dispatch(getDartsGames(buildQueryString(params)));
    }

    return (
        <div>
            {showCDCTour && <CDCTournament/>}
            <div className="cms-action-btns-inner">
                <div>
                    <Button color="yellow" onClick={onOpenFilter}>
                        Filter
                    </Button>
                    {isRenderControls && <Button color="green" id="add_game" onClick={showForm}>Add</Button>}
                    {/*<Button*/}
                    {/*    color="green" id="add_game"*/}
                    {/*    onClick={()=>setShowCDCTour(true)}>*/}
                    {/*    Create Tour*/}
                    {/*</Button>*/}
                </div>
            </div>
            {noContent ? (
                <div className="schedule-info-block">No games at the moment</div>
            ) : (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {isRenderControls && <Table.HeaderCell textAlign="center">
                                Id
                            </Table.HeaderCell>}
                            <Table.HeaderCell textAlign="center">
                                Phase
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Week
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Group
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Home player
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Away player
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Date start
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Status
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Start score
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Double start
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Leg To Win
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {dartsGames?.map((game) => {
                            return (
                                <Table.Row key={game.id.toString()}>
                                    {isRenderControls &&
                                        <Table.Cell textAlign="center">
                                            {game.id}
                                        </Table.Cell>
                                    }

                                    <Table.Cell textAlign="center">
                                        {game.phaseName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.weekName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.groupName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{game.location}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.playerHomeName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.playerAwayName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {moment(game.timeStart)
                                            .local()
                                            .format("YYYY-MM-DD HH:mm")}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{
                                        game.statusName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.startScore}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        <CheckboxCms isReadyOnly={true} value={game.doubleStart}/>
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.legsToWin}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {generateButtons(game)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            {isVisibleForm && <DartsGamesForm/>}
            {isShowFilter && (
                <DartsGamesFilter
                    closeFilter={setIsShowFilter}
                />
            )}
            {!noContent && (
                <AppPagination
                    activePage={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onChangePager={onChangePager}
                    onChangePageSize={onChangePageSize}
                />
            )}
        </div>
    );
};

export default DartsGamesTable;
