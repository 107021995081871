import React from 'react';
import styles from "../index.module.scss";
import {Checkbox} from "semantic-ui-react";

interface PermissionProps {
    name: string
    id: string | number
    selectPermission: (id: string) => void
    selectedPermissions: string[]
}

const Permission: React.FC<PermissionProps> = (
    {
        name,
        id,
        selectPermission,
        selectedPermissions
    }) => {
    const isChecked = (permission: string | number) => !!selectedPermissions.find(item => item === permission)
    return (
        <div
            key={id}
            className={styles.permission}
        >
            <div className={styles.permissionName}>{name}</div>
            <div>
                <Checkbox
                    checked={isChecked(id)}
                    onClick={() => selectPermission(id.toString())}
                />
            </div>
        </div>
    );
};

export default Permission;
