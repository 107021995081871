import React from 'react';
import styles from "./index.module.scss"
import {useAppSelector} from "../../../../hooks/hooks";
import {formatTotalScore} from "./helpers/formatTotalScores";

const LegByLegTable = () => {
    const {
        scoreBoardAndStatsInfo: {
            legByLeg: {
                maxLeg,
                totalDarts
            }
        }
    } = useAppSelector((state) => state.scoreboardAndStats);

    return (
        <div className={styles.table}>
            <div className={styles.header}>
                {Array(maxLeg).fill(0).map((leg, index) => {
                    return (
                        <div key={index} className={styles.headerColumn}>{index + 1}</div>
                    )
                })}
            </div>
            <div className={styles.valuesRow}>
                {formatTotalScore(totalDarts[0], maxLeg)?.map((score: React.ReactNode,index) => {
                    return (
                        <div
                            key={index}
                            style={totalDarts[0].length > 12 ? {fontSize: 1.2 + "rem"} : {fontSize: 2 + "rem"}}
                            className={styles.valueCell}>{score !== null ? score : " - "}
                        </div>
                    )
                })}
            </div>
            <div className={styles.valuesRow}>
                {formatTotalScore(totalDarts[1], maxLeg)?.map((score: React.ReactNode,index) => {
                    return (
                        <div
                            key={index}
                            style={totalDarts[1].length > 12 ? {fontSize: 1.2 + "rem"} : {fontSize: 2 + "rem"}}
                            className={styles.valueCell}>{score !== null ? score : " - "}
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default LegByLegTable;
