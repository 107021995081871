import React, {Fragment, FunctionComponent, useEffect, useState} from 'react'
import {Button, Icon, Input, Table} from "semantic-ui-react";
import DartsShootOutForm from "../DartsGames/components/DartsShootOutForm/DartsShootOutForm";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import './style.scss'
import moment from "moment";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    changePageSize,
    deleteShootOutGame,
    getDartsShootOutGames,
    getShootOutDartsInit, setEditGameId, setPageNumber
} from "../../../../store/cms/darts/shootOutGames/cmsShootOutGamesSlice";
import {buildQueryString} from "../DartsGames/helpers/buildQueryString";
import {useDebounce} from "../../../../hooks/useDebounce";
import {GameStatuses} from "../../../../enums/gameStatus";
import AppPagination from "../../../../components/Pagination/AppPagination";

const DartsShootOutGames: FunctionComponent = () => {
    const [isOpenForm, setIsOpenForm] = useState<boolean>(false)
    const dispatch = useAppDispatch()
    const {
        shootOutGames,
        initData: {tournaments},
        totalPages,
        pageNum,
        pageSize
    } = useAppSelector((state) => state.cmsShootOutGames)
    const {user, isRenderControls} = useAppSelector((state) => state.auth);
    const [searchWord, setSearchWord] = useState("");

    useEffect(() => {
        const params = {
            pageNum,
            pageSize
        }
        dispatch(getDartsShootOutGames(buildQueryString(params)))
        dispatch(getShootOutDartsInit());
    }, [])

    const onSetEditGameId = (gameId: number) => {
        dispatch(setEditGameId(gameId))
        setIsOpenForm(true)
        window.scrollTo(0, 0);
    }

    const onChangePager = (activePage: string | number | undefined) => {
        dispatch(setPageNumber(activePage))
        const params = {pageNum: activePage, pageSize}
        dispatch(getDartsShootOutGames(buildQueryString(params)));
    }

    const onSearch = () => {
        dispatch(getDartsShootOutGames(buildQueryString({pageNum, pageSize, search: searchWord})))
        dispatch(setPageNumber(1))
    }

    useDebounce(onSearch, searchWord, 500)

    const generateButtons = (game: any) => {
        if (game.status === GameStatuses.NotActive && isRenderControls) {
            return (
                <Fragment>
                    <DartsDeleteModal
                        id={game.id}
                        deleteAction={() => dispatch(deleteShootOutGame(game.id))}
                        confirmText={"Are you sure you want to delete your handler?"}
                        titleModal={"Delete your Game"}
                    />
                    <Icon
                        name='pencil alternate'
                        link
                        onClick={() => onSetEditGameId(game.id)}
                        size="small"
                        color="grey"
                    >
                    </Icon>
                </Fragment>
            );
        }
    };

    const onChangePageSize = (value: any) => {
        dispatch(getDartsShootOutGames(buildQueryString({pageNum, pageSize: value, search: searchWord})))
        dispatch(changePageSize(value))
    }

    return (
        <>
            <div className="cms-action-btns-inner">
                <div>
                    <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <div>
                            <Input
                                value={searchWord}
                                onChange={(e) => setSearchWord(e.target.value)}
                                placeholder="Search....."
                            />
                        </div>
                        {isRenderControls && <Button
                            loading={tournaments === undefined}
                            disabled={tournaments === undefined}
                            onClick={() => setIsOpenForm(true)}
                            color="green" id="add_game">
                            Add
                        </Button>}
                    </div>
                </div>
            </div>
            {(
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            {isRenderControls && <Table.HeaderCell textAlign="center">
                                Id
                            </Table.HeaderCell>
                            }
                            <Table.HeaderCell textAlign="center">Location</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Tournament</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Player 1
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Player 2
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Player 3
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">Start time</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Event Status
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center"/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {shootOutGames.map((game) => {
                            return (
                                <Table.Row key={game.id}>
                                    {isRenderControls &&
                                        <Table.Cell textAlign="center">
                                            {game.id}
                                        </Table.Cell>
                                    }
                                    <Table.Cell textAlign="center">{game.location}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.tournamentName}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.player1Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.player2Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {game.player3Name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {moment(game.timeStart).local().format("YYYY-MM-DD HH:mm:ss")}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{game.statusName}</Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {generateButtons(game)}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            )}
            <AppPagination
                activePage={pageNum}
                pageSize={pageSize}
                totalPages={totalPages}
                onChangePager={onChangePager}
                onChangePageSize={onChangePageSize}
            />
            {isOpenForm && <DartsShootOutForm setIsOpenForm={setIsOpenForm}/>}
        </>);
};

export default DartsShootOutGames;
