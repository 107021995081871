import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    Averages,
    BestOfXLegs, Checkouts,
    IGetScoreBoardStatsPayload, IScoreBoardAndStatsResp,
    IScoreBoardAndStatsState,
    LegByLeg, Rounds
} from "./types";
import {API_DARTS_SCOREBOARD_STATS,} from "../../../constants";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";

const initialState: IScoreBoardAndStatsState = {
    scoreBoardAndStatsInfo: {
        doubleMissed: {
            currentLeg:0,
            scores:[0,0]
        },
        turns180:{
            series180:0,
            week180:0,
        },
        playersInfo: {
            playersInfo: []
        },
        bestOfXLegs: {
            players: [],
            legs: [],
            score: [],
            numThrows: [],
            title: "",
            firstPlayerId: null,
            nextPlayerId: 0,
            is180PlayerId: null,
            legsToWin: 0
        },
        ligaTable: {
            name: "",
            tournamentId: 0,
            table: {
                items: []
            }
        },
        legByLeg: {
            maxLeg: 0,
            totalDarts: []
        },
        averages: {
            average: [],
            turn180: []
        },
        rounds: {
            titles: [],
            data: [
                [],
                []
            ]
        },
        checkouts: {
            checkouts: [],
            highestCheckout: []
        }
    },
    statsLoader: true,
    currentGameId: 0,
}

export const getScoreboardStatsInit = createAsyncThunk<IScoreBoardAndStatsResp, IGetScoreBoardStatsPayload, { rejectValue: string }>(
    'scoreboardAndStats/schedule',
    async (gameParams, {rejectWithValue}) => {
        const {gameId, gameType} = gameParams;
        try {
            const {data} = await client.get(`${API_DARTS_SCOREBOARD_STATS}/${gameType}/${gameId}`);
            return data;
        } catch (e) {
            if (e as AxiosError) {
                toastr.error('Scoreboard and stats:', `${e.response.data}`);
                console.log(e?.message)
                return rejectWithValue(e.response.data);
            }
        }
    }
)

export const scoreboardAndStatsSlice = createSlice({
    name: 'scoreboardAndStats',
    reducers: {
        setCurrentGameId: (state, action: PayloadAction<number>) => {
            state.currentGameId = action.payload;
        },

        updateBestOf7LegsBySocket: (state, action: PayloadAction<BestOfXLegs>) => {
            state.scoreBoardAndStatsInfo.bestOfXLegs = action.payload;
        },
        updateAveragesBySocket: (state, action: PayloadAction<Averages>) => {
            state.scoreBoardAndStatsInfo.averages = action.payload;
        },
        updateCheckoutBySocket: (state, action: PayloadAction<Checkouts>) => {
            state.scoreBoardAndStatsInfo.checkouts = action.payload;
        },
        updateLegByLegBySocket: (state, action: PayloadAction<LegByLeg>) => {
            state.scoreBoardAndStatsInfo.legByLeg = action.payload;
        },
        updateRoundsBySocket: (state, action: PayloadAction<Rounds>) => {
            state.scoreBoardAndStatsInfo.rounds = action.payload;
        },
        updatePlayersInfoBySocket: (state, action) => {
            state.scoreBoardAndStatsInfo.playersInfo = action.payload
        },
        setDoubleMissed: (state, action) => {
            state.scoreBoardAndStatsInfo.doubleMissed = action.payload
        },
        setTurn180BySocket:(state, action) => {
            state.scoreBoardAndStatsInfo.turns180 = action.payload
        }
    },

    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getScoreboardStatsInit.pending, (state: IScoreBoardAndStatsState) => {
                state.statsLoader = true;
            })
            .addCase(getScoreboardStatsInit.fulfilled, (state: IScoreBoardAndStatsState, action) => {
                state.scoreBoardAndStatsInfo = action.payload
                state.statsLoader = false;
            })
            .addCase(getScoreboardStatsInit.rejected, (state: IScoreBoardAndStatsState) => {
                state.statsLoader = false;
            })
    }
})

export const {
    setCurrentGameId,
    updateAveragesBySocket,
    updateCheckoutBySocket,
    updateBestOf7LegsBySocket,
    updateLegByLegBySocket,
    updateRoundsBySocket,
    updatePlayersInfoBySocket,
    setDoubleMissed,
    setTurn180BySocket
} = scoreboardAndStatsSlice.actions;
export default scoreboardAndStatsSlice.reducer;
