export const dartsGameStatuses = [
    {key: 1, value: "NotActive", text: "NotActive"},
    {key: 2, value: "Running", text: "Running"},
    {key: 3, value: "Delayed", text: "Delayed"},
    {key: 4, value: "Suspended", text: "Suspended"},
    {key: 5, value: "Postponed", text: "Postponed"},
    {key: 6, value: 'Walkover', text: "Walkover"},
    {key: 7, value: 'Abandoned', text: "Abandoned"},
    {key: 8, value: 'Cancelled', text: "Cancelled"},
    {key: 9, value: 'PreEnd', text: "PreEnd"},
    {key: 10, value: "End", text: "End"},
]

export const dartsGameStatusesOptions = [
    {key: 101, value: 0, text: "Clear"},
    {key: 1, value: 1, text: "NotActive"},
    {key: 2, value: 2, text: "Running"},
    {key: 3, value: 3, text: "Delayed"},
    {key: 4, value: 4, text: "Suspended"},
    {key: 5, value: 5, text: "Postponed"},
    {key: 6, value: 6, text: "Walkover"},
    {key: 7, value: 7, text: "Abandoned"},
    {key: 8, value: 8, text: "Cancelled"},
    {key: 9, value: 9, text: "PreEnd"},
    {key: 10, value: 10, text: "End"},
]
