import React, {useEffect, useState} from "react";
import {Button, Form, FormField, Icon, Input, Label, Message, Select,} from "semantic-ui-react";
import {Controller, useForm} from "react-hook-form";
import Flatpickr from "react-flatpickr";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment, {Moment} from "moment";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {createDartsAdcGame, editDartsAdcGame} from "../../../../store/cms/darts/adcGames/cmsAdcGamesSlice";
import {DartsAdcCreateGamePayload} from "../../../../store/cms/darts/adcGames/types";
import {useGetOptions} from "./hooks/useSelectOptions";
import {useGetCompDefaultOptions} from "./hooks/useGetCompDefaultOptions";
import {ConfirmationModal} from "../../../../components/ConfirmationModal";
import {isChosenDateInPresent} from "./helpers/isChoosenDateInPast";

interface IDartsGamesFormProps {
    closeHandler: () => void
}

const DartsAdcGamesForm: React.FC<IDartsGamesFormProps> = ({closeHandler}) => {
    const dispatch = useAppDispatch();
    const [dateStart, setDateStart] = useState<Date | string>(new Date());
    const [timeValue, setTimeValue] = useState<Moment>(moment());
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showDateValidation, setShowDateValidation] = useState<boolean>(false);
    const [gameDataPayload, setGameDataPayload] = useState<DartsAdcCreateGamePayload | null>(null)
    const {countries, competitions, players} = useAppSelector(
        (state) => state.cmsAdcGames.initData
    );
    const {selectedGame} = useAppSelector((state) => state.cmsAdcGames);
    const competitionDefaultOptions = useGetCompDefaultOptions({selectedGame, competitions});
    const adcGamesValidationSchema = yup.object().shape({
        competition: yup.string().required(),
        playerHomeName: yup.string().required(),
        playerAwayName: yup.string().required(),
        location: yup.string().required(),
        legsToWin: yup.number().required(),
        isValid: yup
            .string()
            .test("match", "You cannot select the same players", function () {
                return this.parent.playerHomeName !== this.parent.playerAwayName || !this.parent.playerHomeName?.trim() || !this.parent.playerAwayName?.trim()
            })
    });
    const {
        countriesOptions,
        competitionOptions,
        playersOptions
    } = useGetOptions({countries, competitions, players});

    const {control, errors, handleSubmit, setValue, getValues} = useForm({
        mode: "onChange",
        resolver: yupResolver(adcGamesValidationSchema),
    });

    useEffect(() => {
        setValue("location", "GB");
        setValue("legsToWin", 4);
    }, []);

    useEffect(() => {
        if (selectedGame) {
            const defaultCountryOptions = countriesOptions?.find((country) => country.value === selectedGame?.location);
            const defaultHomePlayerOptions = playersOptions?.find((player) => player.text === selectedGame?.playerHomeName);
            const defaultAwayPlayerOptions = playersOptions?.find((player) => player.text === selectedGame?.playerAwayName);

            setValue("location", defaultCountryOptions?.value);
            setValue("competition", competitionDefaultOptions?.value);
            setValue("playerHomeName", defaultHomePlayerOptions?.value);
            setValue("playerAwayName", defaultAwayPlayerOptions?.value);
            setValue("legsToWin", selectedGame?.legsToWin);
            setDateStart(selectedGame?.timeStart!);
            setTimeValue(moment(selectedGame?.timeStart));
        }

    }, [selectedGame]);
    const dateValidation = () => {
        setShowDateValidation(true);
        setTimeout(() => {
            setShowDateValidation(false);

        }, 1000)
    }

    const createPayload = (): DartsAdcCreateGamePayload => {
        return {
            competitionId: +getValues("competition"),
            legsToWin: +getValues("legsToWin"),
            playerHomeId: getValues("playerHomeName"),
            playerAwayId: getValues("playerAwayName"),
            location: getValues("location"),
            timeStart: moment(`${dateStart}`).format()
        };
    }
    const submitForm = () => {
        const payload = createPayload()
        if (!dateStart) return dateValidation();
        if (!isChosenDateInPresent(dateStart)) {
            setGameDataPayload(payload);
            return setShowConfirmModal(true);
        }
        dartsGameHandler(payload);
    };

    const dartsGameHandler = (payload: DartsAdcCreateGamePayload) => {
        selectedGame?.id ?
            dispatch(editDartsAdcGame(
                {...payload, status: 1, id: selectedGame.id}
            ))
            :
            dispatch(createDartsAdcGame(payload));
        return closeHandler();
    }

    const changeTimeStart = (value: Moment) => {
        setTimeValue(value);
    };

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };

    const onCloseForm = () => {
        closeHandler();
    }

    const onConfirmModalHandler = () => {
        dartsGameHandler(gameDataPayload as DartsAdcCreateGamePayload);
        setShowConfirmModal(false);
        setGameDataPayload(null);
    }
    return (
        <div className="form-overlay">
            <Form
                onSubmit={handleSubmit(submitForm)}
                className={"darts-game-form"}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={onCloseForm}
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label className={'darts-game-adc-label'}>Competition:</Label>
                    <Controller
                        name="competition"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("competition")}
                                id="competition"
                                options={competitionOptions}
                                placeholder="Select competition"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.competition && (
                        <Message negative>{errors.competition.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label className={'darts-game-adc-label'}>Home player name</Label>
                    <Controller
                        name="playerHomeName"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("playerHomeName")}
                                id="playerHomeName"
                                options={playersOptions}
                                placeholder="Select home player name"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.playerHomeName && (
                        <Message negative>{errors.playerHomeName.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label className={'darts-game-adc-label'}>Away Player name</Label>
                    <Controller
                        name="playerAwayName"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={(props) => (
                            <Select
                                value={getValues("playerAwayName")}
                                id="playerAwayName"
                                options={playersOptions}
                                placeholder="Select away player name"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.playerAwayName && (
                        <Message negative>{errors.playerAwayName.message}</Message>
                    )}
                    {errors.isValid && (
                        <Message negative>{errors.isValid.message}</Message>
                    )}
                </Form.Field>
                <Form.Field>
                    <Label className={'darts-game-adc-label'}>Location</Label>
                    <Controller
                        name="location"
                        control={control}
                        render={(props) => (
                            <Select
                                value={getValues("location")}
                                options={countriesOptions}
                                placeholder="Select country"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                    {errors.location && (
                        <Message negative>{errors.location.message}</Message>
                    )}
                </Form.Field>
                <FormField>
                    <Label className={'darts-game-adc-label'}>Date</Label>
                    <Flatpickr
                        defaultValue={dateStart}
                        value={dateStart}
                        options={dayPickerOptions}
                        onChange={(date: Date, dayStr: string) => setDateStart(dayStr)}
                    />
                    {showDateValidation &&
                        <Message negative>{'Date field is required.'}</Message>
                    }
                </FormField>
                <FormField>
                    <Label className={'darts-game-adc-label'}>Start Time</Label>
                    <div>
                        <TimePicker
                            showSecond={false}
                            value={timeValue}
                            onChange={changeTimeStart}
                        />
                    </div>
                </FormField>
                <Form.Field>
                    <Label className={'darts-game-adc-label'}>Legs to win (min: 2 max: 9)</Label>
                    <Controller
                        name="legsToWin"
                        control={control}
                        render={(props) => (
                            <Input
                                min={2}
                                max={9}
                                name='legsToWin'
                                placeholder="Select legs to win (number)"
                                value={props.value}
                                type='number'
                                id="legsToWin"
                                onChange={(e, {value}) => props.onChange(value)}/>
                        )}
                    />
                    {errors.legsToWin && (
                        <Message negative>{errors.legsToWin.message}</Message>
                    )}
                </Form.Field>
                <Button id="save_game" color="green" type="submit">
                    Submit
                </Button>
            </Form>
            {
                showConfirmModal &&
                <ConfirmationModal
                    open={showConfirmModal} content={'Creating the game with a past date.'}
                    onConfirm={() => onConfirmModalHandler()}
                    onDecline={() => setShowConfirmModal(false)}
                />
            }
        </div>
    );
};

export default DartsAdcGamesForm;
