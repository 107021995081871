import React, {useState} from 'react';
import logo from "../../img/igm_logo.svg";
import {useAppDispatch} from "../../hooks/hooks";
import {Link, useHistory} from "react-router-dom";
import {setChangePassword} from "../../store/login/authSlice";
import styles from "./index.module.scss"
import {client} from "../../services/api.service";
import {errorLogger} from "../../services/error-logger";
import {toastr} from "react-redux-toastr";


const PasswordIssues = () => {
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [code, setCode] = useState("");
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState("");

    const setNewPassword = () => {
        if (password !== confirmPassword) {
            return setErrors("Passwords mismatch")
        }
        const payload = {
            email,
            password,
            codeFromEmail: code,
            history,
        }
        dispatch(setChangePassword(payload))
        setErrors("")
    }
    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const getConfirmCode = async () => {
        if (!validateEmail(email)) {
            return toastr.error("Auth", "Email should be like example@domain.com")
        }
        try {
            const {data} = await client.post("api/auth/code", {email})
            return data
        } catch (error) {
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Auth Code: ${error.name}, ${error.message}`,
                errorDate: new Date()
            }
            return errorLogger(errorLog);

        }

    }
    return (
        <div className="login-container">
            <div className="login-form">
                <div className="left">
                    <div className="inputs">
                        <input
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='E-mail'
                            className="input login"
                        />
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='New password'
                            className="input login"
                        />
                        <input
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder='Confirm password'
                            className="input login"
                        />
                        <input
                            onChange={(e) => setCode(e.target.value)}
                            placeholder='Code From Email'
                            className="input login"
                        />
                        <div className={styles.codeBtn}>
                            <button onClick={(e)=> {
                                e.preventDefault()
                                getConfirmCode()
                            }}>
                                Request confirmation code
                            </button>
                        </div>
                    </div>
                    <button
                        onClick={() => setNewPassword()}
                        type='submit'
                        className="login-btn">
                        Confirm
                    </button>
                    <div className={"password-link"}>
                        <Link className={styles.goToLoginBtn} to={"/login"}>Go to login</Link>
                    </div>
                </div>
                <div className="right">
                    <div className="logo">
                        <img className="logo-img" src={logo} alt="logo"/>
                    </div>
                    <span className="logo-label">Data Collection</span>
                    {
                        errors ? <span className="error">{errors}</span> : <></>
                    }
                </div>
            </div>
        </div>
    );
};

export default PasswordIssues;
