import {client} from "../../../../services/api.service";

import {deleteActiveGameAndTournament} from "../../../pages/schedule/scheduleSlice";
import {IMatchStatusPayload} from "../types";
import {AppThunkDispatch} from "../../../../store";
import {setCurrentGameStatus} from "../dartsCurrentGameSlice";
import {disconnectFromGameSocket} from "../../../../services/connection.service";
import {DartsGameTypes, GameTypes} from "../../../../enums/gameEvents";
import {API_GAME_STATUS} from "./setMatchStatusForCertainGame";

export const setEndGame = async (payload: IMatchStatusPayload, dispatch: AppThunkDispatch): Promise<void> => {
    const {gameData, gameType, history,status} = payload;
    const body = {
        gameId: gameData.gameId,
        status,
        date: gameData.date,
    }
    let response;

    switch (gameType) {
        case GameTypes.ADC:
            response = await client.put(API_GAME_STATUS, {...body,type:DartsGameTypes.ADC});
            dispatch(setCurrentGameStatus(response.data.status))
            dispatch(deleteActiveGameAndTournament())
            localStorage.setItem('isGameRunning', JSON.stringify(false))
            await disconnectFromGameSocket(gameData.gameId!)
            if (history) {
                history.go(-1)
            }
            return;

        case GameTypes.SHOOTOUT:
            response = await client.put(API_GAME_STATUS, {...body,type:DartsGameTypes.SHOOTOUT});
            dispatch(setCurrentGameStatus(response.data.status));
            dispatch(deleteActiveGameAndTournament());
            localStorage.setItem('isGameRunning', JSON.stringify(false))
            await disconnectFromGameSocket(gameData?.gameId!);
            if (history) {
                history.go(-1)
            }
            return;

        default:
            response = await client.put(API_GAME_STATUS, {...body,type:DartsGameTypes.CLASSIC});
            dispatch(setCurrentGameStatus(response.data.status));
            dispatch(deleteActiveGameAndTournament());
            localStorage.setItem('isGameRunning', JSON.stringify(false));
            await disconnectFromGameSocket(gameData?.gameId!);
            if (history) {
                history.go(-1)
            }
            return;
    }
}
