import axios, { AxiosError } from 'axios';
import { history } from '../history';
import {LS_TOKEN, LS_ACTIVE_PLAYER, LS_CURRENT_USER} from "../constants/localStorageItems";

const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity'
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT
})
client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(LS_TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

client.interceptors.response.use((response) => response,
    async (error: AxiosError) => {
        const originalRequest = error.config;
        if (error.response?.data === "User was not found."){
            return Promise.reject(error);
        }
        if (error.response?.data === "Invalid password"){
            return Promise.reject(error);
        }
        if (error.response?.status === 400) {
            return Promise.reject(error);
        }
        if (error.response?.status !== 401 ) {
            return Promise.reject(error);
        }
        const accessToken = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        if (accessToken) return client(originalRequest);
});

const refreshToken = async () => {
    const accessToken = localStorage.getItem(LS_TOKEN);

    if (!accessToken) {
        localStorage.removeItem(LS_CURRENT_USER);
        localStorage.removeItem(LS_ACTIVE_PLAYER);
        return history.push('/login');
    }

    try {
        const refreshResp = await client.post(`/api/Auth/refresh`, {token: accessToken})

        if (refreshResp.status === 200) {
            localStorage.setItem(LS_TOKEN, refreshResp.data.token);
        }
        if (refreshResp.status === 400) {
            localStorage.removeItem(LS_TOKEN);
            localStorage.removeItem(LS_CURRENT_USER);
            return history.push('/login');
        }

        return refreshResp.data.token;
    }
    catch(e) {
        if (e instanceof Error) {
            console.error(e.message);
        }
        localStorage.removeItem(LS_TOKEN);
        return history.push('/login');
    }
}

const handleError = async (error: AxiosError, defaultResult: any = {}) => {
    if(!error.response) {
			return;
    }
    return error.response.data;
}

export { client, handleError };
