import React, {useEffect} from "react";
import {Table} from "semantic-ui-react";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {getRoles} from "../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {IRole} from "../../../../store/cms/usersNRoles/types";
import '../Users/styles.scss';


const Roles = () => {
    const dispatch = useAppDispatch();
    const {roles, rolesLoader} = useAppSelector((state) => state.cmsUsersNRoles);

    useEffect(() => {
        dispatch(getRoles())
    }, []);

    return (
        <>
            {roles.length > 1 &&
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center">
                                Role name
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">
                                Role description
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {roles?.map((role: IRole) => {
                            return (
                                <Table.Row key={role.id}>
                                    <Table.Cell textAlign="center">
                                        {role.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {role.description}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            }
            {
                rolesLoader && <DimmerLoader/>
            }
        </>
    );
}

export default Roles;
