import React, {useEffect, useMemo, useState} from "react";
import {Form, Label, Select, Icon, Button} from "semantic-ui-react";
import Flatpickr from "react-flatpickr";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {IWeekOption} from "../../../../../../store/cms/darts/phases/types";
import {
    onClearFilterParams,
    setGameFilterValues
} from "../../../../../../store/cms/darts/games/cmsGamesSlice";
import {IDartsGamesFilter} from "../../../../../../store/cms/darts/games/types";
import {dartsGameStatuses} from "../../../../../../constants/gameStatuses";
import './index.scss'


interface IGameFilterPros {
    closeFilter: (isClose: boolean) => void
}

interface ISelectOption {
    key: number,
    value: number,
    text: number,
}

const DartsGamesFilter: React.FC<IGameFilterPros> = ({closeFilter}) => {
    const dispatch = useAppDispatch();
    const {players,phases,groups} = useAppSelector((state) => state.cmsGames.initGamesData);
    const [weekOptions, setWeekOptions] = useState<ISelectOption[]>([])
    const filterValues = useAppSelector((state) => state.cmsGames.filterValues)

    const clearFilter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        dispatch(onClearFilterParams())
    };

    useEffect(() => {
        if (filterValues.Phase && phases) {
            const currentPhase = phases.find((phase) => phase.id === filterValues.Phase);
            if (currentPhase) {
                const newWeeksOptions = currentPhase?.items?.map((week: IWeekOption) => ({
                    key: week.id,
                    value: week.id,
                    text: week.name,
                }));
                //@ts-ignore
                setWeekOptions(newWeeksOptions);
            }
        }
    }, [filterValues, phases]);

    const getOptionForSelectInp = (optionsData: any[]) => {
        const options = optionsData?.map(opt => ({
            key: opt.id,
            value: opt.id,
            text: opt.name,
        }))
        const emptyOption = {key: 101, value: 0, text: "Clear"}
        if (options) {
            options.unshift(emptyOption)
        }
        return options
    }
    const getStatusOptions = useMemo(() => {
        const options = dartsGameStatuses?.map(opt => ({
            key: opt.key,
            value: opt.key,
            text: opt.value,
        }))
        const emptyOption = {key: 101, value: 0, text: "Clear"}
        if (options) {
            options.unshift(emptyOption)
        }
        return options
    }, [players])

    const getPlayersOptions = useMemo(() => {
        const options = players?.map(opt => ({
            key: opt.id,
            value: opt.id,
            text: opt.name
        }))
        const emptyOption = {key: 101, value: 0, text: "Clear"}
        if (options) {
            options.unshift(emptyOption)
        }
        return options
    }, [players])

    const handleSelectChange = (name: string, value: any) => {
        const payload: IDartsGamesFilter = {
            field: name,
            value
        }
        dispatch(setGameFilterValues(payload))
    };

    const onSelectPhase = (name: string, value: any) => {
        handleSelectChange(name, value)
        handleSelectChange("Week",0)
    }

    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };
    return (
        <div className="filter-overlay">
            <Form className="cms-filter">
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={() => closeFilter(false)}
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label pointing="below">Phase</Label>
                    <Select
                        value={filterValues.Phase}
                        options={getOptionForSelectInp(phases)}
                        name="Phase"
                        placeholder="Select Phase"
                        onChange={(e, {name, value}) => onSelectPhase(name, value)}
                    />
                </Form.Field>
                {!!filterValues.Phase && <Form.Field>
                    <Label pointing="below">Week</Label>
                    <Select
                        value={filterValues.Week}
                        options={weekOptions}
                        name="Week"
                        placeholder="Select Week"
                        onChange={(e, {name, value}) => handleSelectChange(name, value)}
                    />
                </Form.Field>
                }
                <Form.Field>
                    <Label pointing="below">Group</Label>
                    <Select
                        value={filterValues.Group}
                        options={getOptionForSelectInp(groups)}
                        name="Group"
                        placeholder="Select Group"
                        onChange={(e, {name, value}) => handleSelectChange(name, value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Label pointing="below">Status</Label>
                    <Select
                        value={filterValues.Status}
                        name="Status"
                        options={getStatusOptions}
                        placeholder="Select status"
                        onChange={(e, {name, value}) => handleSelectChange(name, value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Label pointing="below">Player</Label>
                    <Select
                        value={filterValues.Player}
                        options={getPlayersOptions}
                        name="Player"
                        placeholder="Select Player"
                        onChange={(e, {name, value}) => handleSelectChange(name, value)}
                    />
                </Form.Field>
                <Form.Field className={"flat-picker-wrapper"}>
                    <button
                        onClick={()=>{
                            handleSelectChange("From", "")
                            handleSelectChange("From", null)
                        }}
                        className={"reset-btn"}>Reset date
                    </button>
                    <Label pointing="below">Date start</Label>
                    <Flatpickr
                        value={filterValues.From}
                        options={dayPickerOptions}
                        onChange={(date: Date, dayStr: string) => {
                            handleSelectChange("From", dayStr)
                        }}
                    />
                </Form.Field>
                <div className="cms-filter__btn-inner">
                    <Button onClick={(e) => clearFilter(e)} color="green">
                        Reset Filter
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default DartsGamesFilter;
