export const getTagId = (tag: string | number) => {
    switch (tag) {
        case 'Blog':
            return 1;
        case 'Announcement':
            return 2;
        case 'PressRelease':
            return 3
        default:
            return 1;
    }
}
export const getTag = (tag?: number) => {
    switch (tag) {
        case 1:
            return 'Blog';
        case 2:
            return 'Announcement';
        case 3:
            return 'PressRelease'
        default:
            return 'Blog';
    }
}
