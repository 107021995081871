import React from "react";
import './style.scss'
import './style-media.scss'
import highLight from '../../../img/SuperSeriesSweep.mp4';

const StartPage = React.forwardRef((counter: any, ref: any) => {
    return (
            <div className="start-page">
                <div className="start-page__logo-inner">
                    <div className="start-page__logo-wrapper">
                        <video
                            ref={ref}
                            width={'1300px'}
                            height={'800px'}
                            src={highLight}
                            muted={true}
                            autoPlay={true}
                        />
                    </div>
                    <div className="start-page-last-matches">
                        Form
                        <br/>
                        <span>Guide</span>
                        <div className="fadingEffect"></div>
                    </div>
                </div>
            </div>
    )
})

export default StartPage;
StartPage.displayName = "StartPage";

