
export const buildQueryString = (filter: any): string => {
    const queryParams: string[] = [];
    for (const key in filter) {
        if(key === "sort") {
            queryParams.push(`${key}=${filter[key]}`)
        }
        if (Object.prototype.hasOwnProperty.call(filter, key)
            && filter[key] !== null
            && filter[key] !== 0
            && filter[key] !== ""
            && filter[key] !== undefined
        ) {
            queryParams.push(`${key}=${filter[key]}`)
        }
    }

    return queryParams.join('&');
}