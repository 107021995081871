import React from "react";
import {Grid} from "semantic-ui-react";
import "./styles.scss";
import {IScoreBoardProps} from "./types";
import {useAppDispatch} from "../../../../hooks/hooks";
import {ISetFirstPlayer} from "../../../../store/dartsGame/currentGame/types";
import {setFirstPlayer} from "../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {Player} from "../Player/Player";
import {setSelectedColumnStyle} from "./helpers/setSelectedColumnStyle";
import {GameTypes} from "../../../../enums/gameEvents";
import {useHistory} from "react-router-dom";

const ScoreBoard: React.FC<IScoreBoardProps> = React.memo((
    {
        currentPlayerId,
        initData,
    }) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const pathName = history.location.pathname;
    const turnScores = initData.turnScores
    const setDartsPlayer = (payload: ISetFirstPlayer) => {
        if (!currentPlayerId) {
            const {gameData} = payload;
            dispatch(setFirstPlayer({
                gameType: pathName.includes("darts-adc") ? GameTypes.ADC : GameTypes.CLASSIC,
                gameData
            }));
        } else {
            return alert("Game already started and first player already changed");
        }
    };
    const basicStyles: React.CSSProperties = {
        width: "50%",
        height: "100%",
        position: "absolute",
        background: "#fbbd08",
    };

    const getTextColorForColumn = (id: number | null) => id === currentPlayerId ? {color: "#454d55"} : {color: "white"};

    return (
        <Grid columns={2} divided>
            <div
                className="custom-background"
                style={setSelectedColumnStyle({
                    id: initData?.players?.[0].id,
                    currentPlayerId,
                    basicStyles
                })}
            />

            <Grid.Row>
                <Grid.Column style={getTextColorForColumn(initData?.players?.[0].id)}>
                    <Player
                        isPlayingFirst={initData?.players?.[0].id === currentPlayerId}
                        isHome={true}
                        onSelect={setDartsPlayer}
                        leg={initData?.legs?.[0]}
                        playerId={initData?.players?.[0].id}
                        gameId={initData?.gameId}
                        isCurrentPlayerId={currentPlayerId}
                    />
                </Grid.Column>

                <Grid.Column style={getTextColorForColumn(initData?.players?.[1].id)}>
                    <Player
                        isPlayingFirst={initData?.players?.[1].id === currentPlayerId}
                        isHome={false}
                        onSelect={setDartsPlayer}
                        leg={initData?.legs?.[1]}
                        playerId={initData?.players?.[1].id}
                        gameId={initData?.gameId}
                        isCurrentPlayerId={currentPlayerId}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Column style={getTextColorForColumn(initData?.players?.[0].id)}>
                {turnScores && turnScores[0]?.map((score: number) => {
                    return (
                        <div>
                            <div style={{paddingBottom:"10px"}}>
                                {score}
                            </div>
                        </div>
                    )
                })}
            </Grid.Column>
            <Grid.Column style={getTextColorForColumn(initData?.players?.[1].id)}>
                {turnScores && turnScores[1]?.map((score: number) => {
                    return (
                        <div>
                            <div style={{paddingBottom:"10px"}}>
                                {score}
                            </div>
                        </div>
                    )
                })}
            </Grid.Column>
        </Grid>
    );
})

export {ScoreBoard};
