import React, {useEffect} from 'react';
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import mmsLogo from "../../img/MSSLogoMain.png"
import ADCLogo from "../../img/ADC-Logo-White-Orange-slogan.png"
import {getPostMatchStats} from "../../store/pages/postMatch/postMatchSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {splitName} from "../LeagueTablePage/LeagueTable/helpers/splitPlayerName";
import './styles.scss';
import './styles.media.scss';

const PostMatchPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const {playersStatistics, isLoading} = useAppSelector((state) => state.postMatch);

    useEffect(() => {
        dispatch(getPostMatchStats())
    }, []);

    return (
        <div className="post-match-wrapper">
            <div className="static-statistic">
                {
                    isLoading ? <OverlayLoader/> :
                        <div className="static-statistic-wrapper">
                            <div className="static-statistic-names">

                                {
                                   playersStatistics.players && playersStatistics.players.length > 0 &&
                                    <div>
                                    <span
                                        className="static-statistic-names-firstname">
                                        {splitName(playersStatistics.players[0]?.name)[0]}
                                    </span>
                                        <span
                                            className="static-statistic-names-lastname">
                                        {splitName(playersStatistics.players[0]?.name)[1]}
                                    </span>
                                    </div>
                                }

                                {playersStatistics.statistics && playersStatistics.statistics.length > 0 && <div className="static-statistic-total-points">
                                    <div
                                        className="static-statistic-total-points-wrapper">
                                        {playersStatistics.statistics[0]?.totalScore}
                                    </div>
                                    <div
                                        className="static-statistic-total-points-wrapper">
                                        {playersStatistics.statistics[1]?.totalScore}
                                    </div>
                                </div>
                                }

                                {
                                    playersStatistics.players && playersStatistics.players.length > 0 &&
                                    <div>
                                   <span
                                       className="static-statistic-names-firstname">
                                        {splitName(playersStatistics.players[1].name)[0]}
                                    </span>
                                        <span
                                            className="static-statistic-names-lastname">
                                        {splitName(playersStatistics.players[1].name)[1]}
                                    </span>
                                    </div>
                                }
                            </div>
                            {playersStatistics.statistics && playersStatistics.statistics.length > 0 && <div className="static-statistic-container">
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {playersStatistics.statistics[0]?.average.toFixed(2)}
                                    </div>
                                    <div className="static-statistic-item-title"> 3 dart average</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {playersStatistics.statistics[1]?.average.toFixed(2)}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {playersStatistics.statistics[0]?.turns180}
                                    </div>
                                    <div className="static-statistic-item-title">180s</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {playersStatistics.statistics[1]?.turns180}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {playersStatistics.statistics[0]?.turns140to179}
                                    </div>
                                    <div className="static-statistic-item-title">140+</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {playersStatistics.statistics[1]?.turns140to179}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {playersStatistics.statistics[0]?.checkouts.split("/")[0]}
                                        <p className={"fixed-value"}>
                                            /{playersStatistics.statistics[0]?.checkouts.split("/")[1]}
                                        </p>
                                    </div>
                                    <div className="static-statistic-item-title">Checkouts</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {playersStatistics.statistics[1]?.checkouts.split("/")[0]}
                                        <p className={"fixed-value"}>
                                            /{playersStatistics.statistics[1]?.checkouts.split("/")[1]}
                                        </p>
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value ">
                                        {`${playersStatistics.statistics[0]?.checkoutPercentage}`}
                                        <p className={"fixed-value"}>%</p>
                                    </div>
                                    <div className="static-statistic-item-title">Checkout %</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {`${playersStatistics.statistics[1]?.checkoutPercentage}`}
                                        <p className={"fixed-value"}>%</p>
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-odd">
                                    <div className="static-statistic-item-value">
                                        {playersStatistics.statistics[0]?.highestCheckout}
                                    </div>
                                    <div className="static-statistic-item-title">Highest checkout</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {playersStatistics.statistics[1]?.highestCheckout}
                                    </div>
                                </div>
                                <div className="static-statistic-item static-statistic-item-even">
                                    <div className="static-statistic-item-value">
                                        {playersStatistics.statistics[0]?.checkouts100plus}
                                    </div>
                                    <div className="static-statistic-item-title">100+ checkouts</div>
                                    <div className="static-statistic-item-value static-statistic-item-value-away">
                                        {playersStatistics.statistics[1]?.checkouts100plus}
                                    </div>
                                </div>
                                <div className="static-statistic-item">
                                    <div className="static-statistic-logo">
                                        <img src={ADCLogo} alt=""/>
                                    </div>
                                </div>
                            </div>}
                        </div>
                }
            </div>
        </div>
    );
}

export default PostMatchPage;
