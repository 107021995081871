
export const API_DARTS_FILTER_TOURNAMENTS = 'api/darts/filtered-tournaments-games';
export const API_DARTS_GAMES = 'api/darts-games-classic';
export const API_DARTS_FIRSTPLAYER = 'api/darts/first-player';
export const API_DARTS_WALKOVER = 'api/darts/match-walkover';
export const API_DARTS_UNDO_THROW = 'api/darts/throw-undo';
export const API_DARTS_THROW_DOUBLEATTEMPT = 'api/darts/double-attempt';
export const API_DARTS_THROW = 'api/darts/throw';
export const API_DARTS_SCORE_INIT = 'api/darts/game-scoring-init';
export const API_ERROR_LOGGER = 'api/Error/';
export const API_ONDLL_CRUD = 'api/mss-cms/news';
export const API_ONDLL_NEWS = 'api/mss-cms/news';
export const API_CLIENTS = 'api/clients';
export const API_USERS = 'api/users';
export const API_USERS_INIT = 'api/users/init';
export const API_ROLES = 'api/roles';
export const API_PERMISSIONS_DICTIONARY = 'api/permissions/dictionary';
export const API_CLIENT_PERMISSIONS_READ = 'api/permissions/client/read';
export const API_USER_PERMISSIONS_READ = 'api/permissions/user/read';
export const API_CURRENT_USER_PERMISSIONS_READ = 'api/permissions/try';
export const API_DARTS_TOURNAMENT_INIT = 'api/darts-tournaments/init';
export const API_DARTS_TOURNAMENTS = 'api/darts-tournaments'
export const API_DARTS_WEEKS = 'api/darts-weeks'
export const API_DARTS_PHASE = 'api/darts-phases'
export const API_COMPETITIONS = 'api/competitions'
export const API_COMPETITIONS_INIT = 'api/competitions/init'
export const API_PLAYERS_PHOTO = 'api/darts-player-pictures';
export const API_PLAYERS = 'api/darts-players'
export const API_DARTS_GAMES_INIT_DATA = 'api/darts-games-classic/init'
export const API_DARTS_SHOOTOUT_GAMES = 'api/darts-games-shootout';
export const API_DARTS_ADC_GAMES = 'api/darts-games-adc'
export const API_LIVE_SCORE_INIT = '/api/darts-statistics/live-score-init';
export const API_LIVE_SCORE_INIT_BY_TOUR = 'api/darts-statistics/live-score-init-by-tournament';
export const API_LIVE_SCORE_SIDEBAR = '/api/darts-statistics/live-score-sidebar';
export const API_LOGIN = '/api/auth/login';
export const API_SET_PASSWORD = '/api/auth/password-set';
export const API_SHARED = 'api/shared';
export const API_DARTS_DATA_INIT = '/api/darts/init';
export const API_VERSION = `${API_SHARED}/version`
export const API_DARTS_SCOREBOARD_STATS = '/api/darts-statistics/score-board-init'
export const API_DARTS_ACTIVE_GAME_SCORE_ADC = 'api/darts-statistics/active-game-score/adc';
export const API_DARTS_ACTIVE_GAME_SCORE = '/api/darts-statistics/active-game-score/classic'
export const API_DARTS_ACTIVE_GAME_SCORE_SHOOTOUT = '/api/darts-statistics/active-game-score/shootout';
export const API_POST_MATCH = '/api/darts-statistics/post-match';
export const API_PRE_MATCH = '/api/darts-statistics/pre-match';
export const API_LEAGUE_TABLE = '/api/darts-statistics/league-table';
export const API_DARTS_LISTENERS = 'api/listeners'
export const API_DARTS_LISTENERS_INIT = 'api/listeners/init'

