import React from "react";
import {Grid} from "semantic-ui-react";
import classnames from "classnames";
import "./styles.scss";
import {removeNameSeparator} from "../../../helpers/playersNames.helper";

interface IHeaderProps {
    reverse?: boolean;
    playerName: string;
    isCurrentPlayerThrow: boolean;
    remainingScore: number;
}

const ShootOutPlayerScoreboardHeader: React.FC<IHeaderProps> = (
    {
        reverse,
        playerName,
        isCurrentPlayerThrow,
        remainingScore,
    }) => {
    return (
        <Grid columns={16} className="player-scoreboard-header">
            {(
                <>
                    <Grid.Row
                        className={classnames("row", "name-row", {
                            reverse: reverse,
                            active: isCurrentPlayerThrow,
                        })}
                    >
                        <Grid.Column width={16} className="col name-col">
                            {removeNameSeparator(playerName)}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                        className={classnames("row", "scores-row", {reverse: reverse})}
                    >
                        <Grid.Column width={16} className="col remaining-score-col">
                            {remainingScore}
                        </Grid.Column>
                    </Grid.Row>
                </>
            )}
        </Grid>
    );
};

export default ShootOutPlayerScoreboardHeader
