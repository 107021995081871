export enum PlayerAdcType {
    UK = 1,
    Europe = 2,
    Oceania = 3
}

export enum AdcTypes {
    UK = "ADC UK",
    Europe = "ADC Europe",
    Oceania = "ADC Oceania"
}
