import moment from "moment";
import React from "react";
import {convertUtcToLocalDate} from "../../../../helpers/date.helper";
import classnames from "classnames";
import "./styles.scss";
import {useAppSelector} from "../../../../hooks/hooks";
import {ILiveScoreEvents} from "../../../../store/pages/liveScoreInfo/types";
import {DartsGameTypes} from "../../../../enums/gameEvents";

interface IMatchListItemProp {
    event: ILiveScoreEvents;
    onSelect: (eventId: number, isAdc: boolean) => void;
}

export const MatchListItem: React.FC<IMatchListItemProp> = (
    {
        event,
        onSelect,
    }) => {
    const activeSidebarEvent = useAppSelector(
        (state) => state.liveScoreInfo.activeSideBarEvent
    );
    const isEventActive = event.id === activeSidebarEvent;

    const selectGame = () => {
        const isAdc = event.type === DartsGameTypes.ADC;
        onSelect(event.id!, isAdc);
    };

    const getStatus = (event: ILiveScoreEvents) => {
        if (event.status === "NotActive") {
            const localStartTime = convertUtcToLocalDate(event.timeStart);
            return moment(localStartTime).format("HH:mm");
        } else {
            return event.status;
        }
    };

    return (
        <div
            onClick={selectGame}
            className={classnames("game-item", {
                "game-item--active": isEventActive,
            })}
        >
            <div className={"content"}>
                <div className={"status-inner"}>
                    <div className="status">{getStatus(event)}</div>
                </div>
                <div className={"scores-content"}>
                    <div className={"players"}>
                        <div>
                            <span className="name">{event.players?.home}</span>
                        </div>
                        <div>
                            <span className="name">{event.players?.away}</span>
                        </div>
                    </div>
                    <div className={"scores"}>
                        <div>
                            <span>{event.score?.home}</span>
                        </div>
                        <div className={"score-separator"}>
                            <hr/>
                        </div>
                        <div>
                            <span>{event.score?.away}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
