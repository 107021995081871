import React from 'react';
import styles from "./index.module.scss"
import {Form, Select} from "semantic-ui-react";

const CDCTournament = () => {
    return (
        <div className={styles.overlay}>
            <Form className={styles.form}>
                <div className={styles.tourColumn}>
                    <div className={styles.topOne}>
                        {new Array(16).fill(null).map((_, index) => (
                            <div className={styles.gameFirst}>
                                <div className={styles.decor}>{index + 1}</div>
                                <div className={styles.playersInputs}>
                                    <div className={styles.inputWrapper}>
                                        <Select
                                            options={[]}
                                            placeholder={"Select player"}
                                        />
                                    </div>
                                    <div className={styles.inputWrapper}>
                                        <Select
                                            options={[]}
                                            placeholder={"Select player"}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default CDCTournament;
