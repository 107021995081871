import React from "react";
import { Modal, Button } from "semantic-ui-react";

const ValidationModal = ({ open, text, onClose }) => {
  return (
    <div className="validation-modal-inner">
      <Modal size="mini" open={open}>
        <Modal.Header>IgameDC</Modal.Header>
        <Modal.Content>
          <p>{text}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={onClose}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ValidationModal;
