import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {client} from "../../../services/api.service";
import {errorLogger} from "../../../services/error-logger";
import {API_CLIENTS, API_DARTS_LISTENERS, API_DARTS_LISTENERS_INIT} from "../../../constants";
import {ISelectOption} from "../darts/tournaments/types";
import {IGetClientResponse} from "../clients/cmsClientsSlice";
import {IClient} from "../clients/types";

interface IGetListenersResponse {
    pageNum: number,
    pageSize: number,
    totalPages: number
    items: IListener[]
}

export interface IListenersState {
    listeners: IListener[]
    clients: IListenerClient[]
    allClients: IClient[]
    clientId: any
    totalPages: number,
    pageNum: number,
    pageSize: number,
    noContent: boolean
    loader: boolean
}

export interface IListener {
    id: number,
    isActive: boolean
    name: string
    email: string
    whiteIPs: string
    socketKey: string
    clientId: number,
    competitionId: number,
}

export interface ICreateListenerPayload {
    clientId: number,
    competitionId: number
    isActive: boolean,
    name: string,
    email: string,
    whiteIPs: string,
    socketKey: string
}

export interface IUpdateListenerPayload extends ICreateListenerPayload {
    id: number
}

export interface IListenerClient {
    id: number,
    name: string,
    items: ISelectOption[]
}

export interface IListenerInitResponse {
    clientsCompetitions: IListenerClient[]
}

const initialState: IListenersState = {
    allClients: [],
    clientId: null,
    listeners: [],
    clients: [],
    pageNum: 1,
    pageSize: 10,
    totalPages: 0,
    noContent: false,
    loader: false
}

export const getListenerInit = createAsyncThunk<IListenerInitResponse>(
    'listeners/init',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_LISTENERS_INIT}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)
export const getListeners = createAsyncThunk<IGetListenersResponse, string>(
    'listeners/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_LISTENERS}?${query}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch listeners.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)

export const getClients = createAsyncThunk<IGetClientResponse>(
    'listeners/get-all-client',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_CLIENTS}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch clients.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)

export const createListener = createAsyncThunk<IUpdateListenerPayload, ICreateListenerPayload>(
    'listeners/create',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.post(`${API_DARTS_LISTENERS}`, payload);
            toastr.success("CMS", "Listener has been created")
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create listeners.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)
export const updateListener = createAsyncThunk<IUpdateListenerPayload, IUpdateListenerPayload>(
    'listeners/update',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_LISTENERS}`, payload);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to update listeners.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)
export const deleteListener = createAsyncThunk<null, number>(
    'listeners/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_LISTENERS}/${id}`);
            toastr.success("CMS", "Listener has been deleted")
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to update listeners.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get clients: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.message.data);
        }
    }
)

export const listenersSlice = createSlice({
    name: 'cmsListeners',
    reducers: {
        setPageNumber: (state, action) => {
            state.pageNum = action.payload
        },
        setListenerClientId: (state, action) => {
            state.clientId = action.payload
        },
        onChangePageSize: (state, action) => {
            state.pageSize = action.payload
        },
    },

    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getListenerInit.fulfilled, (state, action) => {
                state.clients = action.payload.clientsCompetitions
            })
            .addCase(getClients.fulfilled, (state, action) => {
                state.allClients = action.payload.items
            })
            .addCase(getListeners.fulfilled, (state, action) => {
                state.listeners = action.payload.items
                state.totalPages = action.payload.totalPages
                state.noContent = action.payload.items.length === 0;
            })
            .addCase(createListener.fulfilled, (state, action) => {
                state.listeners.push(action.payload)
            })
            .addCase(updateListener.fulfilled, (state, action) => {
                state.listeners.map(listener => {
                    if (listener.id === action.payload.id) {
                        return action.payload
                    } else {
                        return listener
                    }
                })
            })
            .addCase(deleteListener.fulfilled, (state, action) => {
                state.listeners = state.listeners.filter(listener => listener.id !== action.meta.arg)
            })
    },
});

export const {setPageNumber, setListenerClientId, onChangePageSize} = listenersSlice.actions

export default listenersSlice.reducer;
