import React from 'react';

const DartsService = () => {
    return (
        <div>
            {/*<Card>*/}
            {/*    <CardContent textAlign={"center"} header='Games without statistics'/>*/}
            {/*    <CardContent>*/}
            {/*        <List>*/}
            {/*            <ListItem icon='marker' content='2323'/>*/}
            {/*            <ListItem icon='marker' content='5445'/>*/}
            {/*            <ListItem icon='marker' content='5445'/>*/}
            {/*            <ListItem icon='marker' content='5445'/>*/}
            {/*            <ListItem icon='marker' content='5445'/>*/}
            {/*            <ListItem icon='marker' content='5445'/>*/}
            {/*            <ListItem icon='marker' content='5445'/>*/}
            {/*        </List>*/}
            {/*    </CardContent>*/}
            {/*    <CardContent textAlign={"center"} extra>*/}
            {/*        <Button color={"green"}>Repair</Button>*/}
            {/*    </CardContent>*/}
            {/*</Card>*/}
        </div>
    );
};

export default DartsService;
