import axios from "axios";
import {LS_TOKEN} from "../constants/localStorageItems";

export const upload = axios.create({
    headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Encoding': 'identity'
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT
})
upload.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(LS_TOKEN);
        if (token) { // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)
