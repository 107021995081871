import moment from "moment";
import {IDartsTournament} from "../../../../../store/cms/darts/tournaments/types";

export const createTitleForDatePicker = (selectedTour: IDartsTournament | null) => {
    return selectedTour
        ?
        `Start date: (Select from 
            ${moment(selectedTour.dateStart).format("YYYY-MM-DD")} 
            to 
            ${moment(selectedTour.dateEnd).format("YYYY-MM-DD")})`
        : "Select date:"
}
