import './styles.scss';
import React from 'react';

interface ICheckboxCmsProps {
    handler?: () => void;
    id?: string;
    name?: string;
    value?: boolean;
    isReadyOnly?: boolean;
    isDisabled?: boolean;
}

const CheckboxCms: React.FC<ICheckboxCmsProps> = (
    {
        id,
        name,
        value,
        handler,
        isReadyOnly = false,
        isDisabled = false,
    }) => {

    return (
        <div className="checkbox-wrapper-1">
            <input
                onChange={handler}
                type="checkbox"
                id={id}
                name={name}
                disabled={isDisabled}
                readOnly={isReadyOnly}
                checked={value} aria-hidden="true"/>
        </div>
    );
};

export default CheckboxCms;
