export const removeNameSeparator = (name: string) => name?.trim().replace(/_/g, ' ');

export const getPlayerName = (displayName: string): string[]=> {
	const players = displayName?.split(' ');
	return players?.reduce((accum: string[], player: string) => {
		if (player) {
			accum.push(removeNameSeparator(player));
		}
		return accum
		}, [])
};



