import React from 'react';
import {Button, Card, CardContent, CardDescription, CardHeader, CardMeta} from "semantic-ui-react";
import {IClient} from "../../../../store/cms/clients/types";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import {useAppDispatch} from "../../../../hooks/hooks";
import {deleteClient} from "../../../../store/cms/clients/cmsClientsSlice";
import {useHistory} from "react-router-dom";
import {setClientId} from "../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {setListenerClientId} from "../../../../store/cms/listeners/listenersSlice";
import styles from "./index.module.scss";

interface IClientCardProps {
    client: IClient
    setEditedClient: (client: IClient) => void
}

const ClientCard: React.FC<IClientCardProps> = ({client, setEditedClient}) => {
    const dispatch = useAppDispatch()
    const history = useHistory()

    const goToUsers = (clientId: number | undefined) => {
        dispatch(setClientId(clientId))
        history.push("/cms/users")
    }
    const goToListener = (clientId: number | undefined) => {
        dispatch(setListenerClientId(clientId))
        history.push("/cms/listeners")
    }

    return (
        <div className={`${styles.card}`}>
            <Card>
                <CardContent className={client.isActive ? "" : `${styles.notActive}`}>
                    <CardHeader>{client.name}</CardHeader>
                    <CardDescription>
                        Email: <strong>{client.email}</strong>
                    </CardDescription>
                    <CardDescription>
                        Description: <strong>{client.description}</strong>
                    </CardDescription>
                    <CardDescription>
                        Domains: <strong>{client.domains}</strong>
                    </CardDescription>
                    <CardMeta
                        onClick={() => goToUsers(client.id)}
                        className={styles.link}>{`Users : ${client.users}`}
                    </CardMeta>
                    <CardMeta
                        onClick={() => goToListener(client.id)}
                        className={styles.link}>{`Listeners : ${client.listeners}`}
                    </CardMeta>
                </CardContent>
                <CardContent className={client.isActive ? "" : `${styles.notActive}`}>
                    <div className={styles.btnWrapper}>
                        <Button onClick={() => setEditedClient(client)} color='green'>
                            Update
                        </Button>
                        <DartsDeleteModal
                            btnView={true}
                            id={client.id}
                            deleteAction={() => dispatch(deleteClient(client.id as number))}
                            confirmText={`Are you sure you want to delete client ${client.name}`}
                            titleModal={"Delete client"}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ClientCard;
