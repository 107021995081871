import { Router } from 'react-router-dom';
import AppContainer from "./App";
import { Provider } from 'react-redux';
import { history } from './history';
import 'semantic-ui-css/semantic.min.css'
import './style.scss';
import React from 'react';
import {createRoot} from 'react-dom/client';
import toolkitStore from "./store";

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
        <Provider store={toolkitStore}>
            <Router history={history}>
                <AppContainer/>
            </Router>
        </Provider>
);
