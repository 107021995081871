import {client} from "../../../../services/api.service";
import fileDownload from "js-file-download";
import {errorLogger} from "../../../../services/error-logger";
import React from "react";

interface IGetStatisticPicture {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    leagueId: number;
}

export const getStatisticPicture = async ({setIsLoading, leagueId}: IGetStatisticPicture) => {
    try {
        setIsLoading(true);
        const statisticPicture = await client.get(`/api/darts-statistics/league-table-picture/${leagueId}`, {responseType: 'arraybuffer'});
        const fileName = statisticPicture.headers['content-disposition'].split("filename=")[1].split(';')[0]
        fileDownload(statisticPicture.data, fileName);
        setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
        if (error instanceof Error) {
            console.error(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
        }
    }
};
