import React from 'react';
import { Modal } from 'semantic-ui-react'
import './index.scss'

const AlertModal = ({open,text}) => {
  return (
    <div className='alert-modal-inner'>
      <Modal
        size='mini'
        open={open}
      >
        <Modal.Header>IgameDC</Modal.Header>
        <Modal.Content>
          <p>{text}</p>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default AlertModal;
