import {DartsGamesInitData} from "../../../../../store/cms/darts/adcGames/types";
import {useMemo} from "react";

interface ICountriesOption {
    id: number,
    name: string
}

interface IOptions {
    key: number;
    value: number;
    text: string;
}

export const useGetOptions = ({countries, competitions, players}: DartsGamesInitData) => {
    const dayPickerOptions = {
        dateFormat: "Y-m-d",
        locale: {
            firstDayOfWeek: 1,
        },
    };
    const countriesOptions = useMemo(() => {
        const options = countries?.map((country) => ({
            key: country.id,
            value: country.id,
            text: country.name,
        }))
        return options
    }, [countries])

    const playersOptions: IOptions[] = useMemo(() => {

        const options = players?.map((player) => ({
            key: player.id,
            value: player.id,
            text: player.name,
        }))
        return options
    }, [players])

    const competitionOptions: IOptions[] = useMemo(() => {
        const options = competitions?.map((competition) => ({
            key: competition.id,
            value: competition.id,
            text: competition.name,
        }))
        return options

    }, [competitions])

    return {competitionOptions, countriesOptions, playersOptions, dayPickerOptions}
}
