import React from 'react';
import styles from './index.module.scss'

const AppLoader = () => {
    return (
        <div className={styles.appLoader}></div>
    );
};

export default AppLoader;
