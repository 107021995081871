import React from "react";
import {Container, Grid} from "semantic-ui-react";
import moment from "moment";
import {useAppSelector} from "../../../../hooks/hooks";
import {renderIconForAwayPlayer, renderIconForHomePlayer} from "./helpers/getIcon";
import {getLogContentAwayPlayer, getLogContentHomePlayer} from "./helpers/getLogContent";
import {removeNameSeparator} from "../../../DartBoard/components/GameScores/helpers/removeNameSeparator";
import "./styles.scss";
import {LiveScorePlayer} from "../../../../store/pages/liveScoreInfo/types";

export const DartTimeline: React.FC = React.memo(() => {
    const allInfo = useAppSelector((state) => state.liveScoreInfo.allInformation);
    const timeLines = allInfo?.liveScore?.timelines?.items;
    const players = allInfo?.liveScore?.header?.players
    const formatTime = (seconds: number): string => {
        return moment.utc(seconds * 1000).format("mm:ss");
    };
    const formatName = (player:LiveScorePlayer[],index:number) => {
        if (!player.length) {
            return
        }
        else {
            return removeNameSeparator(players[index]?.name)
        }
    }
    return (
        <div>
            {players && <Container>
                <div>
                    <Grid>
                        <Grid.Row columns={16} className="timeline__round__teams">
                            <Grid.Column width={8} textAlign="left">
                                {formatName(players,0)}
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                                {formatName(players,1)}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Grid className="timeline">
                        {timeLines?.map((line, index) => {
                            return (
                                <Grid.Row
                                    key={index}
                                    columns={16}
                                    className="timeline__round"
                                    verticalAlign="middle"
                                >
                                    <Grid.Column width={2} className="timeline__round__time">
                                        {formatTime(line?.seconds)}
                                    </Grid.Column>
                                    <Grid.Column
                                        width={5}
                                        className="timeline__round__content"
                                        textAlign="right"
                                    >
                                        {getLogContentHomePlayer(line)}
                                    </Grid.Column>
                                    <Grid.Column width={1} className="timeline__round__icon">
                                        {renderIconForHomePlayer(line)}
                                    </Grid.Column>
                                    <Grid.Column width={2} className="timeline__round__score">
                                        {line.score}
                                    </Grid.Column>
                                    <Grid.Column width={1} className="timeline__round__icon">
                                        {renderIconForAwayPlayer(line)}
                                    </Grid.Column>
                                    <Grid.Column
                                        width={5}
                                        className="timeline__round__content"
                                        textAlign="left"
                                    >
                                        {getLogContentAwayPlayer(line)}
                                    </Grid.Column>
                                </Grid.Row>
                            );
                        })}
                    </Grid>
                </div>
            </Container>}
        </div>
    );
})

DartTimeline.displayName = "DartTimeline";
