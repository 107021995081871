import {LS_CURRENT_USER, LS_TOKEN} from "../../../constants/localStorageItems";
import {selectAdcGame, selectClassicGame, selectTournament} from "../../../store/pages/schedule/scheduleSlice";
import {AppDispatch} from "../../../store";
import { History } from "history";
import {clearLoginState} from "../../../store/login/authSlice";

export const handleLogout = (dispatch: AppDispatch, history: History) => {
    localStorage.removeItem(LS_TOKEN);
    localStorage.removeItem(LS_CURRENT_USER);
    dispatch(selectClassicGame(0));
    dispatch(selectAdcGame(0));
    dispatch(selectTournament(0));
    dispatch(clearLoginState())
    sessionStorage.clear();
    history.push('/login');
};
