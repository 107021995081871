import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {errorLogger} from "../../services/error-logger";
import {client} from "../../services/api.service";

export interface IDailyFixture {
    gameId: number;
    fixture: string;
    playerHome: string;
    playerAway: string;
    scorePlayerHome: number;
    scorePlayerAway: number;
}

export interface IDailyTournament {
    id: number,
    name: string,
    from: string,
    to: string,
    fixtures: IDailyFixture[]

}

export interface IGetDailyResultResponse {
    isFinal: boolean,
    isOneDay: boolean,
    from: string,
    to: string,
    tournaments: IDailyTournament[]
}

interface IDailyResultState {
    loader: boolean
    groupName: string
    isFinalGroup: boolean | null
    tournaments: IDailyTournament[]
    competitionTime: {
        from: string,
        to: string

    }
}

export const getDailyResults = createAsyncThunk<IGetDailyResultResponse, number>(
    'get-daily-results',
    async (tourId, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`api/darts-statistics/daily-results/${tourId}`);
            return data;
        } catch (error) {
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Daily-result: ${error.name}, ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.message)
        }
    }
)

const initialState: IDailyResultState = {
    competitionTime: {
        from: "",
        to: ""
    },
    tournaments: [],
    isFinalGroup: null,
    groupName: "",
    loader: false
}

export const dailyResultSlice = createSlice({
    name: 'dailyResult',
    reducers: {
        updateResultBySocket: (state, action: PayloadAction<IGetDailyResultResponse>) => {
            if (!action.payload.isFinal) {
                state.tournaments = action.payload.tournaments
                state.competitionTime.from = action.payload.from
                state.competitionTime.to = ""
                return
            } else {
                state.tournaments = action.payload.tournaments
                state.competitionTime.from = action.payload.from
                state.competitionTime.to = action.payload.to
                state.isFinalGroup = action.payload.isFinal
            }

        },
        setIsFinalDailyResult:(state, action) => {
            state.isFinalGroup = action.payload
        }

    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDailyResults.fulfilled, (state, action) => {
                if (action.payload.isOneDay) {
                    state.tournaments = action.payload.tournaments
                    state.competitionTime.from = action.payload.from
                    state.competitionTime.to = ""
                    state.isFinalGroup = action.payload.isFinal
                    state.loader = false
                    return
                } else {
                    state.tournaments = action.payload.tournaments
                    state.competitionTime.from = action.payload.from
                    state.competitionTime.to = action.payload.to
                    state.isFinalGroup = action.payload.isFinal
                    state.loader = false
                }


            })
            .addCase(getDailyResults.pending, (state, action) => {
                state.loader = true

            })
            .addCase(getDailyResults.rejected, (state, action) => {
                state.loader = false

            })
    }
})
export const {updateResultBySocket,setIsFinalDailyResult} = dailyResultSlice.actions
export default dailyResultSlice.reducer;