import React from 'react'
import {animated, useSpring, useSprings} from "react-spring";
import './style.scss'
import './style-media.scss'
import {IPlayerAnimateData} from "../PreMatchPage";


interface IPlayerResult {
    counter: number,
    playerHome: IPlayerAnimateData,
    playerAway: IPlayerAnimateData
}

const PlayerLastResult: React.FC<IPlayerResult> = (
    {counter, playerHome, playerAway}) => {

    const showScoreLabel = useSpring({
        to: async (next) => {
            await next({width: counter >= 22800 && counter < 25700 ? "313px" : "24px"})
        },
        from: {width: "0px",},
        config: {duration: 300}
    })
    const moveLabelLineStart = useSpring({
        to: async (next) => {
            await next({height: counter >= 22300 && counter <= 22750 ? "100%" : "0px"})
        },
        from: {height: "0px",},
        config: {duration: 300}
    })
    const moveLabelLineEnd = useSpring({
        to: async (next) => {
            await next({height: counter >= 23800 && counter <= 24900 ? "100%" : "0px"})
        },
        from: {height: "0px",},
        config: {duration: 300}
    })

    const setMovementsHomeWidth = (index: number) => {
        switch (index) {
            case 0:
                return '60%'
            case 1:
                return '70%'
            case 2:
                return '80%'
            case 3:
                return '90%'
            case 4:
                return '100%'

        }
    }
    const setMovementsAwayWidth = (index: number) => {
        switch (index) {
            case 0:
                return '100%'
            case 1:
                return '90%'
            case 2:
                return '80%'
            case 3:
                return '70%'
            case 4:
                return '60%'

        }
    }
    const setMovementsDelay = (index: number) => {
        switch (index) {
            case 0:
                return 1500
            case 1:
                return 1700
            case 2:
                return 1900
            case 3:
                return 2100
            case 4:
                return 2300

        }
    }

    const getResult = (value: any) => {
        switch (value) {
            case true:
                return 'W'
            case false:
                return 'L'
            case null:
                return ''
        }
    }

    const getWinLoss = (value: boolean | null) => {
        switch (value) {
            case true:
                return 'result__value result__value__win'
            case false:
                return 'result__value result__value__loss'
            case null:
                return 'result__value result__value__loss'
        }
    }
    const getMovementsHomeResult = () => {
        // @ts-ignore
        return playerHome?.lastGames?.map((item: any, index: number) => {
            return {
                id: index + 1,
                result: item,
                from: {width: "0%"},
                to: {
                    width: counter >= 21500 && counter < 25200 ? setMovementsHomeWidth(index) : "0%",
                    display: counter >= 21500 && counter < 27000 ? "block" : "none"
                },
                config: {duration: 800},
                delay: setMovementsDelay(index)
            }
        })
    }
    const getMovementsAwayResult = () => {
        // @ts-ignore
        return playerHome.lastGames?.map((item: any, index: number) => {
            return {
                id: index + 1,
                result: item,
                from: {width: "0%"},
                to: {
                    width: counter >= 21500 && counter < 25200 ? setMovementsAwayWidth(index) : "0%",
                    display: counter >= 21500 && counter < 27000 ? "block" : "none"
                },
                config: {duration: 800},
                delay: setMovementsDelay(index)
            }
        })
    }


    const springHome = useSprings(
        getMovementsHomeResult().length,
        // @ts-ignore
        getMovementsHomeResult().map(({id, result, ...config}) => config)
    )
    const springAway = useSprings(
        getMovementsAwayResult().length,
        // @ts-ignore
        getMovementsAwayResult().map(({id, result, ...config}) => config)
    )
    return (
        <>
            <div className="results home-results">
                {springHome.map((spring, index) => {
                    return (<animated.div
                        key={getMovementsHomeResult()[index].id}
                        className="result"
                        // @ts-ignore
                        style={{...spring}}>
                        <div
                            // @ts-ignore
                            className={getWinLoss(playerHome.lastGames[index]) + ' result__value-right-pos'}>
                            {
                                // @ts-ignore
                                getResult(playerHome.lastGames[index])}
                        </div>
                    </animated.div>)
                })}
            </div>
            <div className="results away-results">
                {springAway.map((spring, index) => {
                    return (<animated.div
                        key={getMovementsAwayResult()[index].id}
                        className="result-away"
                        // @ts-ignore
                        style={{...spring}}>
                        <div
                            // @ts-ignore
                            className={getWinLoss(playerAway.lastGames[index]) + ' result__value-left-pos'}>
                            {
                                // @ts-ignore
                                getResult(playerAway.lastGames[index])}
                        </div>
                    </animated.div>)
                })}
            </div>
            <div className="player-last-result">
                <animated.div className="block-label" style={showScoreLabel}>
                    <div className="title-inner">
                        <p className="block-label__title">Last</p>
                    </div>
                    <div className="subtitle-inner">
                        <p className="block-label__subtitle">5 RESULTS</p>
                    </div>
                    <animated.div
                        className="block-label-decor"
                        style={moveLabelLineStart}
                    />
                </animated.div>
            </div>
        </>

    )
}

export default PlayerLastResult