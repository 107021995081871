import {DartsCompetition} from "../../../../../../store/cms/darts/competitions/types";

interface IsExistsCompetitionNameProps {
    dartsCompetitions: DartsCompetition[];
    competitionName: string;
}

export const isExistsCompetitionName = ({dartsCompetitions, competitionName}: IsExistsCompetitionNameProps): boolean => {
    const competition = dartsCompetitions
        .reduce((accum: string[], item) => {
            accum.push(item.name)
            return accum
        }, [])

    return competition.includes(competitionName)
}
