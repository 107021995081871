import React, {useEffect} from "react";
import {Grid} from "semantic-ui-react";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {LS_ACTIVE_PLAYER} from "../../constants/localStorageItems";
import {getScoreboardStatsInit} from "../../store/pages/scoreboardAndStats/scoreboardAndStatsSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {IUrlParams} from "../../constants/globalTypes";
import GameScores from "../DartBoard/components/GameScores/GameScores";
import PlayerCard from "./components/PlayerCard/PlayerCard";
import ScoreTable from "./components/ScoreTable/ScoreTable";
import TurnsScoreTable from "./components/TurnsScoreTable/TurnsScoreTable";
import Average180Table from "./components/Average180Table/Average180Table";
import "./styles.scss";
import './styles-media.scss';
import DoublesMissedLegTable from "./components/DoubleMissedLegTable/DoublesMissedLegTable";
import {PlayerInfo} from "../../store/pages/scoreboardAndStats/types";

const DartMatchStatsTable: React.FC = () => {
    const dispatch = useAppDispatch();
    const getBtnNameToLocalStorage = (): string | null => {
        if (!localStorage.getItem(LS_ACTIVE_PLAYER)) return null;
        return JSON.parse(localStorage.getItem(LS_ACTIVE_PLAYER)!);
    }
    const {gameType, gameId} = useParams<IUrlParams>();
    const {scoreBoardAndStatsInfo: {playersInfo, averages, checkouts, doubleMissed}, statsLoader} = useAppSelector(
        (state) => state.scoreboardAndStats
    );
    const isConnected = useAppSelector((state) => state.connection);
    const [activeBtnName, setActiveBtnName] = useState(getBtnNameToLocalStorage());
    const [playerInCard, setPlayerInCard] = useState({} as PlayerInfo);
    const players = playersInfo.playersInfo
    useEffect(() => {
        if (isConnected) {
            dispatch(getScoreboardStatsInit(
                {
                    gameType,
                    gameId: +gameId!
                }));
        }
    }, [isConnected]);

    useEffect(() => {
        setBtnNameToLocalStorage(activeBtnName)
    }, [activeBtnName])

    const onSelectPlayer = (btnName: string, player: PlayerInfo) => {
        setActiveBtnName(btnName)
        setPlayerInCard(player)
        setActiveBtnName(btnName)
    }
    useEffect(() => {
        if (players.length > 0) {
            switch (getBtnNameToLocalStorage()) {
                case '1player':  // if (x === 'value1')
                    onSelectPlayer("1player", players[0])
                    break;
                case '2player':  // if (x === 'value2')
                    onSelectPlayer("2player", players[1])
                    break;
                default:
                    onSelectPlayer("1player", players[0])
            }
        }
    }, [players])


    const setBtnNameToLocalStorage = (activeBtnClass: string | null): void => {
        localStorage.setItem(LS_ACTIVE_PLAYER, JSON.stringify(activeBtnClass))
    }

    return statsLoader ? (
        <div className="match-stats-loader">Loading...</div>
    ) : (
        <div className="match-stats">
            {players.length > 0 && (
                <Grid>
                    <Grid.Row>
                        <Grid.Column className="panel-column" width="5">
                            <div className="prematch-control">
                                <button
                                    className={activeBtnName === "1player" ? "prematch-control__btn prematch-control__btn-active" : "prematch-control__btn"}
                                    onClick={() => onSelectPlayer("1player", players[0])}
                                >
                                    P1
                                </button>
                                <button
                                    onClick={() => onSelectPlayer("2player", players[1])}
                                    className={activeBtnName === "2player" ?
                                        "prematch-control__btn prematch-control__btn-active"
                                        :
                                        "prematch-control__btn"}
                                >
                                    P2
                                </button>
                            </div>
                            {playerInCard && <PlayerCard playerInfo={playerInCard}/>}
                        </Grid.Column>
                        <Grid.Column className="content-column" width="5">
                            {players.length > 0 && <div className="content">
                                <div className="border-container">
                                    <div className="game-scores-wrapper">
                                        <div className="wrapper-score">
                                            <ScoreTable
                                                homeValue={averages.average[0]}
                                                awayValue={averages.average[1]}
                                                title={"Average"}
                                            />
                                            <ScoreTable
                                                homeValue={checkouts.checkouts[0]}
                                                awayValue={checkouts.checkouts[1]}
                                                title={"Checkouts"}
                                            />
                                            <Average180Table/>
                                            <TurnsScoreTable/>
                                            <ScoreTable
                                                homeValue={checkouts.highestCheckout[0]}
                                                awayValue={checkouts.highestCheckout[1]}
                                                title={" High checkout"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </Grid.Column>
                        <Grid.Column className={'right-content-column'} width="4">
                            <GameScores/>
                            <div className={'counters-container'}>
                                <DoublesMissedLegTable doubleMissedInfo={doubleMissed}/>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </div>
    );
};


export default DartMatchStatsTable;
DartMatchStatsTable.displayName = "DartMatchStatsTable";
