import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../services/api.service";
import {API_LEAGUE_TABLE} from "../../../constants";
import {toastr} from "react-redux-toastr";
import {errorLogger} from "../../../services/error-logger";
import {ILeagueTable, ILeagueTableItem} from "./types";

const initialState: ILeagueTable  = {
        items: [] as ILeagueTableItem[]
}

export const getLeagueTableData = createAsyncThunk<ILeagueTable,number>(
    'leagueTable/get',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_LEAGUE_TABLE}/${id}`);
            return data;
        } catch (error) {
            toastr.error('League table', 'Failed to fetch data.');
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `getLeagueTableData: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }
    }
)

export const leagueTableSlice = createSlice({
    name: 'preMatch',
    reducers: {
        setLeagueTable: (state, action: PayloadAction<ILeagueTable>) => {

            const {items} = action.payload;
            state.items = items;
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getLeagueTableData.fulfilled, (state, action) => {
                const {items} = action.payload;
                state.items = items
            })
    }})

export const {
    setLeagueTable,
} = leagueTableSlice.actions;
export default leagueTableSlice.reducer;
