import React from "react";
import './style.scss'
import PlayerStats from "../PlayersStats/PlayersStats";
import BroadcastersFooter from "../components/BroadcastersFooter/BroadcastersFooter";
import {animated, useSpring} from "react-spring";
import {IStatisticData} from "../PreMatchPage";
import animatedBg from "../../../img/animatedBg.mp4"
export interface IPlayerVsPLayerProps {
    counter: number,
    animateData: IStatisticData
    ref: any
}

const PlayerVsPLayer: React.FC<IPlayerVsPLayerProps> = ({counter, animateData, ref}) => {

    const playersStatStyles = useSpring({
        to: async (next) => {
            await next({opacity: counter > 3000 ? 1 : 0,})
        },
        from: {opacity: 0,}
    })

    const blackBgStart = useSpring({
        to: async (next) => {
            await next({
                backgroundColor: counter >= 0 && counter < 3500 ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)'
            })
        },
        from: {backgroundColor: 'rgba(0, 0, 0, 0)',},
        config: {duration: 800}
    })
    const blackBgEnd = useSpring({
            to: async (next) => {
                await next({
                    backgroundColor: counter > 38500 && counter <= 40000 ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)'
                })
            },
            from: {backgroundColor: 'rgba(0, 0, 0, 0)',},
            config: {duration: 800}
        })

    return (
        <>
            <div className="player-vs-player">
                <animated.div style={counter <= 4000 && counter >= 38000 ? blackBgStart : blackBgEnd}>
                        <div id="particle-container">
                            <video
                                ref={ref}
                                width={'120%'}
                                height={'85%'}
                                src={animatedBg}
                                muted={true}
                                autoPlay={true}
                                loop={true}
                            />
                        </div>
                </animated.div>

                <animated.div style={playersStatStyles}>
                    <PlayerStats
                        playerHome={animateData.playerHome}
                        playerAway={animateData.playerAway}
                        counter={counter}/>
                </animated.div>
                <BroadcastersFooter counter={counter}/>
            </div>
        </>
    )
}


export default PlayerVsPLayer;
PlayerVsPLayer.displayName = "PlayerVsPLayer";
