import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import './styles.scss';
import {FinishedRound, PlayerScoreData} from "../../../../enums/gameEvents";

interface IBodyProps {
  reverse?: boolean;
  rounds: FinishedRound[];
  turnData: PlayerScoreData[];
}

const PlayerScoreboardBody: React.FC<IBodyProps> = ({ reverse, rounds, turnData }) => {
  const scrollToBottomRef: any = useRef();

  const scrollToBottom = () => {
    if (scrollToBottomRef.current) {
      scrollToBottomRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  useEffect(scrollToBottom, [turnData]);

  return (
    <Grid columns={16} className="player-scoreboard-body">
      {turnData?.map((score, i) => (
        <Grid.Row key={i} className="row" >
          {reverse && <Grid.Column width={4}></Grid.Column>}
          <Grid.Column width={4} className="col scored-col score-turn">{score.turn}</Grid.Column>
          <Grid.Column width={4} className="col remaining-score-col score-value">{score.score}</Grid.Column>
        </Grid.Row>
      ))}
      <div ref={scrollToBottomRef} ></div>
    </Grid>
  );
}

const mapStateToProps = (state: any) => ({
  rounds: state.dartsRounds
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerScoreboardBody);
