import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {client} from "../../../../services/api.service";
import {errorLogger} from "../../../../services/error-logger";
import {toastr} from "react-redux-toastr";
import {
    DartsTournaments, ICreateTournamentPayload,
    IDartsTour, IEditTournamentPayload,
    ITournamentsInit
} from "./types";
import {API_DARTS_TOURNAMENT_INIT, API_DARTS_TOURNAMENTS} from "../../../../constants";

export interface IGetToursResponse {
    totalItems: number,
    totalPages: number,
    pageNum: number,
    pageSize: number,
    items: IDartsTour[]

}

const initialState: DartsTournaments = {
    dartsTournaments: [],
    editTournamentId: null,
    editMode: false,
    isVisibleForm: false,
    isLoading: true,
    noContent: null,
    competitionOptions: [],
    groupOptions: [],
    phaseOptions: [],
    totalItems: 0,
    totalPages: 0,
    pageNum: 1,
    pageSize: 10,
    filter: {
        phase: undefined,
        week: undefined,
        competition: undefined
    }

}

export const getDartsTournaments = createAsyncThunk<IGetToursResponse, string>(
    'dartsTournaments/get',
    async (query, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_TOURNAMENTS}?${query}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts tournaments.', {id: "get-tour"})
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Tournaments: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.message);
        }
    }
)

export const getDartsTournamentsInit = createAsyncThunk<ITournamentsInit>(
    'dartsTournamentsInit/get',
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await client.get(API_DARTS_TOURNAMENT_INIT);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch darts tournaments init.', {id: "get-init-tour"})
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get Darts Tournaments Init: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const createDartsTournament = createAsyncThunk<IDartsTour, ICreateTournamentPayload>(
    'dartsTournament/post',
    async (tournament, {rejectWithValue}) => {
        try {
            const {data} = await client.post(API_DARTS_TOURNAMENTS, tournament);
            toastr.success("CMS", "Darts tournament has been created", {id: "succeed-create-tour"});
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create a tournament.', {id: "error-create-tour"})
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Create darts tournament: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }

    }
)


export const editDartsTournament = createAsyncThunk<IDartsTour, IEditTournamentPayload>(
    'dartsTournament/edit',
    async (tournament, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_TOURNAMENTS}`, tournament);
            toastr.success("CMS", "Darts tournament has been edited", {id: "edit-tour"});
            return data;
        } catch (error) {
            console.log(error.message);
            toastr.error('CMS', 'Failed to edit a tournament.', {id: "error-edit-tour"})
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Edit darts tournament: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteDartsTournament = createAsyncThunk<string | undefined, any>(
    'dartsTournament/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_TOURNAMENTS}/${id}`);
            toastr.success("", "Darts tournament has been deleted", {id: "delete-tour"});
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete a darts tournament.', {id: "error-delete-tour"})
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Delete darts tournament: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)


export const cmsTournamentsSlice = createSlice({
    name: 'cmsDartsTournaments',
    reducers: {
        showTournamentForm: (state) => {
            state.isVisibleForm = true
        },
        hideTournamentForm: (state) => {
            state.isVisibleForm = false;
            state.editMode = false;
        },
        setEditTournamentMode: (state, action: PayloadAction<number | null>) => {
            state.editMode = true;
            state.isVisibleForm = true;
            state.editTournamentId = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNum = action.payload
        },
        changePageSize: (state, action) => {
            state.pageSize = action.payload
        },
        setFilter: (state, action) => {
            state.filter = {...state.filter, [action.payload.name]: action.payload.value}
        },

        clearFilter: (state) => {
            state.filter = {phase: null, week: null, competition: null}
        }

    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDartsTournamentsInit.fulfilled, (state, action) => {
                state.competitionOptions = action.payload.competitions
                state.phaseOptions = action.payload.phases
                state.groupOptions = action.payload.groups
            })
            .addCase(getDartsTournamentsInit.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getDartsTournaments.fulfilled, (state, action) => {
                state.dartsTournaments = action.payload.items
                state.isLoading = false;
                state.totalPages = action.payload.totalPages
            })
            .addCase(getDartsTournaments.rejected, (state) => {
                state.isLoading = false;

            })
            .addCase(deleteDartsTournament.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDartsTournament.fulfilled, (state, action) => {
                state.isLoading = false;
                state.dartsTournaments = state.dartsTournaments.filter(tour => tour.id !== action.meta.arg.id)
            })
            .addCase(deleteDartsTournament.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createDartsTournament.pending, (state) => {
                state.isLoading = true;
                state.isVisibleForm = false;
            })
            .addCase(createDartsTournament.fulfilled, (state, action) => {
                state.dartsTournaments.unshift(action.payload);
                state.isLoading = false;
            })
            .addCase(createDartsTournament.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(editDartsTournament.pending, (state) => {
                state.isVisibleForm = false;
                state.isLoading = true;
            })
            .addCase(editDartsTournament.fulfilled, (state, action) => {
                state.dartsTournaments = state.dartsTournaments.map((tournament) => {
                    if (tournament.id === action.payload.id) {
                        tournament = action.payload
                    }
                    return tournament
                })
                state.editTournamentId = 0;
                state.isLoading = false;
            })
            .addCase(editDartsTournament.rejected, (state) => {
                state.editTournamentId = 0;
                state.isLoading = false;
            });
    },
});

export const {
    showTournamentForm,
    hideTournamentForm,
    setEditTournamentMode,
    setPageNumber,
    setFilter,
    clearFilter,
    changePageSize
} = cmsTournamentsSlice.actions
export default cmsTournamentsSlice.reducer;

