import {IFormData} from "../MssNewsForm/MssNewsForm";
import React, {ChangeEvent} from "react";

interface IMssInputFileHandler {
    formData: IFormData;
    setFormData: React.Dispatch<React.SetStateAction<IFormData>>;
    e: ChangeEvent<HTMLInputElement>;
    inputRef?: React.RefObject<HTMLInputElement>
}

export const inputFileHandler = ({e, formData, setFormData,inputRef}: IMssInputFileHandler) => {
    const WIDTH = 1400;
    const HEIGHT = 800;
    let reader = new FileReader();
    let files = e.target.files;

    if (files && files.length) {
        setFormData({...formData, imgName: files[0]?.name.split('.')[0]});
        reader.readAsDataURL(files[0]);
    }

    reader.onload = e => {
        let imgUrl: any;
        if (e.target) {
            imgUrl = e.target.result;
        }
        const img = new Image();
        img.src = imgUrl;

        img.onload = (e: any) => {
            const imgWidth = e.target.width;
            const imgHeight = e.target.height;

            if (imgWidth !== WIDTH || imgHeight !== HEIGHT) {
                alert(`The size should be  ${WIDTH}x${HEIGHT}`);
                if (inputRef && inputRef.current) {
                    inputRef.current.value = '';
                }
                return;
            }

            setFormData({...formData, imgValue: imgUrl});

            const canvas = document.createElement('canvas');
            const ratio = WIDTH / imgWidth;
            canvas.width = WIDTH;
            canvas.height = imgHeight * ratio;
            const context = canvas.getContext("2d");

            if (context) {
                context.drawImage(img, 0, 0, canvas.width, canvas.height);
                const imgUrl = context.canvas.toDataURL("image/jpeg", 0.8);
                setFormData({...formData, imgValue: imgUrl, fileValue: imgUrl});
            }
        };
    };
};