import {client} from "./api.service";
import {API_ERROR_LOGGER} from "../constants";


export interface IErrorLogger {
    projectName: string;
    errorMessage: string;
    errorDate: Date | string;
}

export const errorLogger = async (payload: IErrorLogger) => {
    try {
        const response = await client.post(`${API_ERROR_LOGGER}`, payload);
        return response.data;
    }
     catch (e) {
         if (e instanceof Error) {
             console.log(`${e.name} ${e.message}`)
         }
     }
}
