import React, {FC} from 'react';
import modusLogo from "../../../../img/MSSLogoMain.png";
import {PlayerInfo} from "../../../../store/pages/scoreboardAndStats/types";
import {splitName} from "../../../LeagueTablePage/LeagueTable/helpers/splitPlayerName";
import ADCLogo from "../../../../img/ADC-Logo-White-Orange-slogan.png"
interface IPlayerCardProps {
    playerInfo: PlayerInfo
}

const PlayerCard: FC<IPlayerCardProps> = ({playerInfo}) => {
    return (
        <div className="player-card">
            <div className="player-card__logo">
                <img
                    alt="logo"
                    className="player-card__logo-img"
                    src={ADCLogo}
                />
            </div>
            {Object.keys(playerInfo).length > 0 && <div className="player-card-name">
                <h1 className="player-card-name__first-name">{splitName(playerInfo.name)[0]}</h1>
                <h1 className="player-card-name__last-name">{splitName(playerInfo.name)[1]}</h1>
            </div>}
            <div className="player-card-info">
                <div className="panel-stats-item panel-stats-item-card">
                    <div>
                        <p className="panel-stats-title">AGE</p>
                    </div>
                    <div>
                        <p className="panel-stats-value panel-stats-value-card">
                            {playerInfo.age}
                        </p>
                    </div>
                </div>
                <div className="panel-stats-item panel-stats-item-card">
                    <div>
                        <p className="panel-stats-title">HOME TOWN</p>
                    </div>
                    <div>
                        <p className="panel-stats-value panel-stats-value-card">
                            {playerInfo.hometown}
                        </p>
                    </div>
                </div>
                <div className="panel-stats-item panel-stats-item-card">
                    <div>
                        <p className="panel-stats-title">DARTS USED</p>
                    </div>
                    <div>
                        <p className="panel-stats-value panel-stats-value-card">
                            {playerInfo.dartsUsed}
                        </p>
                    </div>
                </div>
                <div className="panel-stats-item panel-stats-item-card">
                    <div className="panel-stats-item-card-career">
                        <div className="panel-stats-title">
                            MODUS Super Series Best
                        </div>
                        <div className="panel-stats-value">
                            {playerInfo.careerBest}
                        </div>
                    </div>
                </div>
                {playerInfo.nickname && <div className="panel-stats-item panel-stats-item-card">
                    <div>
                        <p className="panel-stats-title">Nickname</p>
                    </div>
                    <div>
                        <p className="panel-stats-value panel-stats-value-card">
                            {playerInfo.nickname}
                        </p>
                    </div>
                </div>
                }
            </div>
        </div>
    );
};

export default PlayerCard;
