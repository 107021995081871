import React from "react";
import {OnShotForLiveScore} from "../../../enums/gameEvents";

interface ISetLastShotAndHighlightBed {
    setLastShot:  React.Dispatch<React.SetStateAction<string | undefined>>;
    liveShot: OnShotForLiveScore;
    dartboardRef:  React.MutableRefObject<any>;
}

const getPureScoreValue = (shot: OnShotForLiveScore) => {
    switch (shot.ring) {
        case "double": {
            return shot.bed;
        }
        case "triple": {
            return shot.bed;
        }
        case "borders":
        case "innerBull":
        case "outerBull":
        case "innerSingle":
        case "outerSingle":
        default: {
            return shot.bed;
        }
    }
};

export const setHighlightBedOnLastShot = ({setLastShot, liveShot, dartboardRef}: ISetLastShotAndHighlightBed): void => {
    const clearLastShot = () => setLastShot("");
    setLastShot(liveShot.message);
    setTimeout(() => setLastShot(""), 1000);
    const classnameOfElement = `c-Dartboard-${liveShot.ring}--${getPureScoreValue(liveShot)}`;
    const bedToHighlight = dartboardRef.current.getElementsByClassName(classnameOfElement)[0] as HTMLElement;
    if (bedToHighlight) {
        const highlightBed = (element: HTMLElement) => {
            const className = "selected-bed";
            element.classList.add(className);
            const hideLastShot = () => {
                element.classList.remove(className);
                clearLastShot();
            };
            setTimeout(hideLastShot, 1000);
        };
        highlightBed(bedToHighlight);
    }
}
