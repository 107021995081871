import {Button, Form, Icon, Input, Label, Select} from "semantic-ui-react";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import styles from './index.module.scss'
import {
    createListener,
    ICreateListenerPayload,
    IListener,
    IUpdateListenerPayload,
    updateListener
} from "../../../../../store/cms/listeners/listenersSlice";
import {ISelectOption} from "../../../../../store/cms/darts/tournaments/types";

interface IListenerFormProps {
    closeForm: () => void
    editedListener: IListener | null
    setEditedListener: Dispatch<SetStateAction<IListener | null>>
}

const ListenersForm: React.FC<IListenerFormProps> = ({closeForm, editedListener, setEditedListener}) => {
    const dispatch = useAppDispatch();
    const {control, handleSubmit, errors, setValue, getValues} = useForm();
    const {clients} = useAppSelector(state => state.listeners)
    const [competitions, setCompetitions] = useState<ISelectOption[]>([]);

    useEffect(() => {
        if (editedListener) {
            const currentClient = clients.find(client => client.id === editedListener.clientId)
            if (currentClient) {
                setCompetitions(currentClient.items)
                setValue("client", editedListener.clientId)
                setValue("competition", editedListener.competitionId)
            }
            setValue("name", editedListener.name)
            setValue("email", editedListener.email)
            setValue("ip", editedListener.whiteIPs)
            setValue("socket", editedListener.socketKey)
        }
    }, [editedListener]);

    const clientOptions = useMemo(() => {
        return clients.map(option => {
            return {
                key: option.id,
                value: option.id,
                text: option.name
            }
        })
    }, [clients])

    const competitionOptions = useMemo(() => {
        return competitions.map(option => {
            return {
                key: option.id,
                value: option.id,
                text: option.name
            }
        })
    }, [competitions])

    const setCompetitionsByClient = (competitionId: any) => {
        const client = clients.find(comp => comp.id === competitionId)
        if (client) {
            setCompetitions(client.items)
        }
    }
    const onCreateListener = () => {
        const payload: ICreateListenerPayload = {
            clientId: getValues("client"),
            competitionId: getValues("competition"),
            name: getValues("name"),
            email: getValues("email"),
            whiteIPs: getValues("ip"),
            socketKey: getValues("socket"),
            isActive: true
        }
        closeForm()
        dispatch(createListener(payload))
    }

    const onUpdateListener = () => {
        const payload: IUpdateListenerPayload = {
            id: editedListener!.id,
            clientId: getValues("client").value,
            competitionId: getValues("competition").value,
            name: getValues("name"),
            email: getValues("email"),
            whiteIPs: getValues("ip"),
            socketKey: getValues("socket"),
            isActive: true
        }
        closeForm()
        dispatch(updateListener(payload))
        setEditedListener(null)
    }

    return (
        <div className="form-overlay">
            <Form
                onSubmit={editedListener ? handleSubmit(onUpdateListener) : handleSubmit(onCreateListener)}
                className={styles.form}
            >
                <Icon
                    onClick={() =>{
                        setEditedListener(null)
                        closeForm()
                    }}
                    color="red"
                    name="close"
                    size="large"
                    style={{float: "right", cursor: "pointer"}}
                />
                <Form.Field>
                    <Label className={styles.selectLabel}>Client</Label>
                    <Controller
                        name="client"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={(props) => (
                            <Select
                                value={props.value}
                                id="client"
                                options={clientOptions}
                                placeholder="Select client"
                                onChange={(e, {value}) => {
                                    props.onChange(value)
                                    setCompetitionsByClient(value)
                                }}
                            />
                        )}
                    />
                </Form.Field>
                {errors.client && errors.client.type === "required" &&
                    <div className={'darts-users-form-error-wrap'}>
                        <div><i className="warning sign red icon"></i></div>
                        <p className='darts-users-form-error'>The client field is required</p>
                    </div>
                }
                {(competitions.length > 0 || editedListener) && <Form.Field>
                    <Label className={styles.selectLabel}>Competition</Label>
                    <Controller
                        name="competition"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={(props) => (
                            <Select
                                value={props.value}
                                id="competition"
                                options={competitionOptions}
                                placeholder="Select competition"
                                onChange={(e, {value}) => props.onChange(value)}
                            />
                        )}
                    />
                </Form.Field>}
                {errors.competition && errors.competition.type === "required" &&
                    <div className={'darts-users-form-error-wrap'}>
                        <div><i className="warning sign red icon"></i></div>
                        <p className='darts-users-form-error'>The competition field is required</p>
                    </div>
                }
                <Form.Field>
                    <Label>Name</Label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: true
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter name"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.name && errors.name.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The name field is required</p>
                        </div>
                    }
                </Form.Field>
                <Form.Field>
                    <Label>Email</Label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: true,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter email"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.email && errors.email.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The email field is required</p>
                        </div>}
                    {errors.email && errors.email.type === 'pattern' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>Email should be like example@xxx.xx</p>
                        </div>
                    }
                </Form.Field>
                <Form.Field>
                    <Label>IP</Label>
                    <Controller
                        name="ip"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter IP"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.ip && errors.ip.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The IP field is required</p>
                        </div>
                    }
                </Form.Field>
                <Form.Field>
                    <Label>Socket</Label>
                    <Controller
                        name="socket"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={(props) => (
                            <div className={styles.inputInner}>
                                <Input
                                    placeholder={"Enter socket"}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                />
                            </div>
                        )}
                    />
                    {errors.socket && errors.socket.type === 'required' &&
                        <div className={'darts-users-form-error-wrap'}>
                            <div><i className="warning sign red icon"></i></div>
                            <p className='darts-users-form-error'>The socket field is required</p>
                        </div>
                    }
                </Form.Field>
                <Button id="save_game" color="green" type="submit">
                    {editedListener ? 'Update' : 'Create'}
                </Button>
            </Form>
        </div>
    );
}

export default ListenersForm;
