import React, {useEffect, useMemo, useState} from "react";
import {Button, Form, FormField, Icon, Input, Label, Select} from "semantic-ui-react";
import './style.scss'
import {toastr} from "react-redux-toastr";
import CheckboxCms from "../../../../../components/CheckboxCms/CheckboxCms";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {
    createDartsCompetition,
    editDartsCompetition, getDartsCompetitions,
    hideCompetitionForm
} from "../../../../../store/cms/darts/competitions/competitionsSlice";
import {DartsCompetition} from "../../../../../store/cms/darts/competitions/types";
import {isExistsCompetitionName} from "./helpers/isExistsCompetitionName";
import {buildQueryString} from "../../DartsGames/helpers/buildQueryString";
import {isAdmin} from "../../../CmsPage/Sidebar/helpers/renderSidebarItems";
import {getClientId} from "./helpers/getClientId";

interface IDartsCompetitionsFormProps {
    onlyActiveByFilter: boolean
    clientByFilter: number
    search: string
}

const DartsCompetitionsForm: React.FC<IDartsCompetitionsFormProps> = (
    {
        onlyActiveByFilter,
        search,
        clientByFilter
    }) => {
    const dispatch = useAppDispatch();
    const {
        dartsCompetitions,
        pageSize,
        pageNum,
        editMode, editCompetitionId, clients, competitionTypeOptions
    } = useAppSelector((state) => state.cmsCompetitions);
    const {user} = useAppSelector((state) => state.auth);
    const [isActive, setIsActive] = useState(true);
    const [competitionName, setCompetitionName] = useState<string>('');
    const [client, setClient] = useState<any>(getClientId());
    const [competitionType, setCompetitionType] = useState<any>(competitionTypeOptions[0].id);

    useEffect(() => {
        if (editMode) {
            const updatedCompetition = dartsCompetitions.find(
                (competition) => competition.id === editCompetitionId
            );
            if (updatedCompetition) {
                setCompetitionName(updatedCompetition!.name);
                setIsActive(updatedCompetition.isActive)
                setCompetitionType(updatedCompetition.type)
                setClient(updatedCompetition.clientId)
            }
        }
    }, [editMode]);

    const clientOptions = useMemo(() => {
        return clients.map(client => {
            return {key: client.id, value: client.id, text: client.name}
        })
    }, [clients]);

    const typeOptions = useMemo(() => {
        return competitionTypeOptions.map(client => {
            return {key: client.id, value: client.id, text: client.name}
        })
    }, [competitionTypeOptions]);

    const submitCompetitions = () => {
        if (dartsCompetitions) {
            const isExists = isExistsCompetitionName(
                {
                    dartsCompetitions,
                    competitionName: competitionName as string
                }
            );
            if (!isExists) {
                const newCompetitions: DartsCompetition = {
                    name: competitionName,
                    isActive,
                    clientId: client,
                    type: competitionType
                };
                if (!competitionName?.trim()) return toastr.error("", "The competition name field can not be empty");
                dispatch(createDartsCompetition(newCompetitions))
                    .then(() => {
                        dispatch(getDartsCompetitions(buildQueryString({
                            pageNum,
                            pageSize,
                            onlyActive: onlyActiveByFilter,
                            search,
                            clientId: clientByFilter
                        })));
                    })
                setCompetitionName('');

            } else return toastr.error("", "This competition already exists");
        }
        setCompetitionName('');
    };

    const updateCompetition = (id: number) => {
        const updatedCompetition = {
            id,
            name: competitionName,
            isActive,
            type: competitionType,
            clientId: client

        };
        if (!competitionName?.trim()) return toastr.error("", "The competition name field can not be empty");
        dispatch(editDartsCompetition(updatedCompetition as DartsCompetition))
            .then(() =>
                dispatch(getDartsCompetitions(buildQueryString({
                    pageNum,
                    pageSize,
                    onlyActive: onlyActiveByFilter,
                    search,
                    clientId: clientByFilter
                })))
            );
        setCompetitionName('');
    };

    const onCloseForm = () => {
        dispatch(hideCompetitionForm());
    }

    const onSubmit = (editMode: boolean, editCompetitionId?: number) => {
        return editMode ? updateCompetition(editCompetitionId!) : submitCompetitions()
    }

    return (
        <div className="form-overlay">
            <Form
                onSubmit={() => onSubmit(editMode, editCompetitionId!)}
                className={"competition-form"}
            >
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={onCloseForm}
                    style={{float: "right", cursor: "pointer"}}
                />
                {isAdmin(user) && <FormField>
                    <Label className={'competition-label'}>Client:</Label>
                    <Select
                        value={client}
                        options={clientOptions}
                        onChange={(e, {value}) => setClient(value)}
                        defaultValue={clientOptions[0].value}

                    />
                </FormField>
                }
                <FormField>
                    <Label className={'competition-label'}>Competition Type:</Label>
                    <Select
                        value={competitionType}
                        options={typeOptions}
                        onChange={(e, {value}) => setCompetitionType(value)}
                        defaultValue={typeOptions[0].value}

                    />
                </FormField>
                <FormField>
                    <Label className={'competition-label'}>Competition name:</Label>
                    <Input
                        id="competition_name"
                        value={competitionName}
                        onChange={(e) => setCompetitionName(e.target.value)}
                    />
                </FormField>
                <FormField>
                    <div className={'competition-adc-qualifier'}>
                        <Label className={'competition-label'}>Is Active</Label>
                        <div className={'competition-checkbox'}>
                            <CheckboxCms value={isActive} handler={() => setIsActive(!isActive)}/>
                        </div>
                    </div>
                </FormField>
                <Button id="save_competition" type="submit" color="green" size="mini">
                    submit
                </Button>
            </Form>
        </div>
    );
};

export default DartsCompetitionsForm;
