import React from "react";
import {Container} from "semantic-ui-react";
import {LiveScoreGroup} from "../../../../store/pages/liveScoreInfo/types";
import "./styles.scss";


interface IPlayerLiveStatsPanelProps {
    statistics: LiveScoreGroup[];
}

export const PlayerLiveStatsPanel: React.FC<IPlayerLiveStatsPanelProps> = React.memo((
    props
) => {
    const {statistics} = props;
    return (
        <Container>
            {statistics?.map(stats => {
                return (
                    <Container className="section">
                        <div className="section__title">{stats.name}</div>
                        {stats.items.map(item => {
                            return (
                                <div className="section__content">
                                    <span className="section__content__title">{item.name}</span>
                                    <span
                                        className={`section__content__value ${item.isStyled ? "highlighted" : ""}`}>
                                        {item.value}
                                    </span>
                                </div>
                            )
                        })}
                    </Container>
                )
            })}
        </Container>
    );
}, (prevProps, nextProps) => {
    return nextProps.statistics === prevProps.statistics;
})
