import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    DartsAdcCreateGamePayload,
    DartsAdcGames,
    DartsAdcGamesData, DartsAdcGamesResp,
    DartsGamesInitData, IAdcGamesFilterValues,
} from "./types";
import {client} from "../../../../services/api.service";
import {
    API_DARTS_ADC_GAMES,
    API_DARTS_GAMES_INIT_DATA,
} from "../../../../constants";
import {errorLogger} from "../../../../services/error-logger";
import {toastr} from "react-redux-toastr";

import {IDartsGamesFilter} from "../games/types";

const initialState: DartsAdcGames = {
    games: [] as DartsAdcGamesData[],
    scheduleSelectedGame: 0,
    selectedGame: null,
    initData: {} as DartsGamesInitData,
    isLoading: false,
    editMode: false,
    noContent: true,
    filterValues: {
        competition: 0,
        location: '',
        player: 0,
        from: '',
        status: 0,
        sort: 0,
    },
    totalPages:0,
    pageNum: 1,
    pageSize: 10,
}

export const getAdcDartsGamesInit = createAsyncThunk<DartsGamesInitData>(
    'cmsAdcDartsGamesInit/get',
    async () => {
        try {
            const {data} = await client.get(API_DARTS_GAMES_INIT_DATA);
            return data;
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
                const errorLog = {
                    projectName: 'DC',
                    errorMessage: `Adc darts games init: ${error.name}: ${error.message}`,
                    errorDate: new Date()
                }
                errorLogger(errorLog)
            }
        }
    }
)
export const getAdcDartsGames = createAsyncThunk<DartsAdcGamesResp, string>(
    'cmsAdcDartsGames/get',
    async (queryString,{rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_ADC_GAMES}?${queryString}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch adc games.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Adc darts games: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createDartsAdcGame = createAsyncThunk<DartsAdcGamesData, DartsAdcCreateGamePayload>(
    'cmsAdcDartsGames/post',
    async (payload, {rejectWithValue}) => {
        try {
            const {data,status} = await client.post(API_DARTS_ADC_GAMES, payload);
            if (status === 200) {
                toastr.warning("CMS", "This game is already exist");
                return rejectWithValue('This game is already exists');

            }
            toastr.success("CMS", "Adc game has been created");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to create an adc handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Adc darts games creating: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }

    }
)

export const editDartsAdcGame = createAsyncThunk<DartsAdcGamesData, DartsAdcCreateGamePayload>(
    'cmsAdcDartsGames/edit',
    async (payload, {rejectWithValue}) => {
        try {
            const {data} = await client.put(`${API_DARTS_ADC_GAMES}`, payload);
            toastr.success("CMS", "Adc game has been edited");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to edit an adc game.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Adc darts games editing: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.resposen.data);
        }

    }
)

export const deleteDartsAdcGame = createAsyncThunk<DartsAdcGamesData, number>(
    'cmsAdcDartsGames/delete',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.delete(`${API_DARTS_ADC_GAMES}/${id}`);
            toastr.success("", "Adc game has been deleted");
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to delete an adc handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Adc darts game deleting: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog)
            return rejectWithValue(error.response.data);
        }

    }
)

export const getOneDartsAdcGame = createAsyncThunk<DartsAdcGamesData, number>(
    'cmsAdcDartsGames/getSingle',
    async (id, {rejectWithValue}) => {
        try {
            const {data} = await client.get(`${API_DARTS_ADC_GAMES}/${id}`);
            return data;
        } catch (error) {
            toastr.error('CMS', 'Failed to fetch a single adc handler.')
            console.log(error.message);
            const errorLog = {
                projectName: 'DC',
                errorMessage: `Get a single adc game: ${error.name}: ${error.message}`,
                errorDate: new Date()
            }
            errorLogger(errorLog);
            return rejectWithValue(error.response.message);
        }
    }
)

export const cmsAdcDartsGamesSlice = createSlice({
    name: 'cmsAdcDartsGames',
    reducers: {
        setSelectedAdcDartsGame: (state, action: PayloadAction<DartsAdcGamesData | null>) => {
            state.selectedGame = action.payload
        },
        clearAdcFilter: (state, action: PayloadAction<IAdcGamesFilterValues>) => {
            state.filterValues = action.payload
        },
        setAdcGameFilter: (state, action: PayloadAction<IDartsGamesFilter>) => {
            const {field, value} = action.payload;
            state.filterValues = {...state.filterValues, [field]: value};
        },
        setActivePage:(state,action) => {
            state.pageNum = action.payload
        },
        changePageSize:(state,action) => {
            state.pageSize = action.payload
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getAdcDartsGamesInit.fulfilled, (state, action) => {
                state.initData = action.payload;
            })
            .addCase(getAdcDartsGames.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAdcDartsGames.fulfilled, (state, action) => {
                state.games = action.payload.items
                if (action.payload.items.length) {
                    state.noContent = false;
                }
                state.pageNum = action.payload.pageNum
                state.totalPages = action.payload.totalPages
                state.isLoading = false;
            })
            .addCase(getAdcDartsGames.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createDartsAdcGame.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createDartsAdcGame.fulfilled, (state, action) => {
                state.isLoading = false;
                state.games = state.games.concat(action.payload);
            })
            .addCase(createDartsAdcGame.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(editDartsAdcGame.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editDartsAdcGame.fulfilled, (state, action) => {
                state.isLoading = false;
                state.games = state.games.map((game) => {
                    if (game.id === action.payload.id) {
                        game = action.payload
                    }
                    return game
                })

            })
            .addCase(editDartsAdcGame.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(deleteDartsAdcGame.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteDartsAdcGame.fulfilled, (state, action) => {
                state.games = state.games.filter(
                    (game) => game.id !== action.meta.arg
                );
                state.isLoading = false;
            })
            .addCase(deleteDartsAdcGame.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getOneDartsAdcGame.fulfilled, (state, action) => {
                state.selectedGame = action.payload;
            })
    },
});

export const {
    setSelectedAdcDartsGame,
    setAdcGameFilter,
    clearAdcFilter,
    setActivePage,
    changePageSize
} = cmsAdcDartsGamesSlice.actions
export default cmsAdcDartsGamesSlice.reducer;

