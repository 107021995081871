import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    StateGame,
    InitScore,
    ICurrentShootOutGame, DartsGameItem, DartsAdcGameItem
} from "./types";
import {ISetGamePayload} from "./types";


const initialState: StateGame = {
    game: {} as DartsGameItem | ICurrentShootOutGame,
    adcGame: {} as DartsAdcGameItem,
    isLoading: true,
    isReadonly: false,
    isShowWalkoverModal: false,
    isGameRunningLoader: false,
    initScore: {} as InitScore,
    gameClassicStatus: null,
    gameAdcStatus: null,
    gameShootOutStatus: null
}
export const setDartsGame = createAsyncThunk<void, ISetGamePayload>(
    'handler/setGame',
    async (payload: ISetGamePayload, {dispatch}) => {
        const {game, history, selectedAdcGame} = payload
        if (!game.is9shots && !selectedAdcGame) {
            dispatch(setGame(game as DartsGameItem));
            dispatch(setClassicGameStatus(game.status));
            return history.push(`/darts/${game.id}`);
        }
        if (selectedAdcGame) {
            dispatch(setAdcGame(game as DartsAdcGameItem));
            dispatch(setAdcGameStatus(game.status));
            return history.push(`/darts-adc/${game.id}`);
        }
        dispatch(setGame(game as DartsGameItem));
        dispatch(setShootOutGameStatus(game.status));
        return history.push(`/darts-shootout/${game.id}`);
    }
);

export const gameHandlerSlice = createSlice({
    name: 'gameHandler',
    reducers: {
        setClassicGameStatus: (state, action: PayloadAction<number>) => {
            state.gameClassicStatus = action.payload;
        },
        setAdcGameStatus: (state, action: PayloadAction<number>) => {
            state.gameAdcStatus = action.payload;
        },
        setShootOutGameStatus: (state, action: PayloadAction<number>) => {
            state.gameShootOutStatus = action.payload;
        },
        setGame: (state, action: PayloadAction<DartsGameItem>) => {
            state.game = action.payload;
            state.isLoading = false;
        },
        setAdcGame: (state, action: PayloadAction<DartsAdcGameItem>) => {
            state.adcGame = action.payload;
            state.isLoading = false;
        },
        setGameRunningLoader: (state, action: PayloadAction<boolean>) => {
            state.isGameRunningLoader = action.payload;
        },
        setWalkoverModal: (state, action: PayloadAction<boolean>) => {
            state.isShowWalkoverModal = action.payload;
        },
        clearGame: (state) => {
            state.gameAdcStatus = 0;
            state.gameShootOutStatus = 0;
            state.gameClassicStatus = 0;
        },
    },
    initialState
})
export const {
    setClassicGameStatus,
    setAdcGameStatus,
    setGame,
    setAdcGame,
    setGameRunningLoader,
    setWalkoverModal,
    setShootOutGameStatus,
    clearGame
} = gameHandlerSlice.actions;

export default gameHandlerSlice.reducer;
