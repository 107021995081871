import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styles from './index.module.scss';

interface ISeries180Props {
    title: string;
    score: number;
}

const Series180: React.FC<ISeries180Props> = ({ title, score }) => {
    const { opacity, transform } = useSpring({
        from: { opacity: 1, transform: 'translateY(0%)' },
        to: async (next) => {
            await next({ opacity: 0, transform: 'translateY(200%)', config: { duration: 100 } });

            await next({
                opacity: 1,
                transform: 'translateY(-200%)',
                config: { duration: 200 },
            });

            await next({
                opacity: 1,
                transform: 'translateY(0%)',
                config: { tension: 500, friction: 12 }
            });
        },
        reset: true,
    });

    return (
        <div className={styles.table}>
            <div className={styles.header}>
                {title}
                <span className={styles.lastLit}>s</span>
            </div>

            <div className={styles.valueCell}>
                <animated.div className={styles.number} style={{ opacity, transform }}>
                    {score}
                </animated.div>
            </div>
        </div>
    );
};

// Оборачиваем компонент в React.memo для предотвращения лишних рендеров
export default React.memo(Series180, (prevProps, nextProps) => {
    // Компонент не ререндерится, если пропсы не изменились
    return prevProps.title === nextProps.title && prevProps.score === nextProps.score;
});
