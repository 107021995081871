import React from "react";
import {useSpring, animated,} from "react-spring";
import PlayerNames from "./PreMatchPlayerStats/PlayerNames/PlayerNames";
import PlayersAverage from "./PreMatchPlayerStats/PlayersOverall/PlayersAverage";
import './styles.scss'
import './style-media.scss'
import PlayerLastResult from "../PlayerLastResults/PlayerLastResult";
import {IPlayerAnimateData} from "../PreMatchPage";
import blankPlayer from '../../../img/BlankPlayer.png';

interface IPlayerStatsProps {
    counter: number,
    playerHome: IPlayerAnimateData,
    playerAway: IPlayerAnimateData
}

const PlayerStats: React.FC<IPlayerStatsProps> = (
    {counter, playerHome, playerAway}) => {

    const movements3DartsAverage = {
        showScoreLabel: useSpring({
            to: async (next) => {
                await next({width: counter >= 5500 && counter < 9300 ? "495px" : "24px",})
            },
            from: {width: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineStart: useSpring({
            to: async (next) => {
                await next({height: counter > 4800 && counter <= 6300 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineEnd: useSpring({
            to: async (next) => {
                await next({height: counter > 8300 && counter <= 9500 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        showHomePlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 5800 && counter < 9300 ? '110%' : '0',
                    visibility: counter >= 5800 && counter < 9500 ? 'visible' : 'hidden',
                })
            },
            config: {duration: 300}
        }),
        showAwayPlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 5800 && counter < 9300 ? '110%' : '0',
                    visibility: counter >= 5800 && counter < 9500 ? 'visible' : 'hidden',
                    textAlign: counter >= 5800 && counter < 9500 ? 'end' : 'end',
                })
            },
            config: {duration: 300}
        })
    }

    const movementsCheckout = {
        showScoreLabel: useSpring({
            to: async (next) => {
                await next({width: counter >= 11000 && counter < 14800 ? "420px" : "24px",})
            },
            from: {width: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineStart: useSpring({
            to: async (next) => {
                await next({height: counter >= 10500 && counter <= 12000 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineEnd: useSpring({
            to: async (next) => {
                await next({height: counter >= 14000 && counter <= 15200 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        showHomePlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 11300 && counter < 15200 ? '118%' : '0px',
                    display: counter >= 11300 && counter < 15000 ? 'block' : 'none',
                })
            },
            config: {duration: 300}
        }),
        showAwayPlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 11300 && counter < 15200 ? '118%' : '0px',
                    display: counter >= 11300 && counter < 15000 ? 'block' : 'none',
                    transform: counter >= 15800 && counter < 20500 ? 'translateX(-50px)' : 'translateX(0)',

                })
            },
            config: {duration: 300}
        })
    }

    const movementsWinLoss = {
        showScoreLabel: useSpring({
            to: async (next) => {
                await next({width: counter >= 16500 && counter < 20300 ? "500px" : "24px",})
            },
            from: {width: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineStart: useSpring({
            to: async (next) => {
                await next({height: counter >= 15900 && counter <= 17400 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineEnd: useSpring({
            to: async (next) => {
                await next({height: counter >= 19400 && counter <= 20700 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        showHomePlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 16900 && counter < 20300 ? '110%' : '0px',
                    display: counter >= 16900 && counter < 20500 ? 'block' : 'none',
                })
            },
            config: {duration: 250}
        }),
        showAwayPlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 16900 && counter < 20300 ? '110%' : '0px',
                    display: counter >= 16900 && counter < 20500 ? 'block' : 'none',
                    transform: counter >= 15800 && counter < 20500 ? 'translateX(50px)' : 'translateX(0)',
                })
            },
            config: {duration: 250}
        })
    }

    const movementsWinPercentage = {
        showScoreLabel: useSpring({
            to: async (next) => {
                await next({width: counter >= 28900 && counter < 32100 ? "475px" : "24px",})
            },
            from: {width: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineStart: useSpring({
            to: async (next) => {
                await next({height: counter >= 28400 && counter <= 30200 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineEnd: useSpring({
            to: async (next) => {
                await next({height: counter >= 31200 && counter <= 32600 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        showHomePlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 28800 && counter <= 32300 ? "118%" : "0px",
                    display: counter >= 28800 && counter < 32200 ? 'block' : 'none',
                })
            },
            config: {duration: 300}
        }),
        showAwayPlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 28800 && counter <= 32300 ? "118%" : "0px",
                    display: counter >= 28800 && counter < 32200 ? 'block' : 'none',
                })
            },
            config: {duration: 300}
        })
    }

    const movementsPerMatch = {
        showScoreLabel: useSpring({
            to: async (next) => {
                await next({width: counter >= 34300 && counter < 37500 ? "475px" : "24px",})
            },
            from: {width: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineStart: useSpring({
            to: async (next) => {
                await next({height: counter >= 33600 && counter <= 34700 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        moveLabelLineEnd: useSpring({
            to: async (next) => {
                await next({height: counter >= 36600 && counter <= 37700 ? "100%" : "0px"})
            },
            from: {height: "0px",},
            config: {duration: 300}
        }),
        showHomePlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 34600 && counter <= 37500 ? "100%" : "0px",
                    display: counter >= 34600 && counter < 37700 ? 'block' : 'none',
                })
            },
            config: {duration: 300}
        }),
        showAwayPlayerScore: useSpring({
            to: async (next) => {
                await next({
                    width: counter >= 34600 && counter <= 37500 ? "100%" : "0px",
                    display: counter >= 34600 && counter < 37700 ? 'block' : 'none',
                    transform: counter >= 33000 && counter < 37700 ? 'translateX(100px)' : 'translateX(0)'
                })
            },
            config: {duration: 300}
        })
    }

    const showHomePlayerPhoto = useSpring({
        to: async (next) => {
            await next({x: counter >= 3800 && counter <= 38000 ? 0 : -1000})
        },
        from: {x: -1000},
    })

    const showAwayPlayerPhoto = useSpring({
        to: async (next) => {
            await next({x: counter >= 3800 && counter <= 38000 ? 10 : 1000,})
        },
        from: {x: 1000},
    })

    const playerAverage = {
        home: playerHome?.average?.toFixed(2),
        away: playerAway?.average?.toFixed(2)
    }

    const playersCheckout = {
        home: playerHome?.checkoutPercentage?.toFixed(2),
        away: playerAway?.checkoutPercentage?.toFixed(2)
    }

    const playersWinLoss = {
        home: playerHome?.winLossRecord,
        away: playerAway?.winLossRecord
    }

    const playerWinPercentage = {
        home: playerHome?.winningPercent.toFixed(2),
        away: playerAway?.winningPercent.toFixed(2)
    }

    const playerPerMatch = {
        home: playerHome?.perMatch180?.toFixed(2),
        away: playerAway?.perMatch180?.toFixed(2)
    }

    return (
        <div className="players-stats">
            <animated.div style={showHomePlayerPhoto}>
                <div className="player-photo player-photo__left-position">
                    <div className="player-img-inner">
                        {playerHome.picture ?
                            <img
                                src={`data:image/png;base64,${playerHome?.picture}`}
                                alt="home-player"
                                className="player-img player-img__left-position"
                            />
                            :
                            <img src={blankPlayer} alt=""/>
                        }
                    </div>
                </div>
            </animated.div>
            <PlayerNames
                playerHome={playerHome}
                playerAway={playerAway}
            />
            {counter <= 10000
                &&
                <PlayersAverage
                    movements={movements3DartsAverage}
                    counter={counter}
                    changeLabelMove={7000}
                    playersScore={playerAverage}
                    scoreType={"3 DARTS AVERAGE"}
                />
            }
            {counter <= 15500
                &&
                <PlayersAverage
                    movements={movementsCheckout}
                    counter={counter}
                    playersScore={playersCheckout}
                    changeLabelMove={12500}
                    scoreType={"CHECKOUT%"}
                    isPercent={true}
                />
            }
            {counter <= 21000 &&
                <PlayersAverage
                    movements={movementsWinLoss}
                    counter={counter}
                    playersScore={playersWinLoss}
                    changeLabelMove={18000}
                    scoreType={"Win-Loss Record"}
                />
            }
            {counter > 19500 && <PlayerLastResult
                counter={counter}
                playerHome={playerHome}
                playerAway={playerAway}
            />}

            {counter >= 10500
                &&
                <PlayersAverage
                    movements={movementsWinPercentage}
                    counter={counter}
                    playersScore={playerWinPercentage}
                    changeLabelMove={30000}
                    scoreType={"Win Percentage"}
                    isPercent={true}
                />
            }
            {counter >= 17000
                &&
                <PlayersAverage
                    movements={movementsPerMatch}
                    counter={counter}
                    playersScore={playerPerMatch}
                    changeLabelMove={36200}
                    scoreType={"180s Per Match"}
                />
            }
            <animated.div style={showAwayPlayerPhoto}>
                <div className="player-photo player-photo__right-position">
                    <div className="player-img-inner">
                        {playerAway.picture ?
                            <img
                                src={`data:image/png;base64,${playerAway?.picture}`}
                                alt=" "
                                className="player-img player-img__left-position"
                            />
                            :
                            <img src={blankPlayer} alt=""/>
                        }
                    </div>
                </div>
            </animated.div>
        </div>
    )
}


export default PlayerStats;
PlayerStats.displayName = "PlayerStats";
