import React from "react";
import {TbArrowBadgeLeftFilled} from "react-icons/tb";
import {setFontColorClass} from "./setCurrentThrowerStyles";
import {BestCompetitionType} from "../GameScores";

export const renderFirstThrowerInLegPointer = (firstThrower: boolean | null, colorType: BestCompetitionType) => {
    if (!firstThrower) {
        return
    }
    return (
        < TbArrowBadgeLeftFilled
            className={`thrower-arrow`}
            style={setFontColorClass(colorType)}
        />
    );
};
