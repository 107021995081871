import React from 'react';
import ShootOutActiveGameScore from "./ShootOutScoreBoard/ShootOutActiveGameScore";
import './styles.scss';


export const ShootOutActiveGameScorePage = () => {
    return (
        <div className="scoreboard-page shootout">
            <ShootOutActiveGameScore/>
        </div>
    );
}
