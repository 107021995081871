import React, {useEffect, useMemo, useState} from "react";
import {Button, Icon, Input, Select, Table} from "semantic-ui-react";
import DartsDeleteModal from "../../../../components/DartsPlayerTableModal/dartsDeleteModal";
import DimmerLoader from "../../../../components/DImmerLoader/DimmerLoader";
import UsersForm from "./UsersForm";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {
    changePageSize,
    createModeHandler,
    deleteUser,
    getAllClients,
    getSingleUser,
    getUsers,
    setActivePage,
    setClientId
} from "../../../../store/cms/usersNRoles/cmsUsersNRolesSlice";
import {IUser} from "../../../../store/cms/usersNRoles/types";
import AppPagination from "../../../../components/Pagination/AppPagination";
import './styles.scss';

interface QueryParams {
    clientId?: number;
    role?: string;
    pageNum?: number;
    pageSize?: number;
    sort?: number;
    sortField?: string;
    search?: string;
}

const Users = () => {
    const {
        allUsers,
        usersLoader,
        createMode,
        editMode,
        totalPages,
        pageNum,
        clientId,
        allowCRUD,
        allClients,
        pageSize
    } = useAppSelector((state) => state.cmsUsersNRoles);
    const {isRenderControls} = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const [searchWord, setSearchWord] = useState("");
    const [sortField, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');

    useEffect(() => {
        dispatch(getAllClients())
    }, []);

    const clientOptions = useMemo(() => {
        const clients = allClients.map(client => {
            return {key: client.id, value: client.id, text: client.name}
        })
        clients.unshift({key: 0, value: 0, text: "All"})
        return clients
    }, [allClients]);


    useEffect(() => {
        const params = {
            pageNum: searchWord ? 1 : pageNum,
            pageSize,
            search: searchWord.toLowerCase(),
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            clientId
        } as QueryParams
        dispatch(getUsers(createQueryString(params)))
    }, [searchWord, sortField, sortDirection, clientId]);

    const createQueryString = (params: QueryParams): string => {
        const queryEntries = Object.entries(params).filter(
            ([, value]) => value !== undefined && value !== null && value !== '' && value !== 0
        );

        const queryString = queryEntries
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
            .join('&');

        return queryString ? `?${queryString}` : '';
    };

    const getRoles = (roles: string[] | null) => {
        if (Array.isArray(roles)) {
            return !roles.length ? ["-"] : roles.join(', ');
        }
        return ["-"];
    }

    const onChangePager = (activePage: string | number | undefined) => {
        setActivePage(activePage)
        const params = {
            pageNum: activePage,
            pageSize,
            search: searchWord.toLowerCase(),
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            clientId
        } as QueryParams
        dispatch(getUsers(createQueryString(params)))
    }

    const generateButtons = (user: IUser) => {
        if (isRenderControls) {
            return (
                <div className={"user-table-controls"}>
                    <DartsDeleteModal
                        id={user.id}
                        deleteAction={() => dispatch(deleteUser(user.id))}
                        confirmText={"Are you sure you want to delete this user?"}
                        titleModal={"Delete the user"}
                    />
                    <Icon
                        name='pencil alternate'
                        link
                        onClick={() => editUserHandler(user.id)}
                        size="small"
                        color="grey"
                    >
                    </Icon>
                </div>
            );
        }
    };

    const editUserHandler = (id: number) => dispatch(getSingleUser(id));

    const createUserHandler = () => dispatch(createModeHandler(true));

    const handleSort = (field: string) => {
        const direction = sortField === field && sortDirection === 'ascending' ? 'descending' : 'ascending';
        setSortField(field);
        setSortDirection(direction);
    };

    const onChangePageSize =(value:any) => {
        dispatch(changePageSize(value))
        const params = {
            pageNum,
            pageSize: value,
            search: searchWord.toLowerCase(),
            sortField,
            sort: sortDirection === "ascending" ? 0 : 1,
            clientId
        } as QueryParams
        dispatch(getUsers(createQueryString(params)))
    }

    return (
        <>
            <div className="users-btn-wrapper">
                <div className={"users-inp-inner"}>
                    <Select
                        options={clientOptions}
                        value={clientId}
                        onChange={(e, {value}) => {
                            dispatch(setClientId(value))
                        }}
                        placeholder='Select client'
                    />
                </div>
                <Input
                    onChange={(e) => setSearchWord(e.target.value)}
                    icon='search'
                    placeholder='Search...'
                />
                {allowCRUD && <Button
                    disabled={usersLoader}
                    className={'users-table'}
                    color="blue"
                    id="add_game"
                    onClick={createUserHandler}>
                    Add
                </Button>
                }
            </div>
            {allUsers?.length > 0 && !usersLoader &&
                <Table sortable celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={sortField === 'name' ? sortDirection : undefined}
                                onClick={() => handleSort('name')}
                                textAlign="center"
                            >
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell

                                textAlign="center"
                            >
                                Role
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={sortField === 'email' ? sortDirection : undefined}
                                onClick={() => handleSort('email')}
                                textAlign="center"
                            >
                                E-mail
                            </Table.HeaderCell>
                            {allowCRUD && <Table.HeaderCell textAlign="center"/>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {allUsers.map((user: IUser) => {
                            return (
                                <Table.Row key={user.id}>
                                    <Table.Cell textAlign="center">
                                        {user.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                        {getRoles(user.roles!)}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">{user.email}</Table.Cell>

                                    {allowCRUD && <Table.Cell textAlign="center">
                                        {generateButtons(user)}
                                    </Table.Cell>}
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            }
            {usersLoader && <DimmerLoader/>}
            {allUsers.length === 0 && <div className={"users-no-content"}>No users</div>}
            {createMode || editMode ? <UsersForm/> : null}
            {allUsers.length > 0 &&
                <AppPagination
                    activePage={pageNum}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    onChangePager={onChangePager}
                    onChangePageSize={onChangePageSize}
                />
            }
        </>
    );
}

export default Users;
