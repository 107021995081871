import {IFormData} from "../MssNewsForm/MssNewsForm";
import {imgUrlToFile} from "./imgUrlToFile";

export const formDataHandlers = (formData: IFormData, textEditorValue: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('newsDate', formData.dateValue)
    bodyFormData.append('title', formData.titleValue)
    bodyFormData.append('subtitle', formData.subtitleValue)
    bodyFormData.append('text', textEditorValue)
    bodyFormData.append('tag', formData.tagValue.toString())
    bodyFormData.append('image', imgUrlToFile(formData.imgValue, formData.imgName))
    bodyFormData.append('videoUrl', formData.youtubeLink ? formData.youtubeLink : "")
    return bodyFormData
}
export const updateFormData = (formData: IFormData, textEditorValue: string) => {
    const bodyFormData = new FormData();
    bodyFormData.append('id', formData.newsId.toString())
    bodyFormData.append('newsDate', formData.dateValue)
    bodyFormData.append('title', formData.titleValue)
    bodyFormData.append('subtitle', formData.subtitleValue)
    bodyFormData.append('text', textEditorValue)
    bodyFormData.append('tag', formData.tagValue.toString())
    bodyFormData.append('videoUrl', formData.youtubeLink ? formData.youtubeLink : "")
    bodyFormData.append('image', imgUrlToFile(formData.imgValue, formData.imgName))
    return bodyFormData
}