import React from 'react';
import { Button, Modal } from 'semantic-ui-react'

const EndGameModal = ({isOpen,onCloseModal,}) => {
  return (
    <div>
         <Modal
        size='tiny'
        open={isOpen}
        onClose={() => onCloseModal(false)}
      >
        <Modal.Header>IGamedc.igamemedia.com</Modal.Header>
        <Modal.Content>
          <p>The game is still in progress!!Use buttons "Suspend" or "Cancel" instead</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue' onClick={()=>onCloseModal(false)}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

export default EndGameModal;
