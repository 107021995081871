import React from 'react'
import {PlayerAdcType} from "../../../../enums/playerAdcTypes";
import adcQualifierUk from "../../../../img/adcQualifierUk.png"
import adcQualifierEu from "../../../../img/adcQualifierEu.png"
import adcQualifierOceania from "../../../../img/adcQualifierOceania.png"

export const renderQualifierIcon = (qualifierId: number) => {
    switch (qualifierId) {
        case PlayerAdcType.UK:
            return (
                <div className="adc-qualifier">
                    <img
                        src={adcQualifierUk}
                        alt="adc-qualifier"
                        className="adc-qualifier-uk"
                    />
                </div>
            )
        case PlayerAdcType.Europe:
            return (
                <div className="adc-qualifier">
                    <img
                        src={adcQualifierEu}
                        alt="adc-qualifier"
                        className="adc-qualifier-eu"
                    />
                </div>
            )
        case PlayerAdcType.Oceania:
            return (
                <div className="adc-qualifier">
                    <img
                        src={adcQualifierOceania}
                        alt="adc-qualifier"
                        className="adc-qualifier-oceania"
                    />
                </div>
            )
        default:
            return null

    }
}


