export const createFilterParamsPayload = () => {
    return {
        competitionID: 0,
        Week: 0,
        Group: 0,
        Phase: 0,
        location: null,
        Player: 0,
        From: "",
        Status: 0,
        pageNum: 1,
        pageSize: 10,
        gameDateDirection: 0
    };
}
