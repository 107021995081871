import React from 'react';
import"./style.scss"
const OverlayLoader = () => {
  return (
    <div className='overlay-loader'>
        <div className="lds-hourglass"></div>
    </div>
  );
}

export default OverlayLoader;
