import React, {Fragment, useState} from "react";
import {Button, Icon, Modal} from "semantic-ui-react";
import {ConfirmationModal} from "../../../../components/ConfirmationModal";
import moment from "moment";
import {removeNameSeparator} from "../../../../helpers/playersNames.helper";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../../hooks/hooks";
import {setDartsGameStatus} from "../../../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {setWalkoverModal} from "../../../../store/dartsGame/handler/gameHandlerSlice";
import {IMatchStatusPayload} from "../../../../store/dartsGame/currentGame/types";
import {GameTypes} from "../../../../enums/gameEvents";
import {GameStatuses} from "../../../../enums/gameStatus";

interface IWalkoverModalProps {
    open: boolean;
    onDecline: () => void;
    gameId: number
}
interface ISelectedTeam {
    id: number
    name: string
}
const WalkoverModal: React.FC<IWalkoverModalProps> = (
    {
        open,
        onDecline,
        gameId,
    }) => {
    const history = useHistory();
    const pathName = history.location.pathname;
    const isDartsAdc = pathName.includes("darts-adc");
    const {isShowWalkoverModal} = useAppSelector((state) => state.gameHandler);
    const {currentGameInitData} = useAppSelector((state) => state.currentDartsGame);
    const dispatch = useAppDispatch();
    const [selectedTeam, setSelectedTeam] = useState<ISelectedTeam | undefined>(undefined);

    const handleHomeSelected = () => {

        const homeTeam = {
            id: currentGameInitData?.players?.[0].id,
            name: currentGameInitData?.players?.[0].name,
        };
        //@ts-ignore
        setSelectedTeam(homeTeam);
        return dispatch(setWalkoverModal(false));
    };

    const handleAwaySelected = () => {
        const awayTeam = {
            id: currentGameInitData?.players?.[1].id,
            name: currentGameInitData?.players?.[1].name,
        };

        //@ts-ignore
        setSelectedTeam(awayTeam);
        return dispatch(setWalkoverModal(false));
    };


    const handleConfirmationModalConfirm = () => {
        if (selectedTeam !== undefined) {
            const walkoverPayload: IMatchStatusPayload = {
                gameData: {
                    gameId,
                    date: moment().utc().toDate(),
                    status: "walkover"

                },
                status: GameStatuses.Walkover,
                gameType: isDartsAdc ? GameTypes.ADC : GameTypes.CLASSIC,
                history,
                playerId: selectedTeam?.id,
            };
            setSelectedTeam(undefined);
            dispatch(setDartsGameStatus(walkoverPayload))
        }
    };

    const handleConfirmationModalDecline = () => {
        onDecline();
        setSelectedTeam(undefined);
    };

    const closeHandler = () => dispatch(setWalkoverModal(false));

    return (
        <Fragment>
            <Modal open={open && isShowWalkoverModal} size="tiny" autoFocus>
                <Icon
                    color="red"
                    name="close"
                    size="large"
                    onClick={closeHandler}
                    style={{float: "right", cursor: "pointer", transform: 'translate(-30px, 35px)'}}
                />
                <Modal.Header>Additional action required</Modal.Header>
                <Modal.Content>Which player has been awarded with win?</Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={handleHomeSelected}
                    >{`Home - ${removeNameSeparator(currentGameInitData?.players?.[0].name)}`}</Button>
                    <Button
                        onClick={handleAwaySelected}
                    >{`Away - ${removeNameSeparator(currentGameInitData?.players?.[1].name)}`}</Button>
                </Modal.Actions>
            </Modal>
            <ConfirmationModal
                open={selectedTeam !== undefined}
                onConfirm={handleConfirmationModalConfirm}
                onDecline={handleConfirmationModalDecline}
                //@ts-ignore
                content={`Confirm ${removeNameSeparator(selectedTeam?.name)} has been awarded the win due to a walkover?`}
            />
        </Fragment>
    );
};

export default WalkoverModal;
