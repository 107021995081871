import React from 'react';
import styles from "./index.module.scss"
import Permission from "./components/Persmission";
import {IPermissionsDictionary} from "../../store/cms/permissions/permissionsSlice";


interface IRestrictionsProps {
    title: string
    permissions: IPermissionsDictionary[]
    selectPermission: (id: string) => void
    selectedPermissions:string[]
}

const Permissions: React.FC<IRestrictionsProps> = (
    {
        title,
        permissions,
        selectPermission,
        selectedPermissions
    }) => {

    return (
        <div className={styles.permissions}>
            <h6 className={styles.title}>{title}</h6>
            {permissions.map(item => {
                return (
                    <Permission
                        selectedPermissions={selectedPermissions}
                        name={item.name}
                        id={item.id}
                        selectPermission={selectPermission}
                    />
                )
            })}

        </div>
    );
};

export default Permissions;
