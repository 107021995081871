import React, {Fragment, useEffect, useState} from "react";
import {Button, Icon, Loader} from "semantic-ui-react";
import {ConfirmationModal} from "../../components/ConfirmationModal";
import moment from "moment";
import {useHistory, useParams} from "react-router-dom";
import {GameStatuses} from "../../enums/gameStatus";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {startDartsGame} from "../../store/dartsGame/currentGame/dartsCurrentGameSlice";
import {GameTypes} from "../../enums/gameEvents";
import {formatTournamentName} from "./helpers/formatTournamentName";
import {
    setAdcGameStatus,
    setClassicGameStatus,
    setShootOutGameStatus
} from "../../store/dartsGame/handler/gameHandlerSlice";
import {client} from "../../services/api.service";
import {API_DARTS_ADC_GAMES, API_DARTS_GAMES, API_DARTS_SHOOTOUT_GAMES} from "../../constants";
import "./styles.scss";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";


const MatchContainer: React.FC = ({children}) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [actionButtonClicked, setActionButtonClicked] = useState(false);
    const {gameId} = useParams<{ gameId: string }>()
    const {
        isGameRunningLoader,
        gameClassicStatus,
        gameAdcStatus,
        gameShootOutStatus,
        isReadonly
    } = useAppSelector((state) => state.gameHandler)
    const {gameName} = useAppSelector((state) => state.currentDartsGame.currentGameInitData)
    const shootOutGameName = useAppSelector((state) => state.currentDartsGame.currentShootOutGameInitData.gameName)
    const [loader, setLoader] = useState(false);
    const pathName = history.location.pathname;
    const isShootOutGame = pathName.includes("shootout");
    const dartsAdc = pathName.includes("darts-adc");


    let gameStatus: string | number = gameAdcStatus || gameClassicStatus! || gameShootOutStatus!;
    const getGame = async (gameId: number) => {
        if (gameId && !dartsAdc && !isShootOutGame && !gameStatus) {
            setLoader(true)
            const currentGame = await client.get(`${API_DARTS_GAMES}/${gameId}`)
            dispatch(setClassicGameStatus(currentGame.data.status));
            setLoader(false)

        }
        if (gameId && dartsAdc && !gameStatus) {
            setLoader(true)
            const currentGame = await client.get(`${API_DARTS_ADC_GAMES}/${gameId}`)
            dispatch(setAdcGameStatus(currentGame.data.status));
            setLoader(false)

        }
        if (gameId && isShootOutGame && !gameStatus) {
            setLoader(true)
            const currentGame = await client.get(`${API_DARTS_SHOOTOUT_GAMES}/${gameId}`)
            setLoader(false)
            dispatch(setShootOutGameStatus(currentGame.data.status));
        }
    }

    useEffect(() => {
        if (gameId) {
            getGame(gameId)
        }
    }, [gameId]);

    const onConfirmClick = () => {
        const payload = {
            gameId: Number(gameId),
            date: moment().utc().toDate(),
        }

        if (dartsAdc) {
            dispatch(startDartsGame({gameData: payload, gameType: GameTypes.ADC}));
            setActionButtonClicked(false);
        } else if (isShootOutGame) {
            dispatch(startDartsGame({gameData: payload, gameType: GameTypes.SHOOTOUT}));
            setActionButtonClicked(false);
        } else if (!isShootOutGame) {
            dispatch(startDartsGame({gameData: payload, gameType: GameTypes.CLASSIC}));
            setActionButtonClicked(false);
        }
    };
    const onClickActionButtonMatchContainer = () => {
        setAdcGameStatus(GameStatuses.Running)
        setActionButtonClicked(true);
    };


    const logout = () => {
        history.push("/schedule");
    };

    const startButtonStyles = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };

    let page;

    if (
        gameStatus !== GameStatuses.Running &&
        gameStatus !== GameStatuses.PreEnd &&
        gameStatus !== GameStatuses.End && !loader

    ) {
        page = (
            <>
                <div onClick={() => history.push("/schedule")} className="match-page__back-arrow">
                    <Icon name="arrow left" size="large"/>
                    <p className="match-page__back-arrow-text">Back to main page</p>
                </div>
                <div className="match-page__start-btn">
                    <Button
                        style={{width: "200px", height: '40px'}}
                        onClick={() => onClickActionButtonMatchContainer()}
                        color="green"
                        disabled={isGameRunningLoader}
                    >
                        {(!isGameRunningLoader) && 'Start'}
                    </Button>

                    {(isGameRunningLoader) && <Loader active/>}
                </div>
            </>
        );
    } else {
        page = (
            <div className="match-page__sports-info">
                {loader && <div className={"match-loader"}><OverlayLoader/></div>}
                <div className="match-page__title">
                    <div className="game-title">{
                        formatTournamentName({
                            gameName, isShootOutGame, shootOutGameName
                        })
                    }</div>
                    <div className="logout-btn" onClick={logout}>
                        Logout
                    </div>
                </div>
                <div
                    className="sport-control"
                    style={startButtonStyles}
                >
                    {children}
                </div>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="match-page__container">
                {isReadonly && (
                    <div className="readonly">You are in readonly mode</div>
                )}
                {page}
            </div>
            <ConfirmationModal
                open={actionButtonClicked}
                content={`Confirm game to be run ?`}
                onConfirm={onConfirmClick}
                onDecline={() => setActionButtonClicked(false)}
            />
        </Fragment>
    );
};


export default MatchContainer;
MatchContainer.displayName = "MatchContainer";
