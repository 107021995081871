export const defaultAdcFilter = {
    competition: 0,
    location: "",
    player: 0,
    from: '',
    status: 0,
    pageNum: 1,
    pageSize: 10,
    sort: 0,
}
