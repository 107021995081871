import {IUser, UserRoles} from "../../../store/cms/usersNRoles/types"

export const isShowCmsButton = (typeBtn: string,user: IUser | null) => {
    if (typeBtn === 'cms') {
        if (Array.isArray(user?.roles)) {
            return user?.roles.some((role) => (role === UserRoles.Admin) || (role === UserRoles.CrmUser)
                ||(role === UserRoles.ClientAdmin))
        }
        return false
    }
    if (typeBtn === 'stats') {
        if (Array.isArray(user?.roles)) {
            return user?.roles.some((role) => (role === UserRoles.Admin) || (role === UserRoles.StatsUser))
        }
        return false
    }
}
